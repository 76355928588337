import { useEffect, useState } from "react";
import {
  closeModal,
  openModal,
  setModalVariables,
} from "../../../assets/Utils/modal";
import NftController from "../../../assets/Controllers/NftController";
import { iNft } from "../../../assets/models/iNft";
import { useTranslation } from "react-i18next";
import BookingHeader from "../../../assets/TakyonDesignSystem/components/Wallet/sub/BookingHeader";
import { DEFAULT_OVERPRICE } from "../../../config/Fees";
import UserController from "../../../assets/Controllers/UserController";
import { useSelector } from "react-redux";
import { selectUser } from "../../../redux/slices/userSlice";
import Button from "../../../assets/TakyonDesignSystem/components/Button/Button";
import { iBuyOrder } from "../../../assets/models/iOrder";
import { WalletCheckout } from "./WalletCheckout";
import { selectPendingOrders } from "../../../redux/slices/orderSlice";
import Skeleton from "react-loading-skeleton";
import { useMediaQuery } from "react-responsive";
import { SMALL_SCREEN } from "../../../assets/Utils/generic";
import { fireTagManagerEvent } from "../../../assets/Services/tagmanager";

export default function openBuyModal(nftId: string) {
  openModal({ content: <Buy nftId={nftId} /> });
}

interface Props {
  nftId: string;
}

function Buy({ nftId }: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [nft, setNft] = useState<iNft>();
  const [credits, setCredits] = useState(0);
  const user = useSelector(selectUser);

  const [isPaymentIntent, setIsPaymentIntent] = useState(false);

  const orders = useSelector(selectPendingOrders);
  const [order, setOrder] = useState<iBuyOrder>();

  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery({ query: SMALL_SCREEN });

  const load = async () => {
    setIsLoading(true);
    try {
      const data = await NftController.getNft(String(nftId));
      setNft(data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const order = orders.find((o) => o.nftId === String(nft?._id));
    if (order) {
      setOrder(order);
      //setIsPaymentIntent(true); // Requested to open always first step modal
    }
  }, [orders, nft]);

  useEffect(() => {
    load();
  }, [nftId]);

  const loadCredits = async () => {
    setIsLoading(true);
    try {
      const c = await UserController.getCreditsBalance();
      setCredits(c);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadCredits();
  }, [user]);

  return (
    <>
      {isPaymentIntent && nft ? (
        <>
          <WalletCheckout nft={nft} />
        </>
      ) : (
        <>
          <div className="d-flex justify-content-center align-items-center py-2 border-bottom">
            <span style={{ height: "22px" }}></span>
          </div>
          {!isLoading && nft ? (
            <>
              <div
                className="modal-body"
                style={{
                  maxHeight: isSmallScreen ? "calc(100dvh - 88px)" : "unset",
                }}
              >
                <div className="height-24"></div>
                <span className="h3 medium modal-padding py-32">
                  {t("app.checkout.buy_now_from")} {nft._ownerName}
                </span>
                <div className="height-24"></div>
                <div className="w100 modal-padding">
                  <BookingHeader nft={nft} />
                </div>

                <div className="height-32"></div>

                <div className="horizontal-divider m-0"></div>

                <div className="height-16"></div>

                <div className="d-flex justify-content-between modal-padding">
                  <span className="bodytext light">
                    {t("app.checkout.price")}:
                  </span>
                  <span className="bodytext medium">
                    €{nft.currentPrice?.toFixed(2)}
                  </span>
                </div>

                <div className="height-12"></div>

                <div className="d-flex justify-content-between modal-padding">
                  <span className="bodytext light">
                    {t("app.checkout.service_fee")}:
                  </span>
                  <span className="bodytext medium">
                    €
                    {(Number(nft.currentPrice) * DEFAULT_OVERPRICE)?.toFixed(2)}
                  </span>
                </div>

                {credits > 0 ? (
                  <>
                    <div className="height-12"></div>

                    <div className="d-flex justify-content-between modal-padding">
                      <span className="bodytext light">
                        {t("app.checkout.credits_discout")}:
                      </span>
                      <span className="bodytext medium">
                        - €
                        {(credits > nft.currentPrice!
                          ? nft.currentPrice!
                          : credits
                        ).toFixed(2)}
                      </span>
                    </div>
                  </>
                ) : null}

                <div className="height-16"></div>

                <div className="horizontal-divider m-0"></div>

                <div className="height-16"></div>

                <div className="d-flex justify-content-between modal-padding">
                  <span className="h4 medium">{t("app.checkout.total")}:</span>
                  <span className="h4 medium">
                    €
                    {(
                      nft.currentPrice! * (1 + DEFAULT_OVERPRICE) -
                      Math.min(credits, nft.currentPrice!)
                    ).toFixed(2)}
                  </span>
                </div>

                <div className="height-16"></div>
                <div className="horizontal-divider m-0"></div>
                <div className="height-16"></div>

                <span className="bodytext-xs light modal-padding">
                  {t("app.checkout.paymentsecure")}
                </span>

                <div className="height-60"></div>
              </div>
              <div className="d-flex justify-content-between modal-padding border-top py-2">
                <Button
                  variant="blank"
                  onlyText={true}
                  text={t("app.checkout.cancel")}
                  onClick={closeModal}
                />
                <Button
                  text={t("app.checkout.proceed")}
                  onClick={() => {
                    fireTagManagerEvent("buy_intent");
                    setIsPaymentIntent(true);
                  }}
                />
              </div>
            </>
          ) : (
            <>
              <div className="modal-padding py-32">
                <Skeleton height={40} className="w-100"></Skeleton>
              </div>
              <div className="modal-body">
                <div className="modal-padding">
                  <Skeleton height={118} className="w-100"></Skeleton>
                </div>
                <div className="height-32"></div>
                <div className="horizontal-divider m-0"></div>
                <div className="height-16"></div>
                <div className="modal-padding">
                  <Skeleton height={22} className="w-100"></Skeleton>
                </div>
                <div className="height-16"></div>
                <div className="modal-padding">
                  <Skeleton height={22} className="w-100"></Skeleton>
                </div>
                <div className="height-16"></div>
                <div className="modal-padding">
                  <Skeleton height={22} className="w-100"></Skeleton>
                </div>
                <div className="height-16"></div>
                <div className="horizontal-divider m-0"></div>
                <div className="height-16"></div>
                <div className="modal-padding">
                  <Skeleton height={32} className="w-100"></Skeleton>
                </div>
                <div className="height-60"></div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}
