import { useTranslation } from "react-i18next";
import { iWallSlot } from "../../../../config/wall";
import Button from "../Button/Button";

import telegramSvg from "../../../../assets/img/icons/telegram.svg";
import instagramSvg from "../../../../assets/img/icons/instagram.svg";
import whatsappSvg from "../../../../assets/img/icons/whatsapp.svg";
import { useMediaQuery } from "react-responsive";
import { SMALL_SCREEN } from "../../../Utils/generic";

interface Props {
  slot?: iWallSlot;
}

export default function SocialRequest(props: Props) {
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery({ query: SMALL_SCREEN });

  return (
    <section className="light-black-background rounded mt-5 mb-5 p-4">
      <div className="text-white">
        <h2 className="h1 regular m-0">{t("wall.request_social")}:</h2>
        <div className="height-40"></div>

        <a
          hidden
          target="_blank"
          rel="noreferrer"
          id="social-whatsapp-link"
          href="https://whatsapp.com/channel/0029VaglvQgFSAt9vRXlow2y"
        ></a>
        <a
          hidden
          target="_blank"
          rel="noreferrer"
          id="social-telegram-link"
          href="https://t.me/takyonexchange"
        ></a>
        <a
          hidden
          target="_blank"
          rel="noreferrer"
          id="social-instagram-link"
          href="https://www.instagram.com/takyon.io"
        ></a>

        <div className="d-flex gap-3 justify-content-center justify-content-md-start">
          <Button
            customIcon={<img src={whatsappSvg} />}
            size="sm"
            text={isSmallScreen ? "" : "Whatsapp"}
            variant="blank-light"
            onClick={() => {
              document.getElementById("social-whatsapp-link")?.click();
            }}
          />
          <Button
            customIcon={<img src={telegramSvg} />}
            size="sm"
            text={isSmallScreen ? "" : "Telegram"}
            variant="blank-light"
            onClick={() => {
              document.getElementById("social-telegram-link")?.click();
            }}
          />
          <Button
            customIcon={<img src={instagramSvg} />}
            size="sm"
            text={isSmallScreen ? "" : "Instagram"}
            variant="blank-light"
            onClick={() => {
              document.getElementById("social-instagram-link")?.click();
            }}
          />
        </div>

        <div className="height-24"></div>
      </div>
    </section>
  );
}
