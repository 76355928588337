import { useState } from "react";
import { useTranslation } from "react-i18next";
import Account from "./Profile/Account";
import Privacy from "./Profile/Privacy";
import Preferences from "./Profile/Preferences";
import TabLayout from "../Layouts/TabLayout";

export default function ProfilePage() {
  const { t } = useTranslation();

  const tabs = [
    {
      key: "account_section",
      label: t("profile_page.account.tab_title"),
      component: <Account />,
    },
    {
      key: "account_privacy",
      label: t("profile_page.privacy.tab_title"),
      component: <Privacy />,
    },
    {
      key: "account_preferences",
      label: t("profile_page.preferences.tab_title"),
      component: <Preferences />,
    },
  ];

  return (
    <>
      <TabLayout
        title={t("profile_page.title")}
        tabs={tabs}
        defaultTabKey="account_section" // Imposta la scheda di default
      />
    </>
  );
}
