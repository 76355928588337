import {
  EmailShareButton,
  EmailIcon,
  TelegramShareButton,
  TelegramIcon,
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import Input from "../Input/Input";
import { Form } from "react-bootstrap";
import { useState } from "react";
import { fireTagManagerEvent } from "../../../Services/tagmanager";
import { useTranslation } from "react-i18next";

interface Props {
  link: string;
  id: string;
}

export default function Share({ link, id }: Props) {
  const { t } = useTranslation();
  const [copied, setCopied] = useState(false);

  const copy = () => {
    fireTagManagerEvent("share");

    try {
      const copyText = document.getElementById(
        "share-link-input"
      ) as HTMLInputElement;
      copyText?.select();
      copyText.setSelectionRange(0, 99999);

      navigator.clipboard.writeText(copyText.value);
      setCopied(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="h-100 p-4" style={{ width: "100%" }}>
      <a
        id={`email-link-${id}`}
        hidden
        href={`mailto:?subject=Look what I've found on Takyon&body=${link}`}
      ></a>
      <div className="h-100 d-flex flex-column justify-content-center">
        <p className="m-0 h3 regular">{t("general.share")}</p>
        <div className="height-8"></div>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Input
            id="share-link-input"
            readOnly
            icon="content_copy"
            value={link}
            status={copied ? "success" : "normal"}
            className="cursor-pointer"
            onClick={copy}
            onIconClick={copy}
          />
          <Form.Text className="text-muted">
            {copied ? String(t("share.copied")) : String(t("share.copy"))}
          </Form.Text>
        </Form.Group>

        <div className="d-flex justify-content-start gap-2">
          <EmailShareButton
            onClick={() => {
              fireTagManagerEvent("share");
              document.getElementById(`email-link-${id}`)?.click();
            }}
            url={link}
            subject="Discover Takyon"
            body={t("share.body")}
          >
            <EmailIcon size={32} round />
          </EmailShareButton>

          <TelegramShareButton
            onClick={() => fireTagManagerEvent("share")}
            url={link}
          >
            <TelegramIcon size={32} round />
          </TelegramShareButton>

          <FacebookShareButton
            onClick={() => fireTagManagerEvent("share")}
            url={link}
          >
            <FacebookIcon size={32} round />
          </FacebookShareButton>

          <WhatsappShareButton
            onClick={() => fireTagManagerEvent("share")}
            url={link}
          >
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>
        </div>
      </div>
    </div>
  );
}
