import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import getLink, { LINK_TYPE } from "../../../config/Links";
import Button from "../../../assets/TakyonDesignSystem/components/Button/Button";
import BecomePartner from "../../components/Body/BecomePartner/BecomePartner";
import Animate from "../../components/Animate/Animate";
import { SMALL_SCREEN } from "../../../assets/Utils/generic";

import p1 from "../../../assets/img/forhotels/p1.jpg";
import p2 from "../../../assets/img/forhotels/p2.jpg";
import p3 from "../../../assets/img/forhotels/p3.jpg";
import p4 from "../../../assets/img/forhotels/p4.jpg";
import p5 from "../../../assets/img/forhotels/p5.jpg";
import p6 from "../../../assets/img/forhotels/p6.jpg";
import p7 from "../../../assets/img/forhotels/p7.jpg";
import p8 from "../../../assets/img/forhotels/p8.jpg";
import p9 from "../../../assets/img/forhotels/p9.jpg";
import p10 from "../../../assets/img/forhotels/p10.jpg";
import p11 from "../../../assets/img/forhotels/p11.jpg";
import p12 from "../../../assets/img/forhotels/p12.jpg";

import l1 from "../../../assets/img/forhotels/l1.svg";
import l2 from "../../../assets/img/forhotels/l2.png";
import l3 from "../../../assets/img/forhotels/l3.svg";
import l4 from "../../../assets/img/forhotels/l4.svg";
import l5 from "../../../assets/img/forhotels/l5.svg";
import l6 from "../../../assets/img/forhotels/l6.svg";
import l7 from "../../../assets/img/forhotels/l7.svg";
import l8 from "../../../assets/img/forhotels/l8.svg";
import l9 from "../../../assets/img/forhotels/l9.svg";
import l10 from "../../../assets/img/forhotels/l10.svg";
import l11 from "../../../assets/img/forhotels/l11.svg";
import l12 from "../../../assets/img/forhotels/l12.svg";

import heroimg from "../../../assets/img/forhotels/00_IMAGELANDING.jpg";
import { scrollTo } from "../../../assets/Utils/generic";

export function ImgPartner({
  index,
  img,
  logo,
  height,
  link,
}: {
  height: number;
  index: number;
  link: string;
  img: string;
  logo: string;
}) {
  const [id] = useState(String(Math.random()));

  return (
    <>
      <a id={id} hidden target="_blank" rel="noreferrer" href={link}></a>
      <Animate
        style={{
          margin: "5px",
          height: height + "px",
          transform: `translateY(${index % 2 === 0 ? -20 : 20}px)`,
        }}
        delay={index * 0.1}
        key={index}
        className="partner-img w100 position-relative rounded overflow-hidden d-flex align-items-center justify-content-center cursor-pointer"
        element={
          <>
            <div
              onClick={() => {
                document.getElementById(id)?.click();
              }}
              style={{
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                opacity: 0.3,
                zIndex: 2,
              }}
              className="position-absolute black-background"
            ></div>
            <img
              className="position-absolute"
              src={logo}
              alt=""
              style={{ zIndex: 3, width: "65%" }}
            />
            <img
              style={{ objectFit: "cover", height: height + "px", zIndex: 1 }}
              className="w100 partner-bg"
              src={img}
              alt=""
            />
          </>
        }
      ></Animate>
    </>
  );
}

export default function ForHotels({
  title1,
  title2,
  title3,
  title4,
  titleClass,
  cobrandingImg,
}: {
  title1?: string;
  title2?: string;
  title3?: string;
  title4?: string;
  titleClass?: string;
  cobrandingImg?: string;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery({ query: SMALL_SCREEN });

  const partners = [
    {
      image: p1,
      logo: l1,
      link: "https://www.bestwestern.it/hotel/it.aspx",
    },
    {
      image: p2,
      logo: l2,
      link: "https://www.voihotels.com/",
    },
    {
      image: p3,
      logo: l3,
      link: "https://www.italianhotelgroup.net/",
    },
    {
      image: p4,
      logo: l4,
      link: "",
    },
    {
      image: p5,
      logo: l5,
      link: "https://www.sanmarcohotels.com/",
    },
    {
      image: p6,
      logo: l6,
      link: "https://www.italicahotels.com/en/",
    },
    {
      image: p7,
      logo: l7,
      link: "",
    },
    {
      image: p8,
      logo: l8,
      link: "https://www.premierhotels.it/index.html",
    },
    {
      image: p9,
      logo: l9,
      link: "https://www.leardinigroup.com/en/",
    },
    {
      image: p10,
      logo: l10,
      link: "https://www.marcopolohotelsgroup.com/",
    },
    {
      image: p11,
      logo: l11,
      link: "https://www.gruppoabc.it/en/",
    },
    {
      image: p12,
      logo: l12,
      link: "https://www.hotelviumilan.com/en/",
    },
  ];

  return (
    <main id="web-page">
      <section
        style={{
          height: isSmallScreen ? "unset" : "100vh",
          background: `url(${heroimg})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
        }}
        className="web-section-container w100 position-relative"
      >
        <div
          style={{ top: 0, bottom: 0, left: 0, right: 0, opacity: 0.7 }}
          className="position-absolute black-background"
        ></div>

        <div style={{ zIndex: 2 }} className="container h100">
          <div className="content h100">
            <div className="height-100 only-mobile"></div>
            <div className="d-flex h100 align-items-center justify-content-center">
              <div className="d-md-flex" style={{ gap: "50px" }}>
                <div className="white-color w100">
                  {cobrandingImg ? (
                    <div
                      style={{
                        width: "200px",
                        zIndex: 2,
                      }}
                    >
                      <img className="w100" src={cobrandingImg} alt="" />
                      <div className="height-25"></div>
                    </div>
                  ) : null}
                  <Animate
                    element={
                      <h1
                        className={`m-0 regular
                      ${titleClass ?? "h1-large"} 
                      `}
                      >
                        {title1 ?? t("forhotels.section_1.1")}{" "}
                        <i>
                          {title2} {title3}
                        </i>
                      </h1>
                    }
                    automatic
                    delay={0}
                  />
                  <div className="height-20"></div>
                  <Animate
                    element={
                      <>
                        <p className="m-0 bodytext light">
                          {t("forhotels.section_1.2")}
                        </p>
                        <p className="m-0 bodytext light">
                          {t("forhotels.section_1.3")}
                        </p>
                      </>
                    }
                    automatic
                    delay={0.3}
                  />
                  <div className="height-40"></div>

                  <Animate
                    element={
                      <Button
                        text={t("forhotels.become_button")}
                        iconFill
                        onClick={() => {
                          scrollTo("becomePartnerForm");
                        }}
                      />
                    }
                    automatic
                    delay={0.6}
                  />
                </div>

                <div className="height-80 only-mobile"></div>
              </div>
            </div>
            <div className="height-50 only-mobile"></div>
          </div>
        </div>
      </section>

      <section className="web-section-container">
        <div className="web-section-content align-items-center">
          <div className="height-90"></div>

          <div className="only-desktop">
            <div className="d-flex justify-content-around">
              {partners
                .slice(0, Math.ceil(partners.length / 2))
                .map((partner, index) => (
                  <ImgPartner
                    link={partner.link}
                    height={250}
                    index={index}
                    img={partner.image}
                    logo={partner.logo}
                  />
                ))}
            </div>
            <div className="d-flex justify-content-around">
              {partners
                .slice(Math.ceil(partners.length / 2), partners.length)
                .map((partner, index) => (
                  <ImgPartner
                    height={250}
                    index={index}
                    link={partner.link}
                    img={partner.image}
                    logo={partner.logo}
                  />
                ))}
            </div>
          </div>

          <div className="only-mobile">
            <div className="d-flex justify-content-around">
              {partners
                .slice(0, Math.ceil(partners.length / 4))
                .map((partner, index) => (
                  <ImgPartner
                    link={partner.link}
                    height={150}
                    index={index}
                    img={partner.image}
                    logo={partner.logo}
                  />
                ))}
            </div>
            <div className="d-flex justify-content-around">
              {partners
                .slice(
                  Math.ceil(partners.length / 4),
                  Math.ceil(partners.length / 4) * 2
                )
                .map((partner, index) => (
                  <ImgPartner
                    height={150}
                    index={index}
                    link={partner.link}
                    img={partner.image}
                    logo={partner.logo}
                  />
                ))}
            </div>
            <div className="d-flex justify-content-around">
              {partners
                .slice(
                  Math.ceil(partners.length / 4) * 2,
                  Math.ceil(partners.length / 4) * 3
                )
                .map((partner, index) => (
                  <ImgPartner
                    height={150}
                    index={index}
                    img={partner.image}
                    link={partner.link}
                    logo={partner.logo}
                  />
                ))}
            </div>
            <div className="d-flex justify-content-around">
              {partners
                .slice(Math.ceil(partners.length / 4) * 3, partners.length)
                .map((partner, index) => (
                  <ImgPartner
                    height={150}
                    index={index}
                    img={partner.image}
                    link={partner.link}
                    logo={partner.logo}
                  />
                ))}
            </div>
          </div>

          <div className="height-80"></div>

          <div className="d-flex justify-content-center">
            <Button
              icon="arrow_outward"
              text={t("forhotels.all_button")}
              onClick={() => {
                navigate(getLink(LINK_TYPE.WEB_PARTNERS));
              }}
            />
          </div>

          <div className="height-100"></div>
        </div>
      </section>
      <div id="becomePartnerForm" className="mt-5 white-background">
        <BecomePartner />
      </div>
      <div className="height-100"></div>
    </main>
  );
}
