import Button from "../../../assets/TakyonDesignSystem/components/Button/Button";
import { useNavigate } from "react-router-dom";

export default function NotFound() {
  const navigate = useNavigate();

  return (
    <main
      className="d-flex flex-column align-items-center justify-content-center white-color"
      style={{ height: "100vh" }}
    >
      <h1 className="text-center">Not Found</h1>
      <br />
      <Button text="Explore Takyon.io" onClick={() => navigate("")} />
    </main>
  );
}
