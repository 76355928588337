import axios from "axios";
import { iPaginationProps } from "../models/iPagination";

export default class PartnerController {
  static async requestPartnership(payload: any) {
    const response = await axios.post("partner-request", { payload });
    return response.data;
  }

  static async getPaginated(params: iPaginationProps, onlyLive = false) {
    const response = await axios.get("/partner/paginate", {
      params: {
        ...params,
        onlyLive: onlyLive ? true : null,
      },
    });
    return response.data;
  }
}
