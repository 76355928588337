import { Route, Routes } from "react-router-dom";
import { ProfileLayout } from "../../components/Profile/ProfileLayout";
import ProfileWallet from "../../components/Profile/ProfileWallet";
import ProfilePage from "../../components/Profile/ProfilePage";
import ProfileFavorites from "../../components/Profile/ProfileFavorites";
import ProfileOffers from "../../components/Profile/ProfileOffers";
import ProfileCredits from "../../components/Profile/ProfileCredits";
import ProfileTransactions from "../../components/Profile/ProfileTransactions";
import TakPutOnSale from "../../components/Profile/Wallet/Actions/TakPutOnSale";
import TakTransferGift from "../../components/Profile/Wallet/Actions/TakTransferGift";
import TakChangePrice from "../../components/Profile/Wallet/Actions/TakChangePrice";
import TakRemoveFromSale from "../../components/Profile/Wallet/Actions/TakRemoveFromSale";
import OfferAccept from "../../components/Profile/Offers/Actions/OfferAccept";
import OfferDecline from "../../components/Profile/Offers/Actions/OfferDecline";
import OfferCancel from "../../components/Profile/Offers/Actions/OfferCancel";
import Counteroffer from "../../components/Profile/Offers/Actions/Counteroffer";
import CounterofferAccept from "../../components/Profile/Offers/Actions/CounterofferAccept";
import CounterofferDecline from "../../components/Profile/Offers/Actions/CounterofferDecline";
import TakCreditRefund from "../../components/Profile/Wallet/Actions/TakCreditRefund";
import FavoritesCategory from "../../components/Profile/Favorites/FavoritesCategory";
import InviteFriend from "../../components/Profile/Credits/InviteFriend";
import Support from "../Support/Support";

export function ProfileSupportWrapper() {
  return (
    <div style={{ overflow: "scroll" }} className="h100 w100 p-3">
      <Support />
    </div>
  );
}

export function ProfileRoutes() {
  // Configurazione Router (aggiungere questo nel componente WebRouter o simile)
  return (
    <Routes>
      <Route path="/" element={<ProfileLayout />}>
        <Route index element={<ProfilePage />} />
        <Route path="/wallet/put-on-sale" element={<TakPutOnSale />} />
        <Route path="/wallet/transfer-gift" element={<TakTransferGift />} />
        <Route path="/wallet/change-price" element={<TakChangePrice />} />
        <Route
          path="/wallet/remove-from-sale"
          element={<TakRemoveFromSale />}
        />
        <Route path="/wallet/credit-refund" element={<TakCreditRefund />} />
        {/* <Route path="/bank-account" element={<TakPutOnSale />} /> */}
        <Route path="/wallet" element={<ProfileWallet />} />
        <Route path="/favorites/:listId" element={<FavoritesCategory />} />
        <Route path="/favorites" element={<ProfileFavorites />} />
        <Route path="/offers/accept-offer" element={<OfferAccept />} />
        <Route path="/offers/cancel-offer" element={<OfferCancel />} />
        <Route path="/offers/decline-offer" element={<OfferDecline />} />
        <Route path="/offers/counter-offer" element={<Counteroffer />} />
        <Route
          path="/offers/counter-offer-accept/seller"
          element={<CounterofferAccept side="seller" />}
        />
        <Route
          path="/offers/counter-offer-accept/buyer"
          element={<CounterofferAccept side="buyer" />}
        />
        <Route
          path="/offers/counter-offer-decline"
          element={<CounterofferDecline />}
        />
        <Route path="/offers" element={<ProfileOffers />} />
        <Route path="/transactions" element={<ProfileTransactions />} />
        <Route path="/credits" element={<ProfileCredits />} />
        <Route path="/invite-friend" element={<InviteFriend />} />
        <Route path="/support" element={<ProfileSupportWrapper />} />
      </Route>
      {/* Altre rotte */}
    </Routes>
  );
}
