import { useMediaQuery } from "react-responsive";
import { iNft } from "../../../assets/models/iNft";
import TakCard from "../../../assets/TakyonDesignSystem/components/TakCard/TakCard";
import { SMALL_SCREEN } from "../../../assets/Utils/generic";

export default function TakSmallList({ nfts }: { nfts: iNft[] }) {
  const isSmallScreen = useMediaQuery({ query: SMALL_SCREEN });

  return (
    <div className="d-flex flex-wrap gap-3">
      {nfts.map((nft) => (
        <TakCard
          small
          preventAspectRatio={isSmallScreen}
          style={{ width: isSmallScreen ? "100%" : "250px" }}
          nft={nft}
          key={nft._id}
        />
      ))}
    </div>
  );
}
