import axios from "axios";
import { LANG } from "../../config/Lang";
import iUser from "../models/iUser";
import store from "../../redux/redux";
import { setUser } from "../../redux/slices/userSlice";
import { fireTagManagerEvent } from "../Services/tagmanager";
import iPendingTransfer from "../models/iPendingTransfers";
import { iPagination, iPaginationProps } from "../models/iPagination";
import iCredits from "../models/iCredit";

const TOKEN_KEY = "7v567c4637x56c4v5" + process.env.REACT_APP_BACKEND_URI;

export default class UserController {
  static async register(payload: {
    email: string;
    password: string;
    firstName?: string;
    secondName?: string;
    marketingEmails?: "on" | "off";
    lang?: LANG;
    referralCode?: string;
  }): Promise<iUser> {
    const response = await axios.post("/auth/register", payload);

    this._login(response.data);

    return response.data;
  }

  static isLoggedIn() {
    const token = window.localStorage.getItem(TOKEN_KEY);
    return token ? true : false;
  }

  static _login(user: iUser) {
    if (!user.token) throw new Error("LOGIN ERROR: Bearer token not found");

    axios.defaults.headers.common["Authorization"] = "Bearer " + user.token;
    window.localStorage.setItem(TOKEN_KEY, user.token);
    store.dispatch(setUser({ ...user }));
  }

  static async login(payload: {
    email: string;
    password: string;
  }): Promise<iUser> {
    const response = await axios.post("/auth/login", payload);

    this._login(response.data);

    fireTagManagerEvent("login", {
      method: "default",
      user_id: response?.data?._id,
    });

    return response.data;
  }

  static async socialLogin(payload: {
    googleToken: string;
    redirect: string;
    referralCode?: string;
  }): Promise<iUser> {
    const response = await axios.post("/auth/social-login", payload);

    this._login(response.data);

    if (payload.googleToken) {
      fireTagManagerEvent("login", {
        method: "google",
        user_id: response?.data?._id,
      });
    }

    return response.data;
  }

  static async refreshUserInfo(): Promise<iUser> {
    const token = window.localStorage.getItem(TOKEN_KEY);
    if (!token) throw new Error("Bearer Token not found");

    const response = await axios.get("/auth");

    this._login(response.data);

    return response.data;
  }

  static hasBearerToken() {
    const token = window.localStorage.getItem(TOKEN_KEY);
    return token ? true : false;
  }

  static setBearerToken() {
    const token = window.localStorage.getItem(TOKEN_KEY);
    if (token)
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;

    return token;
  }

  static async initLoggedUser(): Promise<iUser | undefined> {
    const token = this.setBearerToken();

    if (token) {
      const user = await this.refreshUserInfo();

      return user;
    }
  }

  static async logout() {
    axios.defaults.headers.common["Authorization"] = "";
    window.localStorage.removeItem(TOKEN_KEY);

    store.dispatch(setUser(undefined));
  }

  static async redeemCredits(code: string): Promise<iCredits> {
    const response = await axios.post("/promocode/use", { code });
    return response.data;
  }

  static async sendVerificationEmail(payload: { redirect: string }) {
    const response = await axios.post(
      "/auth/request-verification-email-by-code",
      payload
    );
    return response.data;
  }

  static async verifyEmail(payload: { redirect: string; code: string }) {
    const response = await axios.post(
      "/auth/verification-email-by-code",
      payload
    );
    return response.data;
  }

  static async sendRecoveryPasswordEmail(payload: {
    email: string;
    redirect: string;
  }) {
    const response = await axios.post("/auth/request-reset-password", payload);
    return response.data;
  }

  static async resetPassword(payload: { token: string; password: string }) {
    const response = await axios.post("/auth/reset-password", payload);
    return response.data;
  }

  static async changePassword(payload: {
    oldPassword: string;
    newPassword: string;
  }) {
    const response = await axios.post("/auth/change-password", payload);
    return response.data;
  }

  static async patchUser(payload: {}): Promise<iUser> {
    const response = await axios.patch("/auth/", payload);
    await this.refreshUserInfo();
    return response.data;
  }

  static async getPendingTransfers(): Promise<iPendingTransfer[]> {
    const response = await axios.get("/auth/pending-transfers");
    return response.data;
  }

  static async getCreditsBalance(): Promise<number> {
    const response = await axios.get("/credits");
    return response.data;
  }

  static async getCreditsPaginated(
    params: iPaginationProps = { page: 1, size: 1 }
  ): Promise<iPagination<iCredits>> {
    const response = await axios.get("/credits/paginate", {
      params,
    });
    return response.data;
  }

  static async unsubscribeMarketing(email: string) {
    const response = await axios.post("/emails/unsubscribe", { email });
    return response.data;
  }

  static async deleteAccount() {
    const response = await axios.delete("/auth");
    return response.data;
  }
}
