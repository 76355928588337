import { FormEvent, useEffect, useState } from "react";
import {
  BuilderComponent,
  builder,
  useIsPreviewing,
  Builder,
} from "@builder.io/react";
import NotFound from "./_Shared/pages/NotFound/NotFound";
import PartnerController from "./assets/Controllers/PartnerController";
import { Form } from "react-bootstrap";
import Input from "./assets/TakyonDesignSystem/components/Input/Input";
import { handleInputChange } from "./assets/Utils/input";
import Button from "./assets/TakyonDesignSystem/components/Button/Button";
import SwipeController from "./assets/Controllers/SwipeController";
import Select from "./assets/TakyonDesignSystem/components/Select/Select";
import { fireTagManagerEvent } from "./assets/Services/tagmanager";
import Icon from "./assets/TakyonDesignSystem/components/Icon/Icon";
import { text } from "stream/consumers";
import PrivacyController from "./assets/Controllers/PrivacyController";

// Put your API key here
builder.init(String(process.env.REACT_APP_BUILDER_IO_APIKEY));

const ReducedTellUsMoreForm = (params: {
  emailPlaceholder: string;
  phonePlaceholder: string;
  budgetPlaceholder: string;
  numberOfPeoplePlaceholder: string;
  regionPlaceholder: string;
  datesPlaceholder: string;
  specialRequestsPlaceholder: string;
  checkboxLabel: string;
  submitButton: string;
  thanks_title: string;
  thanks_text: string;
  continue: string;
}) => {
  const [phone, setPhone] = useState(""); // Optional phone number
  const [numberOfPeople, setNumberOfPeople] = useState("");
  const [region, setRegion] = useState("");
  const [specialRequests, setSpecialRequests] = useState("");
  const [checkboxChecked, setCheckboxChecked] = useState(false); // For the required checkbox
  const [isFormSent, setIsFormSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  useEffect(() => {
    // Form validation: Email, Budget, Number of People, Dates, and Checkbox must be valid
    if (
      numberOfPeople.trim() !== "" &&
      checkboxChecked &&
      phone &&
      numberOfPeople.trim() !== "" &&
      specialRequests
    ) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [numberOfPeople, checkboxChecked]);
  const sendForm = async () => {
    setIsLoading(true);
    // save privacy info
    try {
      await PrivacyController.sendConsent({
        email: "",
        target: "ReducedTellUsMoreForm",
        consent: checkboxChecked,
      });
    } catch (error) {
      console.log(error);
    }
    try {
      await SwipeController.requestStay({
        phone, // Optional field
        numberOfPeople,
        specialRequests,
        region,
      });
      setIsFormSent(true);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };
  return (
    <>
      {isFormSent ? (
        <div className="d-flex flex-column h-100">
          <div className="d-flex align-items-center py-2">
            <span style={{ height: "22px" }}></span>
          </div>
          <div className="height-24"></div>
          <div className="d-flex flex-column justify-content-center h100 w100">
            <div className="height-10"></div>
            <span className="h2 white-color">{params.thanks_title}</span>
            <div className="height-10"></div>
            <span className="bodytext white-color">{params.thanks_text}</span>
            <div>
              <Button
                style={{ marginTop: "20px", marginBottom: "20px" }}
                text={params.continue}
                onClick={() => {
                  // Reset the form and close the modal or continue
                  setIsFormSent(false);
                  setPhone("");
                  setNumberOfPeople("1");
                  setSpecialRequests("");
                  setCheckboxChecked(false);
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        <form
          className="d-flex flex-column"
          onSubmit={(e: FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            sendForm();
          }}
        >
          {/* Phone Number (optional) */}
          <Form.Group className="py-8">
            <Input
              type="text"
              placeholder={params.phonePlaceholder}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </Form.Group>
          {/* Number of People */}
          <Form.Group className="py-8">
            <Select
              value={numberOfPeople}
              onChange={(e) => setNumberOfPeople(e.target.value)}
              options={[
                { value: "", text: params.numberOfPeoplePlaceholder },
                { value: "mountain", text: "Avventura in montagna" },
                { value: "lake", text: "Relax al lago" },
                { value: "city", text: "Fuga in città" },
                { value: "sea", text: "Vacanze al mare" },
                {
                  value: "food_experience",
                  text: "Esperienze enogastronomiche",
                },
                { value: "romantic_experience", text: "Esperienze romantiche" },
                { value: "adventure_experience", text: "Esperienze avventura" },
              ]}
            />
          </Form.Group>
          <Form.Group className="py-8">
            <Select
              value={region}
              onChange={(e) => setRegion(e.target.value)}
              options={[
                { value: "", text: params.regionPlaceholder },
                { value: "abruzzo", text: "Abruzzo" },
                { value: "basilicata", text: "Basilicata" },
                { value: "calabria", text: "Calabria" },
                { value: "campania", text: "Campania" },
                { value: "emilia-romagna", text: "Emilia-Romagna" },
                {
                  value: "friuli-venezia-giulia",
                  text: "Friuli-Venezia Giulia",
                },
                { value: "lazio", text: "Lazio" },
                { value: "liguria", text: "Liguria" },
                { value: "lombardia", text: "Lombardia" },
                { value: "marche", text: "Marche" },
                { value: "molise", text: "Molise" },
                { value: "piemonte", text: "Piemonte" },
                { value: "puglia", text: "Puglia" },
                { value: "sardegna", text: "Sardegna" },
                { value: "sicilia", text: "Sicilia" },
                { value: "toscana", text: "Toscana" },
                { value: "trentino-alto-adige", text: "Trentino-Alto Adige" },
                { value: "umbria", text: "Umbria" },
                { value: "valle-d-aosta", text: "Valle d'Aosta" },
                { value: "veneto", text: "Veneto" },
              ]}
            />
          </Form.Group>
          {/* Special Requests */}
          <Form.Group className="py-8">
            <Input
              type="text"
              placeholder={params.specialRequestsPlaceholder}
              value={specialRequests}
              onChange={(e) => setSpecialRequests(e.target.value)}
            />
          </Form.Group>
          {/* Checkbox (required) */}
          <Form.Group className="py-8">
            <div className="d-flex gap-2">
              <div>
                <input
                  type="checkbox"
                  id="checkbox"
                  checked={checkboxChecked}
                  onChange={() => setCheckboxChecked(!checkboxChecked)}
                />
              </div>
              <label
                className="white-color"
                htmlFor="checkbox"
                style={{ marginLeft: "8px" }}
              >
                {params.checkboxLabel}
              </label>
            </div>
          </Form.Group>
          {/* Submit Button */}
          <div className="d-flex justify-content-start mt-3">
            <Button
              type="submit"
              text={params.submitButton}
              disabled={!isFormValid}
              loading={isLoading}
            />
          </div>
        </form>
      )}
    </>
  );
};
// Register the ReducedTellUsMoreForm component with Builder.io
Builder.registerComponent(ReducedTellUsMoreForm, {
  name: "ReducedTellUsMoreForm4",
  inputs: [
    { name: "emailPlaceholder", type: "text" },
    { name: "phonePlaceholder", type: "text" }, // Added for phone number
    { name: "budgetPlaceholder", type: "text" },
    { name: "numberOfPeoplePlaceholder", type: "text" },
    { name: "datesPlaceholder", type: "text" },
    { name: "specialRequestsPlaceholder", type: "text" },
    { name: "checkboxLabel", type: "text" }, // Added for checkbox label
    { name: "submitButton", type: "text" },
    { name: "regionPlaceholder", type: "text" },
    { name: "thanks_title", type: "text" },
    { name: "thanks_text", type: "text" },
    { name: "continue", type: "text" },
  ],
});

const ReducedTellUsMoreForm5 = (params: {
  emailPlaceholder: string;
  phonePlaceholder: string;
  budgetPlaceholder: string;
  numberOfPeoplePlaceholder: string;
  regionPlaceholder: string;
  datesPlaceholder: string;
  specialRequestsPlaceholder: string;
  checkboxLabel: string;
  cta_text: string;
  submitButton: string;
  thanks_title: string;
  thanks_text: string;
  continue: string;
}) => {
  const [phone, setPhone] = useState(""); // Optional phone number
  const [numberOfPeople, setNumberOfPeople] = useState("");
  const [region, setRegion] = useState("");
  const [specialRequests, setSpecialRequests] = useState("");
  const [checkboxChecked, setCheckboxChecked] = useState(false); // For the required checkbox
  const [isFormSent, setIsFormSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  useEffect(() => {
    // Form validation: Email, Budget, Number of People, Dates, and Checkbox must be valid
    if (
      numberOfPeople.trim() !== "" &&
      checkboxChecked &&
      phone &&
      numberOfPeople.trim() !== "" &&
      specialRequests
    ) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [numberOfPeople, checkboxChecked]);
  const sendForm = async () => {
    setIsLoading(true);
    // save privacy info
    try {
      await PrivacyController.sendConsent({
        email: "",
        target: "ReducedTellUsMoreForm",
        consent: checkboxChecked,
      });
    } catch (error) {
      console.log(error);
    }
    try {
      await SwipeController.requestStay({
        phone, // Optional field
        numberOfPeople,
        specialRequests,
        region,
      });
      setIsFormSent(true);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };
  return (
    <>
      {isFormSent ? (
        <div className="d-flex flex-column h-100">
          <div className="d-flex align-items-center py-2">
            <span style={{ height: "22px" }}></span>
          </div>
          <div className="height-24"></div>
          <div className="d-flex flex-column justify-content-center h100 w100">
            <div className="height-10"></div>
            <span className="h2 white-color">{params.thanks_title}</span>
            <div className="height-10"></div>
            <span className="bodytext white-color">{params.thanks_text}</span>
            <div>
              <Button
                style={{ marginTop: "20px", marginBottom: "20px" }}
                text={params.continue}
                onClick={() => {
                  // Reset the form and close the modal or continue
                  setIsFormSent(false);
                  setPhone("");
                  setNumberOfPeople("1");
                  setSpecialRequests("");
                  setCheckboxChecked(false);
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        <form
          className="d-flex flex-column"
          onSubmit={(e: FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            sendForm();
          }}
        >
          {/* Number of People */}
          <Form.Group className="py-8">
            <Select
              value={numberOfPeople}
              onChange={(e) => setNumberOfPeople(e.target.value)}
              options={[
                { value: "", text: params.numberOfPeoplePlaceholder },
                { value: "mountain", text: "Avventura in montagna" },
                { value: "lake", text: "Relax al lago" },
                { value: "city", text: "Fuga in città" },
                { value: "sea", text: "Vacanze al mare" },
                {
                  value: "food_experience",
                  text: "Esperienze enogastronomiche in giornata",
                },
                {
                  value: "romantic_experience",
                  text: "Esperienze romantiche in giornata",
                },
                {
                  value: "adventure_experience",
                  text: "Esperienze avventura in giornata",
                },
              ]}
            />
          </Form.Group>
          <Form.Group className="py-8">
            <Select
              value={region}
              onChange={(e) => setRegion(e.target.value)}
              options={[
                { value: "", text: params.regionPlaceholder },
                { value: "abruzzo", text: "Abruzzo" },
                { value: "basilicata", text: "Basilicata" },
                { value: "calabria", text: "Calabria" },
                { value: "campania", text: "Campania" },
                { value: "emilia-romagna", text: "Emilia-Romagna" },
                {
                  value: "friuli-venezia-giulia",
                  text: "Friuli-Venezia Giulia",
                },
                { value: "lazio", text: "Lazio" },
                { value: "liguria", text: "Liguria" },
                { value: "lombardia", text: "Lombardia" },
                { value: "marche", text: "Marche" },
                { value: "molise", text: "Molise" },
                { value: "piemonte", text: "Piemonte" },
                { value: "puglia", text: "Puglia" },
                { value: "sardegna", text: "Sardegna" },
                { value: "sicilia", text: "Sicilia" },
                { value: "toscana", text: "Toscana" },
                { value: "trentino-alto-adige", text: "Trentino-Alto Adige" },
                { value: "umbria", text: "Umbria" },
                { value: "valle-d-aosta", text: "Valle d'Aosta" },
                { value: "veneto", text: "Veneto" },
              ]}
            />
          </Form.Group>
          {/* Special Requests */}
          <Form.Group className="py-8">
            <Input
              type="text"
              placeholder={params.specialRequestsPlaceholder}
              value={specialRequests}
              onChange={(e) => setSpecialRequests(e.target.value)}
            />
          </Form.Group>
          <p
            style={{ fontSize: "16px", fontWeight: 300 }}
            className="text-white"
          >
            {params.cta_text}
          </p>

          {/* Phone Number (optional) */}
          <Form.Group className="py-8">
            <Input
              type="text"
              placeholder={params.phonePlaceholder}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </Form.Group>
          {/* Checkbox (required) */}
          <Form.Group className="py-8">
            <div className="d-flex gap-2">
              <div>
                <input
                  type="checkbox"
                  id="checkbox"
                  checked={checkboxChecked}
                  onChange={() => setCheckboxChecked(!checkboxChecked)}
                />
              </div>
              <label
                className="white-color"
                htmlFor="checkbox"
                style={{ marginLeft: "8px" }}
              >
                {params.checkboxLabel}
              </label>
            </div>
          </Form.Group>
          {/* Submit Button */}
          <div className="d-flex justify-content-start mt-3">
            <Button
              type="submit"
              text={params.submitButton}
              disabled={!isFormValid}
              loading={isLoading}
            />
          </div>
        </form>
      )}
    </>
  );
};
// Register the ReducedTellUsMoreForm component with Builder.io
Builder.registerComponent(ReducedTellUsMoreForm5, {
  name: "ReducedTellUsMoreForm6",
  inputs: [
    { name: "emailPlaceholder", type: "text" },
    { name: "phonePlaceholder", type: "text" }, // Added for phone number
    { name: "budgetPlaceholder", type: "text" },
    { name: "numberOfPeoplePlaceholder", type: "text" },
    { name: "datesPlaceholder", type: "text" },
    { name: "specialRequestsPlaceholder", type: "text" },
    { name: "cta_text", type: "text" },
    { name: "checkboxLabel", type: "text" }, // Added for checkbox label
    { name: "submitButton", type: "text" },
    { name: "regionPlaceholder", type: "text" },
    { name: "thanks_title", type: "text" },
    { name: "thanks_text", type: "text" },
    { name: "continue", type: "text" },
  ],
});

export function BecomePartnerSmart(params: {
  emailPlaceholder: string;
  websitePlaceholder: string;
  phonePlaceholder: string;
  infotext: string;
  button: string;
  success: string;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isFormSent, setIsFormSent] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [partnerInformation, setPartnerInformation] = useState<{
    phoneNumber: string;
    website: string;
    email: string;
  }>({
    phoneNumber: "",
    website: "",
    email: "",
  });
  const SendFormClick = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!partnerInformation.website || !partnerInformation.phoneNumber) return;
    setIsLoading(true);
    try {
      await PartnerController.requestPartnership({
        hotelName: partnerInformation.website,
        phoneNumber: partnerInformation.phoneNumber,
        email: partnerInformation.email,
      });

      fireTagManagerEvent("partner_submit");

      setIsFormSent(true);
    } catch (error) {}
    setIsLoading(false);
  };

  useEffect(() => {
    // Form validation
    if (
      partnerInformation.phoneNumber.trim().length > 0 &&
      partnerInformation.website.trim().length > 0 &&
      partnerInformation.email.trim().length > 0
    ) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [partnerInformation]);

  return (
    <section className="web-section-container">
      {!isFormSent ? (
        <div className="web-section-content align-items-center">
          <form
            onSubmit={SendFormClick}
            style={{ width: "100%", gap: "0" }}
            id="become-partner-form-builder-io"
          >
            <Form.Group className="w100">
              <Input
                type="email"
                name="email"
                placeholder={params.emailPlaceholder}
                value={partnerInformation.email}
                onChange={(e) => handleInputChange(e, setPartnerInformation)}
              />
            </Form.Group>
            <div className="height-12"></div>
            <Form.Group className="w100">
              <Input
                type="text"
                name="website"
                placeholder={params.websitePlaceholder}
                value={partnerInformation.website}
                onChange={(e) => handleInputChange(e, setPartnerInformation)}
              />
            </Form.Group>
            <div className="height-12"></div>
            <Form.Group className="w100">
              {/* <Form.Label>{params.phoneLabel}</Form.Label> */}
              <Input
                type="number"
                name="phoneNumber"
                placeholder={params.phonePlaceholder}
                value={partnerInformation.phoneNumber}
                onChange={(e) => handleInputChange(e, setPartnerInformation)}
              />
            </Form.Group>
            <div className="height-12"></div>
            <span className="bodytext-sm dark-grey-color regular">
              {params.infotext}
            </span>
            <div className="height-20"></div>
            <div className="">
              <Button
                text={params.button}
                type="submit"
                disabled={!isFormValid}
                size="lg"
                loading={isLoading}
              />
            </div>
          </form>
        </div>
      ) : (
        <div className="web-section-content align-items-center">
          <div>{params.success}</div>
        </div>
      )}
    </section>
  );
}
Builder.registerComponent(BecomePartnerSmart, {
  name: "BecomePartnerFormSmart3",
  inputs: [
    { name: "emailPlaceholder", type: "text" },
    { name: "websitePlaceholder", type: "text" },
    { name: "phonePlaceholder", type: "text" },
    { name: "infotext", type: "text" },
    { name: "button", type: "text" },
    { name: "success", type: "text" },
  ],
});

const MetamatchDetailsForm = (params: {
  detailsPlaceholder: string;
  submitButton: string;
  emailPlaceholder: string;
}) => {
  const [details, setDetails] = useState("");
  const [email, setEmail] = useState("");
  const [isFormSent, setIsFormSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  useEffect(() => {
    // Form validation: Email, Budget, Number of People, Dates, and Checkbox must be valid
    if (details !== "") {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [details]);
  const sendForm = async () => {
    setIsLoading(true);
    try {
      await SwipeController.requestStay({
        details,
        email,
      });
      setDetails("");
      setEmail("");
      setIsFormSent(true);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };
  return (
    <>
      <form
        className="d-flex flex-column px-3"
        onSubmit={(e: FormEvent<HTMLFormElement>) => {
          e.preventDefault();
          sendForm();
        }}
      >
        <Form.Group className="w100">
          <Input
            type="email"
            name="email"
            placeholder={params.emailPlaceholder}
            value={email}
            onChange={(e) => setDetails(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="py-8">
          <Input
            type="text"
            placeholder={params.detailsPlaceholder}
            value={details}
            onChange={(e) => setDetails(e.target.value)}
          />
        </Form.Group>
        {/* Submit Button */}
        <div className="d-flex justify-content-center align-items-center mt-3">
          <Button
            type="submit"
            text={params.submitButton}
            disabled={!isFormValid}
            loading={isLoading}
          />
        </div>
      </form>
    </>
  );
};
// Register the ReducedTellUsMoreForm component with Builder.io
Builder.registerComponent(MetamatchDetailsForm, {
  name: "MetamatchdetailsForm6",
  inputs: [
    { name: "detailsPlaceholder", type: "text" },
    { name: "emailPlaceholder", type: "text" },
    { name: "submitButton", type: "text" },
  ],
});

const MetamatchMessageForm = (params: {
  takyonMessage: string;
  messagePlaceholder: string;
  submitButton: string;
  phonenumber: string;
}) => {
  const [textSend, setTextSend] = useState("");
  const [textRemain, setTextRemaind] = useState(200);

  return (
    <>
      <div className="d-flex flex-column gap-2">
        <div className="d-flex gap-2">
          <div
            className="d-flex align-items-center justify-content-center rounded-5 primary-background"
            style={{
              width: "30px",
              height: "30px",
            }}
          >
            <svg
              width="15"
              height="15"
              viewBox="0 0 211 196"
              fill="black"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M83.0507 0.0318604H0V27.9559H83.0507V0.0318604Z" />
              <path d="M210.945 27.9238V-0.000244141H148.452C116.507 -0.000244141 90.528 24.3198 90.528 54.2247V195.206H120.357V54.2247C120.357 39.726 132.95 27.9238 148.452 27.9238H210.945Z" />
            </svg>
          </div>
          <div className="white-background p-2 rounded-3 px-3">
            <p className="medium" style={{ color: "#70C56A" }}>
              Takyon
            </p>
            <p>{params.takyonMessage}</p>
          </div>
        </div>
        <Form.Group className="d-flex gap-2">
          <div
            style={{
              width: "30px",
              height: "30px",
            }}
          ></div>
          <div
            className="white-background p-2 rounded-3"
            style={{ width: "250px" }}
          >
            <Input
              className="border-0"
              maxLength={200}
              type="textarea"
              placeholder={params.messagePlaceholder}
              value={textSend}
              onChange={(e) => {
                const text = e.target.value;
                setTextSend(text);
                setTextRemaind(200 - text.length);
              }}
            />
            <p className="light text-dark-grey" style={{ fontSize: "12px" }}>
              {textRemain}/200
            </p>
          </div>
          <div
            className="d-flex align-items-center justify-content-center white-background rounded-5"
            style={{
              width: "30px",
              height: "30px",
            }}
          >
            <Icon icon="person"></Icon>
          </div>
        </Form.Group>
        <div className="d-flex">
          <div
            className="primary-background rounded-3 p-2 px-3"
            style={{
              marginLeft: "205px",
              cursor: "pointer",
              opacity: !textSend ? 0.5 : 1,
            }}
            onMouseDown={(e) => {
              e.preventDefault();
              if (!textSend) return;
              window.open(
                `https://api.whatsapp.com/send?phone=${params.phonenumber}&text=${textSend}`,
                "_blank"
              );
            }}
          >
            {params.submitButton}
            <Icon icon="send" className="pointer-events-none"></Icon>
          </div>
        </div>
      </div>
    </>
  );
};

Builder.registerComponent(MetamatchMessageForm, {
  name: "MetamatchMessage1",
  inputs: [
    { name: "takyonMessage", type: "text" },
    { name: "messagePlaceholder", type: "text" },
    { name: "submitButton", type: "text" },
    { name: "phonenumber", type: "text" },
  ],
});

const MetamatchAudioForm = (params: {
  takyonMessage: string;
  message: string;
  phonenumber: string;
}) => {
  return (
    <>
      <div className="d-flex flex-column gap-2">
        <div className="d-flex gap-2">
          <div
            className="d-flex align-items-center justify-content-center rounded-5 primary-background"
            style={{
              width: "30px",
              height: "30px",
            }}
          >
            <svg
              width="15"
              height="15"
              viewBox="0 0 211 196"
              fill="black"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M83.0507 0.0318604H0V27.9559H83.0507V0.0318604Z" />
              <path d="M210.945 27.9238V-0.000244141H148.452C116.507 -0.000244141 90.528 24.3198 90.528 54.2247V195.206H120.357V54.2247C120.357 39.726 132.95 27.9238 148.452 27.9238H210.945Z" />
            </svg>
          </div>
          <div className="white-background p-2 rounded-3 px-3">
            <p className="medium" style={{ color: "#70C56A" }}>
              Takyon
            </p>
            <p>{params.takyonMessage}</p>
          </div>
        </div>
        <Form.Group className="d-flex gap-2">
          <div
            style={{
              width: "30px",
              height: "30px",
            }}
          ></div>
          <div
            className="white-background p-2 rounded-3 d-flex flex-column gap-2 align-items-center justify-content-center"
            style={{ width: "250px" }}
          >
            <p className="text-center">{params.message}</p>
            <div
              className="d-flex align-items-center justify-content-center primary-background p-2"
              style={{
                width: "60px",
                height: "60px",
                borderRadius: "100%",
                cursor: "pointer",
              }}
              onTouchStart={(e) => {
                e.preventDefault();
                window.open(
                  `https://api.whatsapp.com/send?phone=${params.phonenumber}`,
                  "_blank"
                );
              }}
              onMouseDown={(e) => {
                e.preventDefault();
                window.open(
                  `https://api.whatsapp.com/send?phone=${params.phonenumber}`,
                  "_blank"
                );
              }}
            >
              <Icon icon="mic" size={30}></Icon>
            </div>
          </div>
          <div
            className="d-flex align-items-center justify-content-center white-background rounded-5"
            style={{
              width: "30px",
              height: "30px",
            }}
          >
            <Icon icon="person"></Icon>
          </div>
        </Form.Group>
      </div>
    </>
  );
};

Builder.registerComponent(MetamatchAudioForm, {
  name: "MetamatchAudio2",
  inputs: [
    { name: "takyonMessage", type: "text" },
    { name: "message", type: "text" },
    { name: "phonenumber", type: "text" },
  ],
});

const MetamatchChatForm = (params: {
  submitButton: string;
  phonenumber: string;
}) => {
  return (
    <>
      <div className="d-flex justify-content-center">
        <div
          className="primary-background rounded-3 p-2 px-3"
          style={{
            cursor: "pointer",
          }}
          onMouseDown={(e) => {
            e.preventDefault();
            window.open(
              `https://api.whatsapp.com/send?phone=${params.phonenumber}`,
              "_blank"
            );
          }}
        >
          {params.submitButton}
          <Icon icon="send" className="pointer-events-none"></Icon>
        </div>
      </div>
    </>
  );
};

Builder.registerComponent(MetamatchChatForm, {
  name: "MetamatchChat1",
  inputs: [
    { name: "submitButton", type: "text" },
    { name: "phonenumber", type: "text" },
  ],
});

function CustomLink(props: any) {
  return <a {...props} />;
}
// set whether you're using the Visual Editor,
// whether there are changes,
// and render the content if found
export default function BuilderIo() {
  const isPreviewingInBuilder = useIsPreviewing();
  const [notFound, setNotFound] = useState(false);
  const [content, setContent] = useState(null);
  // get the page content from Builder
  useEffect(() => {
    async function fetchContent() {
      const content = await builder
        .get("page", {
          url: window.location.pathname,
        })
        .promise();
      setContent(content);
      setNotFound(!content);
      // if the page title is found,
      // set the document title
      if (content?.data.title) {
        document.title = content.data.title;
      }
    }
    fetchContent();
  }, [window.location.pathname]);
  if (notFound && !isPreviewingInBuilder) {
    console.log("Builder.io : not Found");
    return (
      <div className="black-background">
        <NotFound />
      </div>
    );
  }
  if (!content) return <></>;
  // return the page when found
  return (
    <>
      {/* Render the Builder page */}
      <BuilderComponent
        model="page"
        content={content}
        renderLink={(props) => <CustomLink {...props} />}
      />
    </>
  );
}
