import { useState } from "react";
import { useTranslation } from "react-i18next";
import OfferList from "../../../../assets/TakyonDesignSystem/components/Offer/OfferList";

export default function OffersDone() {
  const { t } = useTranslation();
  const [showExpired, setShowExpired] = useState(false);

  return (
    <div id="offers-done">
      {/* offerte inviate */}
      <OfferList side="buyer" />
      <hr />
      <div className="d-flex justify-content-end">
        <a
          className="text-underline black-color"
          onClick={() => {
            setShowExpired(!showExpired);
          }}
        >
          {t(!showExpired ? "generic.show" : "generic.hide")}{" "}
          {t("offers.done.showExpired")}
        </a>
      </div>
      {showExpired && <OfferList side="buyer" expired={true} />}
    </div>
  );
}
