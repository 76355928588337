import axios from "axios";
import { getLocaleLanguage } from "./i18next";
import UserController from "../Controllers/UserController";

export async function initAxios() {
  axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URI;
  axios.defaults.headers.common["Accept-Language"] = await getLocaleLanguage();
}

axios.interceptors.response.use(
  (r) => r,
  (error) => {
    if (String(error?.response?.data?.message).includes("expired")) {
      UserController.logout();
    }
    return Promise.reject(error);
  }
);
