import { useMediaQuery } from "react-responsive";
import { SMALL_SCREEN } from "../../../assets/Utils/generic";
import Animate from "../Animate/Animate";

export default function WebStepper({
  text,
  step1extra,
}: {
  text: string[];
  step1extra?: any;
}) {
  const isSmallScreen = useMediaQuery({ query: SMALL_SCREEN });

  return (
    <>
      <section className="web-section-container">
        <div className="height-150"></div>
        <div className="web-section-content h100 d-flex align-items-center justify-content-center">
          <Animate
            element={
              <>
                <p className="h1-large text-center">{text[0]}</p>
                <p className="h1 dark-grey-color text-center light">
                  {text[1]}
                </p>
              </>
            }
          />
          <div className="height-100"></div>

          <div className="d-flex flex-column flex-md-row d-none">
            <Animate
              delay={0.1}
              style={{ borderRadius: "25px" }}
              className="white-background d-flex flex-column justify-content-between w100"
              element={
                <>
                  <div className="d-flex justify-content-center ">
                    <div className="primary-background py-1 px-2 bodytext medium rounded">
                      01
                    </div>
                  </div>
                  <div className="height-12"></div>

                  <div className="h100 d-flex flex-column justify-content-end">
                    <p className="m-0 h3 light-black-color text-center">
                      {text[2]}
                    </p>

                    <div
                      className="horizontal-divider"
                      style={{ margin: "30px 0" }}
                    ></div>

                    <p className="m-0 bodytext px-5 regular text-center">
                      {text[3]} {step1extra}
                    </p>
                  </div>
                </>
              }
            />

            <Animate
              delay={0.3}
              style={{ borderRadius: "25px" }}
              className="white-background d-flex flex-column justify-content-between w100"
              element={
                <>
                  <div className="d-flex justify-content-center ">
                    <div className="primary-background py-1 px-2 bodytext medium rounded">
                      02
                    </div>
                  </div>
                  <div className="height-12"></div>

                  <div className="h100 d-flex flex-column justify-content-end">
                    <p className="m-0 h3 light-black-color text-center">
                      {text[4]}
                    </p>
                    <div
                      className="horizontal-divider"
                      style={{ margin: "30px 0" }}
                    ></div>

                    <p className="m-0 bodytext px-5 regular text-center">
                      {text[5]}
                    </p>
                  </div>
                </>
              }
            />

            <Animate
              delay={0.5}
              style={{ borderRadius: "25px" }}
              className="white-background d-flex flex-column justify-content-between w100"
              element={
                <>
                  <div className="d-flex justify-content-center ">
                    <div className="primary-background py-1 px-2 bodytext medium rounded">
                      03
                    </div>
                  </div>
                  <div className="height-12"></div>

                  <div className="h100 d-flex flex-column justify-content-end">
                    <p className="m-0 h3 light-black-color text-center">
                      {text[6]}
                    </p>
                    <div
                      className="horizontal-divider"
                      style={{ margin: "30px 0" }}
                    ></div>

                    <p className="m-0 bodytext px-5 regular text-center">
                      {text[7]}
                    </p>
                  </div>
                </>
              }
            />
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="content">
            <div className="d-md-flex">
              <Animate
                style={{
                  borderBottom: isSmallScreen ? "" : "2px solid black",
                  zIndex: 2,
                }}
                delay={0}
                className="white-background d-flex flex-column justify-content-between w100"
                element={
                  <>
                    <div className="d-flex justify-content-center ">
                      <div className="primary-background py-1 px-2 bodytext medium rounded">
                        01
                      </div>
                    </div>
                    <div className="height-12"></div>

                    <div className="h100 d-flex flex-column justify-content-end">
                      <p className="m-0 h3 light-black-color text-center">
                        {text[2]}
                      </p>
                    </div>
                    <div className="height-30 only-desktop"></div>
                    <div className="height-15 only-mobile"></div>
                    <p className="m-0 bodytext regular text-center only-mobile">
                      {text[3]} {step1extra}
                    </p>
                  </>
                }
              />
              <div className="height-50 only-mobile"></div>

              <Animate
                style={{
                  borderBottom: isSmallScreen ? "" : "2px solid black",
                  zIndex: 2,
                }}
                delay={0.3}
                className="white-background d-flex flex-column justify-content-between w100"
                element={
                  <>
                    <div className="d-flex justify-content-center ">
                      <div className="primary-background py-1 px-2 bodytext medium rounded">
                        02
                      </div>
                    </div>
                    <div className="height-12"></div>

                    <div className="h100 d-flex flex-column justify-content-end">
                      <p className="m-0 h3 light-black-color text-center">
                        {text[4]}
                      </p>
                    </div>
                    <div className="height-30 only-desktop"></div>
                    <div className="height-15 only-mobile"></div>
                    <p className="m-0 bodytext regular text-center only-mobile">
                      {text[5]}
                    </p>
                  </>
                }
              />
              <div className="height-50 only-mobile"></div>

              <Animate
                style={{
                  borderBottom: isSmallScreen ? "" : "2px solid black",
                  zIndex: 2,
                }}
                delay={0.6}
                className="white-background d-flex flex-column justify-content-between w100"
                element={
                  <>
                    <div className="d-flex justify-content-center ">
                      <div className="primary-background py-1 px-2 bodytext medium rounded">
                        03
                      </div>
                    </div>
                    <div className="height-12"></div>

                    <div className="h100 d-flex flex-column justify-content-end">
                      <p className="m-0 h3 light-black-color text-center">
                        {text[6]}
                      </p>
                    </div>
                    <div className="height-30 only-desktop"></div>
                    <div className="height-15 only-mobile"></div>
                    <p className="m-0 bodytext regular text-center only-mobile">
                      {text[7]}
                    </p>
                  </>
                }
              />
            </div>
          </div>
        </div>
        <div className="height-100 only-mobile"></div>
      </section>

      <div
        className="horizontal-divider only-desktop"
        style={{ margin: "0", transform: "translateY(-1px)", zIndex: 1 }}
      ></div>

      <section className="only-desktop">
        <div className="height-30"></div>

        <div className="container">
          <div className="content">
            <div className="d-flex">
              <Animate
                delay={0}
                className="white-background d-flex flex-column justify-content-between w100"
                element={
                  <>
                    <p className="m-0 bodytext px-5 regular text-center">
                      {text[3]} {step1extra}
                    </p>
                  </>
                }
              />
              <Animate
                delay={0.3}
                className="white-background d-flex flex-column justify-content-between w100"
                element={
                  <>
                    <p className="m-0 bodytext px-5 regular text-center">
                      {text[5]}
                    </p>
                  </>
                }
              />
              <Animate
                delay={0.6}
                className="white-background d-flex flex-column justify-content-between w100"
                element={
                  <>
                    <p className="m-0 bodytext px-5 regular text-center">
                      {text[7]}
                    </p>
                  </>
                }
              />
            </div>
          </div>
        </div>

        <div className="height-150"></div>
      </section>
    </>
  );
}
