import { useTranslation } from "react-i18next";
import { TakListActive } from "./Wallet/TakListActive";
import { TakListOld } from "./Wallet/TakListOld";
import TabLayout from "../Layouts/TabLayout";

export default function ProfileWallet() {
  const { t } = useTranslation();

  const tabs = [
    {
      key: "active-taks",
      label: t("wallet.section_active_taks"),
      component: <TakListActive />,
    },
    {
      key: "old-taks",
      label: t("wallet.section_old_taks"),
      component: <TakListOld />,
    },
  ];

  return (
    <>
      <TabLayout
        title={t("wallet.title")}
        tabs={tabs}
        defaultTabKey="active-taks" // Imposta la scheda di default
      />
    </>
  );
}
