import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import FavoritesController from "../../../../assets/Controllers/FavoritesController";
import TakCard from "../../../../assets/TakyonDesignSystem/components/TakCard/TakCard";
import { iNft } from "../../../../assets/models/iNft";
import TakSmallList from "../../TakSmallList/TakSmallList";
import Button from "../../../../assets/TakyonDesignSystem/components/Button/Button";
import NftController from "../../../../assets/Controllers/NftController";

export default function FavoritesCategory() {
  const { listId } = useParams();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [favorites, setFavorites] = useState<iNft[]>([]);

  const navigate = useNavigate();

  const loadCategoryFavorites = async () => {
    try {
      setIsLoading(true);
      const resp = await FavoritesController.getListFavorites(listId!);

      setTitle(resp.data.name);

      const nftsPaginated = await NftController.getPaginated({
        page: 1,
        size: 500,
        query: JSON.stringify({
          _id: { $in: resp.data.likes },
        }),
      });
      setFavorites(nftsPaginated.data);

      setIsLoading(false);
    } catch (error) {
      console.log("FavoritesCategory - loadCustomerFavorites error", error);
    }
  };

  useEffect(() => {
    loadCategoryFavorites();
  }, []);

  if (isLoading) return <>Loading...</>;

  if (favorites.length === 0)
    return (
      <div style={{ padding: "0 40px" }}>{t("favorites.no_favorites")}</div>
    );

  return (
    <div className="p-4 h100 d-flex flex-column">
      <div>
        <div>
          <Button
            variant="blank"
            className="dark-grey-color"
            icon="arrow_back"
            onClick={() => navigate(-1)} // Assicurati che `goBack` sia gestito correttamente
            text={t("wallet.generic.goback")}
          />
        </div>

        <div className="height-8"></div>

        <h3 className="h3 medium">{title}</h3>
      </div>

      <div className="height-8"></div>
      <TakSmallList nfts={favorites} />
    </div>
  );
}
