import { useState } from "react";
import ReactDatePicker from "react-datepicker";
import Icon from "../Icon/Icon";
import { range } from "lodash";
import Button from "../Button/Button";
import Select from "../Select/Select";
import { useTranslation } from "react-i18next";

interface Props {
  id?: string | "";
  onChange?(e: Date | null): void;
  defaultValue?: Date | undefined;
  status?: "normal" | "success" | "error";
  className?: string;
  placeholder?: string;
  error?: boolean | false;
  minDate?: Date | undefined;
  maxDate?: Date | undefined;
}

export default function DatePicker(props: Props) {
  const [date, setDate] = useState<Date | undefined>(props.defaultValue);
  const years = range(new Date().getFullYear(), 1900, -1);

  const { t } = useTranslation();

  const months = [
    t("month.january"),
    t("month.february"),
    t("month.march"),
    t("month.april"),
    t("month.may"),
    t("month.june"),
    t("month.july"),
    t("month.august"),
    t("month.september"),
    t("month.october"),
    t("month.november"),
    t("month.december"),
  ];

  return (
    <div
      id={props.id !== "" ? props.id : undefined}
      className={`d-flex align-items-center ${props.className}`}
      style={{ gap: "8px" }}
    >
      <div className="w100 position-relative d-flex align-items-center ">
        <Icon
          style={{ zIndex: "2", left: "8px" }}
          className="black-50-color"
          icon="calendar_month"
          type="symbol"
        />
        <ReactDatePicker
          popperPlacement="top-start"
          formatWeekDay={(name) => t(`weekshort.${name.toLocaleLowerCase()}`)}
          className={props.error ? "error" : ""}
          minDate={props.minDate ? props.minDate : undefined}
          maxDate={props.maxDate ? props.maxDate : undefined}
          dateFormat="dd/MM/yyyy"
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <div
              style={{
                margin: 10,
                display: "flex",
                justifyContent: "center",
                gap: "2px",
              }}
            >
              <Button
                size="sm"
                text="<"
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
                type="button"
              />

              <Select
                value={date.getFullYear()}
                onChange={({ target: { value } }) => changeYear(Number(value))}
                options={years.map((year) => {
                  return { value: year, text: year };
                })}
              />

              <Select
                value={months[date.getMonth()]}
                onChange={({ target: { value } }) =>
                  changeMonth(months.indexOf(value))
                }
                options={months.map((month) => {
                  return { value: month, text: month };
                })}
              />

              <Button
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
                text=">"
                size="sm"
                type="button"
              />
            </div>
          )}
          selected={date}
          placeholderText={props.placeholder ?? t("generic.choosedate")}
          onChange={(e) => {
            props.onChange?.(e);
            if (e) setDate(e);
          }}
        />
        {date ? (
          <Icon
            style={{ zIndex: "2", right: "8px" }}
            className="position-absolute black-color cursor-pointer"
            icon="cancel"
            type="symbol"
            onClick={() => {
              setDate(undefined);
              props.onChange?.(null);
            }}
          />
        ) : null}
      </div>
      {props.status === "success" ? (
        <Icon
          fill
          className="success-color"
          icon="check_circle"
          type="symbol"
        />
      ) : null}
    </div>
  );
}
