import { iNft } from "./iNft";
import iUser from "./iUser";

export enum BID_MODE {
  MONEY = "MONEY",
  CREDITS = "CREDITS",
}

export enum BID_STATUS {
  PENDING = "PENDING",
  DECLINED = "DECLINED",
  ACCEPTED = "ACCEPTED",
  FULFILLED = "FULFILLED",
  EXPIRED = "EXPIRED",
  DELETED = "DELETED",
  BUYER_COUNTEROFFER = "BUYER_COUNTEROFFER",
  SELLER_COUNTEROFFER = "SELLER_COUNTEROFFER",
}

export interface iBid {
  _id: string;

  nftId: string;
  amount: number;

  acceptCounterOffer?: boolean;
  counterOfferAmount?: number;

  sellerEmail?: string;
  buyerEmail?: string;

  expireDate?: Date;

  status: BID_STATUS;

  mode?: BID_MODE;

  // buyback bid
  isBuybackBid?: boolean;
  collectionId?: string;
  deleteOnAccept?: boolean;

  _nft?: iNft;
  _side?: "buyer" | "seller";
  _ownerName?: string;
  _buyerName?: string;

  _createdAt: string;
  _updatedAt: string;
}
