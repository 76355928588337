import axios from "axios";
import store from "../../redux/redux";
import {
  addUserFavorite,
  removeUserFavorite,
  setUserFavoriteIds,
} from "../../redux/slices/userSlice";

export default class FavoritesController {
  static async getLists(userId: string): Promise<any> {
    const response = await axios.get("/favorites/user/" + userId);
    return response.data;
  }

  static async getListFavorites(listId: string): Promise<any> {
    const response = await axios.get("/favorites/list/" + listId);
    return response.data;
  }

  static async getAllFavoritesIds(userId: string): Promise<string[]> {
    const response = await axios.get(`/favorites/user/${userId}/ids`);
    const ids = response.data.data;
    return ids;
  }

  static async createList(
    listName: string,
    userId: string,
    nftId: string
  ): Promise<any> {
    const response = await axios.post("/favorites/list", {
      listName,
      userId,
      nftId,
    });
    return response.data;
  }

  static async addFavToList(
    listId: string,
    userId: string,
    nftId: string
  ): Promise<any> {
    try {
      const response = await axios.post(`/favorites/list/${listId}`, {
        nftId,
        userId,
      });
      store.dispatch(addUserFavorite(nftId));
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async removeLike(userId: string, nftId: string): Promise<any> {
    try {
      const response = await axios.patch(`/favorites/user/${userId}`, {
        nftId,
      });
      store.dispatch(removeUserFavorite(nftId));
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // set favorites ids in redux
  static async initUserFavoritesIds(userId: string): Promise<any> {
    const ids = await this.getAllFavoritesIds(userId);
    // set favorites ids in redux
    if (ids && ids.length > 0) {
      store.dispatch(setUserFavoriteIds(ids));
    }
  }
}
