import { useTranslation } from "react-i18next";
import { BID_STATUS, iBid } from "../../../models/iBid";
import Button from "../Button/Button";
import { useNavigate } from "react-router-dom";
import openOfferModal from "../../../../_Web/components/Checkout/Offer";
import openBuyModal from "../../../../_Web/components/Checkout/Buy";

export default function TakBidActions({ offer }: { offer: iBid }) {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const nft = offer._nft;

  return (
    <div className="d-flex flex-wrap gap-1">
      <div
        dangerouslySetInnerHTML={{
          __html: `<!-- ${offer.status} - ${offer._side} | ${offer._id} -->`,
        }}
      />
      {offer.status === BID_STATUS.PENDING && offer._side === "seller" ? (
        <Button
          onClick={
            () => {
              navigate("/profile/offers/accept-offer", {
                state: offer,
              });
            }
            // openWalletPage({
            //   id: "wallet_offer_page_accept_offer" + offer._id,
            //   content: <WalletAcceptOffer mode="accept" offer={offer} />,
            // })
          }
          text={t("wallet.offers.accept")}
          size="sm"
        ></Button>
      ) : null}

      {offer.status === BID_STATUS.DECLINED && offer._side === "buyer" ? (
        <Button
          onClick={() => {
            openOfferModal(String(nft?._id));
          }}
          text={t("wallet.offers.makenewoffer")}
          size="sm"
        ></Button>
      ) : null}

      {offer.status === BID_STATUS.ACCEPTED && offer._side === "buyer" ? (
        <Button
          onClick={
            () => {
              openBuyModal(String(nft?._id));
            }
            // openWalletPage({
            //   id: "wallet_page_checkout" + offer._id,
            //   content: <WalletCheckout nft={offer._nft!} />,
            // })
          }
          text={t("wallet.offers.paynow")}
          size="sm"
        ></Button>
      ) : null}

      {offer.status === BID_STATUS.BUYER_COUNTEROFFER &&
      offer._side === "seller" ? (
        <Button
          onClick={
            () => {
              navigate("/profile/offers/counter-offer-accept/seller", {
                state: offer,
              });
            }
            // openWalletPage({
            //   id: "wallet_offer_page_accept_counteroffer" + offer._id,
            //   content: <WalletCounterofferAccept mode="accept" offer={offer} />,
            // })
          }
          text={t("wallet.offers.acceptcounter")}
          size="sm"
        ></Button>
      ) : null}

      {offer.status === BID_STATUS.SELLER_COUNTEROFFER &&
      offer._side === "buyer" ? (
        <Button
          onClick={
            () => {
              navigate("/profile/offers/counter-offer-accept/buyer", {
                state: offer,
              });
            }
            // openWalletPage({
            //   id: "wallet_offer_page_accept_counteroffer" + offer._id,
            //   content: <WalletCounterofferAccept mode="accept" offer={offer} />,
            // })
          }
          text={t("wallet.offers.acceptcounter")}
          size="sm"
        ></Button>
      ) : null}

      <div className="d-flex gap-1">
        {offer.status === BID_STATUS.SELLER_COUNTEROFFER &&
        offer._side === "buyer" ? (
          <Button
            onClick={() => {
              navigate("/profile/offers/counter-offer", {
                state: offer,
              });
            }}
            text={t("wallet.offers.makecounter")}
            variant="secondary"
            size="sm"
          ></Button>
        ) : null}

        {(offer.status === BID_STATUS.PENDING ||
          offer.status === BID_STATUS.BUYER_COUNTEROFFER) &&
        offer.acceptCounterOffer &&
        offer._side === "seller" ? (
          <Button
            onClick={
              () => {
                navigate("/profile/offers/counter-offer", {
                  state: offer,
                });
              }
              //   openWalletPage({
              //     id: "wallet_offer_page_create_counteroffer" + offer._id,
              //     content: (
              //       <WalletCounteroffer mode="counteroffer" offer={offer} />
              //     ),
              //   })
            }
            text={t("wallet.offers.makecounter")}
            variant="secondary"
            size="sm"
          ></Button>
        ) : null}

        {offer.status === BID_STATUS.PENDING && offer._side === "seller" ? (
          <Button
            onClick={
              () => {
                navigate("/profile/offers/decline-offer", {
                  state: offer,
                });
              }
              //   openWalletPage({
              //     id: "wallet_offer_page_decline_offer" + offer._id,
              //     content: <WalletDeclineOffer mode="decline" offer={offer} />,
              //   })
            }
            icon="delete"
            variant="secondary"
            size="sm"
          ></Button>
        ) : null}

        {(offer.status === BID_STATUS.BUYER_COUNTEROFFER &&
          offer._side === "seller") ||
        (offer.status === BID_STATUS.SELLER_COUNTEROFFER &&
          offer._side === "buyer") ? (
          <Button
            onClick={
              () => {
                navigate("/profile/offers/counter-offer-decline", {
                  state: offer,
                });
              }
              //   openWalletPage({
              //     id: "wallet_offer_page_decline_offer" + offer._id,
              //     content: (
              //       <WalletCounterofferDecline mode="decline" offer={offer} />
              //     ),
              //   })
            }
            icon="delete"
            variant="secondary"
            size="sm"
          ></Button>
        ) : null}
      </div>

      {(offer.status === BID_STATUS.BUYER_COUNTEROFFER &&
        offer._side === "buyer") ||
      (offer.status === BID_STATUS.SELLER_COUNTEROFFER &&
        offer._side === "seller") ? (
        <Button
          onClick={
            () => {
              navigate("/profile/offers/counter-offer-decline", {
                state: offer,
              });
            }
            // openWalletPage({
            //   id: "wallet_offer_page_decline_offer" + offer._id,
            //   content: (
            //     <WalletCounterofferDecline mode="decline" offer={offer} />
            //   ),
            // })
          }
          icon="delete"
          variant="secondary"
          size="sm"
        ></Button>
      ) : null}

      {offer.status === BID_STATUS.PENDING && offer._side === "buyer" ? (
        <Button
          onClick={
            () => {
              navigate("/profile/offers/cancel-offer", {
                state: offer,
              });
            }
            // openWalletPage({
            //   id: "wallet_offer_page_cancel_offer" + offer._id,
            //   content: <WalletCancelOffer offer={offer} mode="cancel" />,
            // })
          }
          icon="delete"
          variant="secondary"
          size="sm"
        ></Button>
      ) : null}
    </div>
  );
}
