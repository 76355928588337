import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../../../../assets/TakyonDesignSystem/components/Button/Button";
import { iNft } from "../../../../../assets/models/iNft";
import { openModal } from "../../../../../assets/Utils/modal";
import NftController from "../../../../../assets/Controllers/NftController";
import { apiErrorToast } from "../../../../../assets/Utils/errors";
import { fireTagManagerEvent } from "../../../../../assets/Services/tagmanager";
import BookingHeader from "../../../../../assets/TakyonDesignSystem/components/Wallet/sub/BookingHeader";
import _ProfileSectionLayout from "../../_ProfileSectionLayout";
import ActionFeedbackModal from "../../../Modals/ActionFeedbackModal";

export default function TakRemoveFromSale() {
  // { nft }: { nft: iNft }
  let location = useLocation();

  const navigate = useNavigate();

  const nft: iNft = location.state;
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const removeFromSale = async () => {
    setIsLoading(true);
    try {
      await NftController.removeNftFromSale({
        nftId: nft._id,
      });

      fireTagManagerEvent("remove_from_sale");

      openModal(
        {
          content: (
            <ActionFeedbackModal
              icon="check_circle"
              title={t("wallet.bookings.removesalesuccesstext")}
              continueClickCallback={() => navigate("/profile/wallet")}
              continueBtnText={t("wallet.bookings.viewtak")}
            />
          ),
        },
        true
      );
    } catch (error) {
      apiErrorToast(error);
    }
    setIsLoading(false);
  };

  return (
    <_ProfileSectionLayout nft={nft} title={t("wallet.bookings.removesale")}>
      <p className="m-0 bodytext medium">{t("wallet.bookings.removesure")}</p>

      <div className="height-16"></div>

      <Button
        loading={isLoading}
        text={t("wallet.bookings.removesale")}
        onClick={removeFromSale}
      />
    </_ProfileSectionLayout>
  );
}
