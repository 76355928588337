import axios from "axios";
import { iBuyOrder } from "../models/iOrder";
import store from "../../redux/redux";
import { setPendingOrders } from "../../redux/slices/orderSlice";
import { openModal } from "../Utils/modal";
import openBuyModal from "../../_Web/components/Checkout/Buy";

export default class OrderController {
  static async getPendingOrders(): Promise<iBuyOrder[]> {
    const response = await axios.get("/nft/buy-intent");
    return response.data;
  }

  static async deletePendingOrder(payload: { orderId: string }): Promise<any> {
    const response = await axios.delete("/nft/buy-intent", { data: payload });
    return response.data;
  }

  static async createPaymentIntent(payload: { nftId: string }) {
    const response = await axios.post("/nft/buy-intent", payload);
    return response.data;
  }

  static async initPendingOrders() {
    const orders = await this.getPendingOrders();
    store.dispatch(setPendingOrders([...orders]));

    if (orders.length) {
      orders.forEach((o) => {
        openBuyModal(o.nftId);
      });
    }
  }

  static async closeAllPendingOrders() {
    const orders = await this.getPendingOrders();
    orders.forEach((o) => {
      this.deletePendingOrder({ orderId: o._id });
    });
  }
}
