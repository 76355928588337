import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../redux";
import { ReactNode } from "react";
import { WALLET_NAV_ELEMENT } from "../../assets/TakyonDesignSystem/components/Wallet/WalletNav";
import {
  iCollectionToFake,
  iSwipeFilter,
} from "../../assets/Controllers/SwipeController";
import { iNft } from "../../assets/models/iNft";
import { SearchCriteria } from "../../_Web/components/Nav/SearchBox/SearchBoxStay";

interface appState {
  refreshSwipes: number;

  refreshSwipePage: number;

  isWalletOpen: boolean;
  isWalletFullPage: boolean;
  walletSection: WALLET_NAV_ELEMENT;
  walletPages: { id: string; page: ReactNode; onClose?: Function }[];

  modal: React.ReactNode | null;
  hideModalCloseBtn: boolean;
  onModalBack: Function | undefined;
  onModalClose: Function | undefined;

  swipeFilter: iSwipeFilter;
  isFilterOpen: boolean;

  allBookings: iNft[];
  allCollectionsToFake: iCollectionToFake[];

  resizeHook: number;

  nftInPage: string | undefined;

  nftInCheckout: string | undefined;
  nftInOffer: string | undefined;

  search: SearchCriteria;
  isHeroVisible: boolean;
  isProfileMenuOpen: boolean;

  isSearchBoxOpen: boolean;

  takWidth?: number;
}

const initialState: appState = {
  refreshSwipes: 1,
  refreshSwipePage: 1,
  walletSection: WALLET_NAV_ELEMENT.bookings,
  walletPages: [],
  isWalletFullPage: false,
  isWalletOpen: false,

  modal: null,
  hideModalCloseBtn: false,
  onModalBack: undefined,
  onModalClose: undefined,

  swipeFilter: {},
  isFilterOpen: false,

  allBookings: [],
  allCollectionsToFake: [],

  resizeHook: 0,

  nftInPage: undefined,

  nftInCheckout: undefined,
  nftInOffer: undefined,

  search: {},
  isHeroVisible: true,
  isProfileMenuOpen: false,

  isSearchBoxOpen: false,
};

export const userSlice = createSlice({
  name: "app",
  initialState: initialState,
  reducers: {
    setSearch: (state, action: PayloadAction<SearchCriteria | {}>) => {
      state.search = action.payload;
    },
    setHeroVisibility: (state, action: PayloadAction<boolean>) => {
      state.isHeroVisible = action.payload;
    },
    setNftInPage: (state, action: PayloadAction<string | undefined>) => {
      state.nftInPage = action.payload;
    },
    setResizeHook: (state, action: PayloadAction<number>) => {
      state.resizeHook = action.payload;
    },
    setRefreshSwipes: (state, action: PayloadAction<number>) => {
      state.refreshSwipes = action.payload;
    },
    setRefreshSwipePage: (state, action: PayloadAction<number>) => {
      state.refreshSwipePage = action.payload;
    },
    setIsWalletOpen: (state, action: PayloadAction<boolean>) => {
      state.isWalletOpen = action.payload;
    },
    setIsWalletFullPage: (state, action: PayloadAction<boolean>) => {
      state.isWalletFullPage = action.payload;
    },
    setIsFilterOpen: (state, action: PayloadAction<boolean>) => {
      state.isFilterOpen = action.payload;
    },
    setWalletSection: (state, action: PayloadAction<WALLET_NAV_ELEMENT>) => {
      state.walletSection = action.payload;
    },
    setWalletPages: (
      state,
      action: PayloadAction<{ id: string; page: ReactNode }[]>
    ) => {
      state.walletPages = action.payload;
    },
    setModal: (state, action: PayloadAction<ReactNode>) => {
      state.modal = action.payload;
    },
    setHideModalCloseBtn: (state, action: PayloadAction<boolean>) => {
      state.hideModalCloseBtn = action.payload;
    },
    setOnModalBack: (state, action: PayloadAction<Function | undefined>) => {
      state.onModalBack = action.payload;
    },
    setOnModalClose: (state, action: PayloadAction<Function | undefined>) => {
      state.onModalClose = action.payload;
    },
    setSwipeFilter: (state, action: PayloadAction<iSwipeFilter>) => {
      state.swipeFilter = action.payload;
    },

    setAllBookings: (state, action: PayloadAction<iNft[]>) => {
      state.allBookings = action.payload;
    },
    setAllCollectionsToFake: (
      state,
      action: PayloadAction<iCollectionToFake[]>
    ) => {
      state.allCollectionsToFake = action.payload;
    },
    setNftInCheckout: (state, action: PayloadAction<string | undefined>) => {
      state.nftInCheckout = action.payload;
    },
    setNftInOffer: (state, action: PayloadAction<string | undefined>) => {
      state.nftInOffer = action.payload;
    },
    setProfileMenu: (state, action: PayloadAction<boolean>) => {
      state.isProfileMenuOpen = action.payload;
    },
    setIsSearchBoxOpen: (state, action: PayloadAction<boolean>) => {
      state.isSearchBoxOpen = action.payload;
    },
    setTakWidth: (state, action: PayloadAction<number>) => {
      state.takWidth = action.payload;
    },
  },
});

export const {
  setRefreshSwipes,
  setRefreshSwipePage,
  setWalletPages,
  setIsWalletOpen,

  setModal,
  setHideModalCloseBtn,
  setOnModalBack,
  setOnModalClose,

  setWalletSection,
  setSwipeFilter,
  setIsFilterOpen,
  setIsWalletFullPage,

  setAllBookings,
  setAllCollectionsToFake,

  setResizeHook,

  setNftInPage,

  setNftInCheckout,
  setNftInOffer,
  setSearch,
  setHeroVisibility,
  setProfileMenu,

  setIsSearchBoxOpen,

  setTakWidth,
} = userSlice.actions;

export const selectSearch = (state: RootState) => state.app.search;
export const selectTakWidth = (state: RootState) => state.app.takWidth;
export const selectIsSearchBoxOpen = (state: RootState) =>
  state.app.isSearchBoxOpen;
export const selectRefreshSwipes = (state: RootState) =>
  state.app.refreshSwipes;
export const selectRefreshSwipePage = (state: RootState) =>
  state.app.refreshSwipePage;
export const selectWalletPages = (state: RootState) => state.app.walletPages;
export const selectIsWalletOpen = (state: RootState) => state.app.isWalletOpen;
export const setlectIsWalletFullPage = (state: RootState) =>
  state.app.isWalletFullPage;
export const selectIsFilterOpen = (state: RootState) => state.app.isFilterOpen;

export const selectModal = (state: RootState) => state.app.modal;
export const selectOnModalBack = (state: RootState) => state.app.onModalBack;
export const selectOnModalClose = (state: RootState) => state.app.onModalClose;
export const selectHideModalCloseBtn = (state: RootState) =>
  state.app.hideModalCloseBtn;

export const selectWalletSection = (state: RootState) =>
  state.app.walletSection;
export const selectSwipeFilter = (state: RootState) => state.app.swipeFilter;

export const selectAllBookings = (state: RootState) => state.app.allBookings;
export const selectAllCollectionsToFake = (state: RootState) =>
  state.app.allCollectionsToFake;

export const selectResizeHook = (state: RootState) => state.app.resizeHook;
export const selectNftInPage = (state: RootState) => state.app.nftInPage;

export const selectNftInCheckout = (state: RootState) =>
  state.app.nftInCheckout;
export const selectNftInOffer = (state: RootState) => state.app.nftInOffer;
export const selectHeroVisibility = (state: RootState) =>
  state.app.isHeroVisible;
export const selectProfileMenuVisibility = (state: RootState) =>
  state.app.isProfileMenuOpen;

export default userSlice.reducer;
