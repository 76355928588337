import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { GoogleOAuthProvider } from "@react-oauth/google";
import store, { useAppDispatch } from "./redux/redux";
import { Route, Routes } from "react-router-dom";
import App from "./_App/App";
import Web from "./_Web/Web";
import "./assets/scss/style.scss";
import UserController from "./assets/Controllers/UserController";
import { initAxios } from "./assets/Services/axios";
import { initi18next } from "./assets/Services/i18next";
import OrderController from "./assets/Controllers/OrderController";
import { ToastContainer } from "react-toastify";
import { apiErrorToast } from "./assets/Utils/errors";
import { initTagManager } from "./assets/Services/tagmanager";
import Modal from "./_Shared/components/Modal/Modal";
import CookiesBanner, {
  initCookies,
} from "./_Shared/components/CookiesBanner/CookiesBanner";
import { Redirect } from "./assets/Utils/app";
import getLink, { LINK_TYPE } from "./config/Links";
import { useEffect, useRef } from "react";
import { debounce } from "lodash";
import { setResizeHook } from "./redux/slices/appSlice";
import Test from "./_App/pages/Test";
import openAuthModal from "./_Web/components/Auth/Auth";
import OfferController from "./assets/Controllers/OfferController";
import FavoritesController from "./assets/Controllers/FavoritesController";
import { SearchModal } from "./_Web/components/Nav/SearchBox/SearchBox";
import TakPage from "./_Web/pages/TakPage/TakPage";
import BuilderIo from "./builder.io";

// USING https://react-bootstrap.netlify.app/

export const IS_PRODUCTION = process.env.REACT_APP_ENV === "production";

if (IS_PRODUCTION) initTagManager();

(async () => {
  UserController.setBearerToken();

  await initCookies();
  await initAxios();
  await initi18next();

  try {
    const user = await UserController.initLoggedUser();
    if (user) await OrderController.initPendingOrders();
    if (user) await OfferController.initPendingOffers();
    if (user) await FavoritesController.initUserFavoritesIds(user._id);
  } catch (error) {
    apiErrorToast(error);
  }

  const params = new URL(window.location.toString()).searchParams;
  const token = params.get("resetPasswordToken");
  if (token) openAuthModal();
})();

function HandleResizeDbounced() {
  const dispatch = useAppDispatch();

  const handle = () => {
    dispatch(setResizeHook(new Date().getTime()));
  };

  const handleCallback = useRef(debounce(handle, 150));

  useEffect(() => {
    window.addEventListener("resize", () => handleCallback.current());
    window.addEventListener("load", () => handle());

    setTimeout(() => handle(), 1000);
    setTimeout(() => handle(), 3000);
  }, []);

  return <></>;
}

function IndexRouter() {
  return (
    <Routes>
      <Route path="test" element={<Test />} />
      <Route path="app" element={<Redirect to="/" />} />
      <Route path="/tak/:id" element={<TakPage />} />
      <Route path="auth" element={<Web />} />
      <Route path="/b/*" element={<BuilderIo />} />
      <Route path="*" element={<Web />} />
    </Routes>
  );
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <GoogleOAuthProvider
        clientId={String(process.env.REACT_APP_GOOGLE_CLIENT_ID)}
      >
        <IndexRouter />
        <ToastContainer />
        <Modal />
        <SearchModal />
        <CookiesBanner />
        <HandleResizeDbounced />
      </GoogleOAuthProvider>
    </Provider>
  </BrowserRouter>
);
