import nexi from "../../../../assets/img/nexi/nexi.png";
import takyon from "../../../../assets/img/icons/TakyonLight.svg";
import nexiLogo from "../../../../assets/img/nexi/nexi-logo.png";
import Button from "../../../../assets/TakyonDesignSystem/components/Button/Button";

interface NexiHeadProps {
  text: string;
  btntext: string;
  buttonRef?: any;
}

export default function NexiHead({ text, btntext, buttonRef }: NexiHeadProps) {
  const scroolToRef = () => {
    buttonRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div
      style={{
        background: `url(${nexi})`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
      }}
      className="d-flex flex-column justify-content-center align-items-center vh-100 vw-100 p-3"
    >
      <div className="d-flex align-items-center gap-3">
        <img className="height-40" src={takyon} alt="" />
        <div
          className="vertical-divider black-color"
          style={{ height: "30px", margin: "0 12px" }}
        ></div>
        <img src={nexiLogo} className="height-30" alt="" />
      </div>

      <div className="height-60"></div>

      <div className="w100 text-center">
        <span className="h1-large white-color regular">{text}</span>
      </div>

      <div className="height-60"></div>

      <div>
        <Button text={btntext} onClick={() => scroolToRef()} />
      </div>
    </div>
  );
}
