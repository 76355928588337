import { iNft, payloadExperienceType } from "../models/iNft";
import {
  calculateCommonWordPercentage,
  calculateAmountOfCommonElementInArray,
} from "./generic";

export function getTranslationExperience(nft: iNft, i18n: any) {
  if (!nft || !nft.payload?.category) return;

  const payload = nft.payload as payloadExperienceType;
  const myCat = payload.category;

  if (!myCat) return;

  try {
    const availLangs = Object.keys(nft._collectionCensus?.info!);

    let bestIndex = -1;
    let bestPercentage = 0;
    let bestAmount = 0;

    for (const lang of availLangs) {
      const collectionInfoLang =
        (nft._collectionCensus?.info as any)[lang]?.experiences || [];

      for (let expIndex = 0; expIndex < collectionInfoLang.length; expIndex++) {
        const expInfo = collectionInfoLang[expIndex];

        const percentage = calculateCommonWordPercentage(expInfo.name, myCat);

        const amount = calculateAmountOfCommonElementInArray(
          expInfo.name.split(" "),
          myCat!.split(" ")
        );

        if (amount > bestAmount) {
          bestAmount = amount;
          bestIndex = expIndex;
        } else if (amount === bestAmount && percentage > bestPercentage) {
          bestPercentage = percentage;
          bestIndex = expIndex;
        }
      }
    }

    let exp = (nft._collectionCensus?.info as any)[i18n.language]
      ?.experiences?.[bestIndex];

    if (!exp) return;
    return exp;
  } catch (error) {
    console.log("Error getting exp description", error);
  }
}
