import PanelButton from "../../../../assets/TakyonDesignSystem/components/PanelButton/PanelButton";
import { useTranslation } from "react-i18next";
import hotelstays from "../../../../assets/img/wall/searchbox/stays.png";
import traveladventures from "../../../../assets/img/wall/searchbox/adventures.png";
import experiences from "../../../../assets/img/wall/searchbox/experiences.png";
import Button from "../../../../assets/TakyonDesignSystem/components/Button/Button";
import { closeModal } from "../../../../assets/Utils/modal";
import { closeSearchModal } from "./SearchBox";
import { fireTagManagerEvent } from "../../../../assets/Services/tagmanager";

export enum SEARCH_TAK_TYPE {
  STAYS = "STAYS",
  EXPERIENCES = "EXPERIENCES",
  TRAVELADVENTURES = "TRAVEL_ADVENTURES",
}

interface iSearchBoxStep1Props {
  onClick?: (value: SEARCH_TAK_TYPE) => void;
}

export default function SearchBoxStep1(props: iSearchBoxStep1Props) {
  const { t } = useTranslation();

  return (
    <div className="h-100 d-flex flex-column justify-content-between">
      <div className="h-100">
        <div className="d-flex justify-content-center p-2 border-bottom">
          <p className="bodytext medium m-0">{t("wall.searchbox.search")}</p>
        </div>
        <div
          className="py-4 modal-padding d-flex flex-column justify-content-center gap-3"
          style={{ height: "calc(100% - 33px)" }}
        >
          <PanelButton
            background={hotelstays}
            icon="search"
            text={t("wall.searchbox.hotelstays")}
            onClick={() => {
              if (props.onClick) {
                props.onClick(SEARCH_TAK_TYPE.STAYS);
              }
            }}
          />
          <PanelButton
            background={experiences}
            icon="search"
            text={t("wall.searchbox.experiences")}
            onClick={() => {
              if (props.onClick) {
                fireTagManagerEvent("search", { type: "experience" });
                props.onClick(SEARCH_TAK_TYPE.EXPERIENCES);
              }
            }}
          />
          {/* <PanelButton
          background={traveladventures}
          icon="search"
          text={t("wall.searchbox.traveladventures")}
          onClick={() => {
            if (props.onClick) {
              props.onClick(SEARCH_TAK_TYPE.TRAVELADVENTURES);
            }
          }}
        /> */}
        </div>
      </div>
      {/* <div className="p-2 px-4 border-top">
        <Button
          className="bodytext medium m-0 btn-text"
          text={t("wall.searchbox.cancel") + "sss"}
          variant="blank"
          circle
          onClick={() => {
            closeSearchModal();
          }}
        />
      </div> */}
    </div>
  );
}
