import axios from "axios";
import iPrivacy from "../models/iPrivacy";

export default class PrivacyController {
  static async sendConsent(payload: {
    email?: string;
    target?: string;
    consent: boolean;
  }): Promise<iPrivacy> {
    const response = await axios.post("/consent", {
      email: payload.email,
      target: payload.target,
      consent: payload.consent,
    });
    return response.data;
  }
}
