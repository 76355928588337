import axios from "axios";
import iReferralCode from "../models/iReferral";

export default class ReferralController {
  static async createReferralCode() {
    const response = await axios.post("referral");
    return response.data;
  }

  static async getReferralCode(): Promise<iReferralCode> {
    const response = await axios.get("referral");
    return response.data;
  }
}
