import { useTranslation } from "react-i18next";
import FavoritesLists from "../Favorites/FavoritesLists";
import { useSelector } from "react-redux";
import { selectUser } from "../../../redux/slices/userSlice";
import { useNavigate } from "react-router-dom";
import FavoritesAll from "./Favorites/FavoritesAll";
import TabLayout from "../Layouts/TabLayout";

export default function ProfileFavorites() {
  const { t } = useTranslation();
  const user = useSelector(selectUser);

  const navigate = useNavigate();

  if (!user) return <>Loading...</>;

  const tabs = [
    {
      key: "categories",
      label: t("favorites.categories.tab_title"),
      component: (
        <FavoritesLists
          userId={user!._id}
          onItemClick={(listId: any) => {
            navigate(`/profile/favorites/${listId}`);
          }}
        />
      ),
    },
    {
      key: "all_favorites",
      label: t("favorites.all_favorites.tab_title"),
      component: <FavoritesAll userId={user!._id} />,
    },
  ];

  return (
    <>
      <TabLayout
        title={t("favorites.title")}
        tabs={tabs}
        defaultTabKey="categories" // Imposta la scheda di default
      />
    </>
  );
}
