import React, {
  ReactNode,
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useAnimation, motion } from "framer-motion";
import { useMediaQuery } from "react-responsive";
import { SMALL_SCREEN } from "../../../Utils/generic";
import Button from "../Button/Button";

export interface iMultiSelectItem {
  content: ReactNode;
  value: string;
  selected?: boolean;
}

const transition = {
  type: "spring",
  duration: 0.5,
};

const MultiSelect = forwardRef(
  (
    {
      items,
      onChange,
    }: {
      items: iMultiSelectItem[];
      onChange: (updatedItems: iMultiSelectItem[]) => void;
    },
    ref
  ) => {
    const isSmallScreen = useMediaQuery({ query: SMALL_SCREEN });
    const [showArrows, setShowArrows] = useState(true);
    const [showLeftArrow, setShowLeftArrow] = useState(false);
    const [showRightArrow, setShowRightArrow] = useState(true);

    const [selectedItems, setSelectedItems] = useState(
      items.map((item) => ({ ...item, selected: item.selected || false }))
    );

    const animation = useAnimation();

    const handleClick = (index: number) => {
      const updatedItems = selectedItems.map((item, i) =>
        i === index ? { ...item, selected: !item.selected } : item
      );
      setSelectedItems(updatedItems);
      onChange(updatedItems);
    };

    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      const container = containerRef.current;
      if (!container) return;

      const handleScroll = () => {
        setShowLeftArrow(container.scrollLeft > 0);
        setShowRightArrow(
          container.scrollLeft + container.offsetWidth < container.scrollWidth
        );
      };

      handleScroll(); // Check on mount

      container.addEventListener("scroll", handleScroll);
      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }, [selectedItems]);

    useImperativeHandle(ref, () => ({
      resetAll() {
        const updatedItems = selectedItems.map((item) => ({
          ...item,
          selected: false,
        }));
        setSelectedItems(updatedItems);
        onChange(updatedItems);
      },
      setSelectedValues(selectedValues: string[]) {
        const updatedItems = selectedItems.map((item) => ({
          ...item,
          selected: selectedValues.includes(item.value),
        }));
        setSelectedItems(updatedItems);
        onChange(updatedItems);
      },
    }));

    return (
      <div className="w100 d-flex align-items-center gap-1">
        {showArrows && showLeftArrow && (
          <Button
            style={{ opacity: showLeftArrow ? 1 : 0 }}
            onClick={() => {
              containerRef.current?.scrollBy({
                left: -200,
                behavior: "smooth",
              });
            }}
            icon="arrow_left_alt"
            variant="blank"
            size="sm"
            className="dark-gray-color only-desktop"
          />
        )}
        <div style={{ width: "calc(100% - 10px)", overflowX: "scroll" }}>
          <div ref={containerRef} className="multi-select-container">
            {selectedItems.map((item, index) => (
              <div
                key={index}
                className={`multi-select-item ${
                  item.selected ? "selected" : ""
                }`}
                onClick={() => handleClick(index)}
              >
                {item.content}
              </div>
            ))}
          </div>
        </div>
        {showArrows && showRightArrow && (
          <Button
            style={{ opacity: showRightArrow ? 1 : 0 }}
            size="sm"
            icon="arrow_right_alt"
            variant="blank"
            className="dark-gray-color only-desktop"
            onClick={() => {
              containerRef.current?.scrollBy({ left: 200, behavior: "smooth" });
            }}
          />
        )}
      </div>
    );
  }
);

export default MultiSelect;
