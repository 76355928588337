import { useMediaQuery } from "react-responsive";

import Button from "../../../../assets/TakyonDesignSystem/components/Button/Button";
import { SMALL_SCREEN } from "../../../../assets/Utils/generic";

interface Props {
  title?: string;
  title2?: string;
  title2class?: string;
  subtitle?: string;
  text?: string;
  dark_bg?: boolean;
  main?: boolean;
  small?: boolean;
  btnText?: string;
  onClick?: Function;
}
export default function Title(props: Props) {
  const isSmallScreen = useMediaQuery({ query: SMALL_SCREEN });

  return (
    <div
      className={`d-flex flex-column ${props.dark_bg ? "white-color" : ""} ${
        props.main
          ? "align-items-md-start align-items-center"
          : "align-items-center"
      }`}
    >
      <span
        className={`h1-large regular ${
          props.main && !isSmallScreen ? "text-start" : ""
        }`}
      >
        {props.title} <i className={props.title2class}>{props.title2}</i>
      </span>

      <div className="height-40"></div>

      <div className="d-flex flex-column">
        {props.subtitle ? (
          <div className={`${props.main ? "primary-color" : ""}`}>
            <span className="h3 regular">{props.subtitle}</span>
          </div>
        ) : null}
        <div className="dark-grey-color">
          <span className="h3 light">{props.text}</span>
        </div>
      </div>

      {props.btnText ? (
        <div>
          <div className="height-40"></div>
          <Button text={props.btnText} onClick={() => props.onClick?.()} />
        </div>
      ) : null}
    </div>
  );
}
