import { iNft, NFT_SELL_STATUS } from "../../assets/models/iNft";
import iUser from "../../assets/models/iUser";

const helpers = {
  price: function (
    number: string | number,
    currency: string = "€",
    toFixed: number = 0
  ): string {
    let numberFloat = typeof number === "string" ? parseFloat(number) : number;
    if (!numberFloat) return "€ 0";

    return `${currency} ${numberFloat.toFixed(toFixed)}`;
  },
  shortName: function (user: iUser) {
    let shortName = "";
    if (user.firstName) {
      shortName = user.firstName;
    }
    if (user.secondName) {
      shortName += ` ${user.secondName[0]}.`;
    }

    return shortName;
  },
  takPeriodDates: function (startDate: string, endDate: string) {
    const from = new Date(startDate ?? "")
      .toLocaleString("it-IT", {
        weekday: "short",
        day: "2-digit",
      })
      .replace(" a.m.", "")
      .replace(" p.m.", "");

    const to = new Date(endDate ?? "")
      .toLocaleString("it-IT", {
        weekday: "short",
        day: "2-digit",
        month: "short",
      })
      .replace(" a.m.", "")
      .replace(" p.m.", "");

    return `${from} - ${to}`;
  },
  feeToString: function (fee: number) {
    return `${Math.round(fee * 100)}%`;
  },
  getXDaysAgo: function (days: number) {
    const date = new Date();
    date.setDate(date.getDate() - days);
    return date;
  },
  getOldPrice: function (nft: iNft) {
    let returnValue = Number(
      nft.marketPrice
        ? nft.marketPrice.toFixed(2)
        : nft.originalPrice?.toFixed(2)
    );

    // For gondolas not show the old price
    // Adding 2 to the original price makes the old price not visible: logic oldPrice > currentPrice = not visible
    if (nft._collectionPriceLockedToOriginal) {
      return undefined;
    } else {
      return returnValue;
    }
  },
  getCurrentPrice: function (nft: iNft) {
    let returnValue = Number(
      nft.currentPrice
        ? nft.currentPrice.toFixed(2)
        : nft.originalPrice?.toFixed(2)
    );

    // For gondolas show always the original price
    if (nft._collectionPriceLockedToOriginal) {
      returnValue = Number(nft.originalPrice);
    }

    return returnValue;
  },
};

export default helpers;
