import React from "react";
import Button from "../../../assets/TakyonDesignSystem/components/Button/Button";
import { useTranslation } from "react-i18next";
import { useProfileNavigation } from "../../hooks/hooks";
import BookingHeader from "../../../assets/TakyonDesignSystem/components/Wallet/sub/BookingHeader";
import { iNft } from "../../../assets/models/iNft";

interface CommonLayoutProps {
  title: string;
  nft: iNft;
  hideBackButton?: boolean;
  children?: React.ReactNode; // Aggiunta della prop children per accettare elementi figli
  nomargin?: boolean;
}

const _ProfileSectionLayout: React.FC<CommonLayoutProps> = ({
  title,
  nft,
  hideBackButton = false,
  children,
  nomargin,
}) => {
  const { t } = useTranslation();
  const { goBack } = useProfileNavigation();
  // const nft = offer._nft!;

  return (
    <div className="p-4 h100 d-flex flex-column">
      {/* Layout comune */}
      <div>
        {!hideBackButton && (
          <div>
            <Button
              variant="blank"
              className="dark-grey-color"
              icon="arrow_back"
              onClick={goBack} // Assicurati che `goBack` sia gestito correttamente
              text={t("wallet.generic.goback")}
            />
          </div>
        )}
        <div className="height-8"></div>

        <h3 className="h3 medium">{title}</h3>
      </div>

      {/* Fine del layout comune */}

      {/* Renderizzazione dei children */}
      <div
        style={{
          overflowY: "scroll",
          paddingBottom: nomargin ? "" : "100px",
          paddingRight: "10px",
          paddingLeft: "10px",
        }}
      >
        <div className="height-24"></div>
        <BookingHeader nft={nft} />
        <div className="height-24"></div>
        {children}
      </div>
    </div>
  );
};

export default _ProfileSectionLayout;
