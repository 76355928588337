import { useTranslation } from "react-i18next";
import {
  datetimeRangeToString,
  generateRandomName,
  getAmountOfNights,
} from "../../../Utils/generic";
import {
  getAmountOfGuests,
  getCityFromLocation,
  getCountryFromLocation,
  getRegionFromLocation,
} from "../../../Utils/location";
import { NFT_SELL_STATUS, iNft } from "../../../models/iNft";
import Icon from "../Icon/Icon";
import Stars from "../Stars/Stars";
import Button from "../Button/Button";
import { useEffect, useState } from "react";

export interface NftCardProps {
  nft: iNft;
  maxWidth?: string;
  maxHeight?: string;

  showOwner?: boolean;
  hideDataOpacity?: boolean;

  liked?: boolean;

  onOffer?(nft: iNft): void;
  onBuy?(nft: iNft): void;
  onLike?(nft: iNft): void;
  onShare?(nft: iNft): void;
  onPartner?(nft: iNft): void;

  onClick?(nft: iNft): void;

  partnerMode?: boolean;

  style?: Object;
}

export default function NftCard2({ nft, ...props }: NftCardProps) {
  const { t } = useTranslation();
  const [name, setName] = useState<string>();

  const discountPercent = Math.ceil(
    (1 - nft.currentPrice! / (nft.marketPrice ?? nft.originalPrice)!) * 100
  );

  useEffect(() => {
    if (!nft._ownerName || nft._ownerName.toLowerCase().includes("takyon")) {
      setName(generateRandomName());
    } else {
      setName(nft._ownerName);
    }
  }, []);

  return (
    <div
      style={{
        maxWidth: props.maxWidth ?? "unset",
        maxHeight: props.maxHeight ?? "unset",
        ...props.style,
      }}
      className="nft-card-2"
      onClick={() => props.onClick?.(nft)}
    >
      <div
        style={{
          background: `url(${
            (nft.images?.length ? nft.images : nft._collectionImages)?.[0]
          })`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
        }}
        className="nft-card-content"
      >
        <div className="nft-black-bg"></div>
        {!props.partnerMode ? (
          <div
            style={{ opacity: props.hideDataOpacity ? 0 : 1 }}
            className="nft-card-user-actions"
            onClick={(e) => e.stopPropagation()}
          >
            <Button
              className="shadow"
              variant="light"
              icon="ios_share"
              onClick={() => props.onShare?.(nft)}
            />
            <Button
              className="shadow"
              variant="light"
              icon="favorite"
              onClick={() => props.onLike?.(nft)}
              iconFill={props.liked}
              iconClass={`${props.liked ? "red-color" : ""}`}
            />
          </div>
        ) : null}

        {/* sold by */}

        {props.showOwner && !props.partnerMode ? (
          <div
            style={{ opacity: props.hideDataOpacity ? 0 : 1 }}
            className="nft-card-soldby bodytext-sm regular"
          >
            <Icon size={20} icon="verified" style={{ marginRight: "7px" }} />{" "}
            {t("app.nft.soldby")} {name}
          </div>
        ) : null}

        <div style={{ height: "100%" }}></div>

        {/* chip tag on top */}
        <div
          style={{ opacity: props.hideDataOpacity ? 0 : 1 }}
          className="nft-card-chip bodytext-sm regular"
        >
          {datetimeRangeToString(
            nft.payload?.checkin,
            nft.payload?.checkout,
            nft._collectionCensus?.location?.gmt
          )}
        </div>
        <div className="height-8"></div>
        <div
          style={{ opacity: props.hideDataOpacity ? 0 : 1 }}
          className="nft-card-chip bodytext-sm regular"
        >
          {getAmountOfGuests(nft).adults}
          <Icon type="symbol" fill size={22} icon="person" />
          <div className="width-8"></div>
          {getAmountOfNights(nft.payload?.checkin, nft.payload?.checkout)}
          <Icon
            type="symbol"
            fill
            size={22}
            icon="brightness_3"
            style={{ transform: "rotate(180deg)" }}
          />
        </div>
        <div className="height-20"></div>
        <div
          style={{ opacity: props.hideDataOpacity ? 0 : 1 }}
          className="nft-card-info"
        >
          {/* location info  */}
          <div className="d-flex w100">
            <p className="m-0 mb-1 w100 d-flex flex-wrap align-items-end">
              <span
                style={{
                  display: "block",
                }}
                className="h1 medium text-ellipsis"
              >
                {getCityFromLocation(nft._collectionCensus?.location)}
              </span>{" "}
              <span className="bodytext-sm regular m-1">
                ({getRegionFromLocation(nft._collectionCensus?.location)},{" "}
                {getCountryFromLocation(nft._collectionCensus?.location)})
              </span>
            </p>
          </div>

          {/* hotel name & stars */}
          <span className="bodytext regular">
            {nft._collectionCensus?.name}
          </span>
          {nft._collectionCensus?.stars ? (
            <Stars stars={nft._collectionCensus?.stars} />
          ) : null}

          <div className="height-20"></div>

          {/* prices */}
          {nft.sellStatus === NFT_SELL_STATUS.FOR_SALE &&
          (nft.marketPrice || nft.originalPrice) &&
          nft.currentPrice! < (nft.marketPrice ?? nft.originalPrice)! ? (
            <div className="bodytext regular d-flex gap-2">
              <span style={{ textDecoration: "line-through" }}>
                €{(nft.originalPrice ?? nft.marketPrice)?.toFixed(2)}
              </span>
              <span>
                (-{discountPercent}% {t("app.nft.vsothers")})
              </span>
            </div>
          ) : null}

          <span className="h1-large medium">
            €{Number(nft.currentPrice ?? nft.originalPrice).toFixed(0)}
          </span>
        </div>
        <div className="height-20"></div>
        <div
          style={{ opacity: props.hideDataOpacity ? 0 : 1 }}
          className="nft-card-actions"
          onClick={(e) => e.stopPropagation()}
        >
          {props.partnerMode ? (
            <Button
              className="w100"
              icon="arrow_outward"
              text={t("app.nft.bookonhotels")}
              onClick={() => props.onPartner?.(nft)}
            />
          ) : null}

          {!props.partnerMode && nft.sellStatus === NFT_SELL_STATUS.FOR_SALE ? (
            <>
              <Button
                text={t("app.nft.buy")}
                variant="secondary"
                className="w100"
                onClick={() => props.onBuy?.(nft)}
              />
              <Button
                text={t("app.nft.offer")}
                variant="primary"
                className="w100"
                onClick={() => props.onOffer?.(nft)}
              />
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
}
