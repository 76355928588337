import { useTranslation } from "react-i18next";
import ForHotels from "./ForHotels";

import cobranding from "../../../assets/img/forhotels/bwcobranding.svg";

export default function BHWForClients() {
  const { t } = useTranslation();

  return (
    <ForHotels
      title1={`${t("bwhpromo.1")} ${t("bwhpromo.2")} ${t("bwhpromo.3")} ${t(
        "bwhpromo.4"
      )}`}
      //   title1={t("bwhpromo.1")}
      title2={" "}
      title3={" "}
      title4={" "}
      titleClass="h1"
      cobrandingImg={cobranding}
    />
  );
}
