import { useTranslation } from "react-i18next";
import { closeWalletPages } from "../../../Utils/wallet";
import Button from "../Button/Button";

export enum WALLET_NAV_ELEMENT {
  bookings = "bookings",
  offers = "offers",
  balance = "balance",
  credits = "credits",
}

export interface Props {
  activeMenu: WALLET_NAV_ELEMENT;
  onChange(mode: WALLET_NAV_ELEMENT): void;
}

export default function WalletNav({ onChange, activeMenu }: Props) {
  const { t } = useTranslation();
  return (
    <div className="d-flex p-4 px-4 gap-2">
      <Button
        responsive
        selected={activeMenu === WALLET_NAV_ELEMENT.bookings}
        variant="blank-primary"
        onClick={() => {
          onChange(WALLET_NAV_ELEMENT.bookings);
          closeWalletPages();
        }}
        text={t("wallet.bookingsmenu")}
        chip
      ></Button>
      <Button
        responsive
        selected={activeMenu === WALLET_NAV_ELEMENT.offers}
        variant="blank-primary"
        onClick={() => {
          onChange(WALLET_NAV_ELEMENT.offers);
          closeWalletPages();
        }}
        text={t("wallet.offersmenu")}
        chip
      ></Button>
      <Button
        responsive
        selected={activeMenu === WALLET_NAV_ELEMENT.balance}
        variant="blank-primary"
        onClick={() => {
          onChange(WALLET_NAV_ELEMENT.balance);
          closeWalletPages();
        }}
        text={t("wallet.earningsmenu")}
        chip
      ></Button>
      <Button
        responsive
        selected={activeMenu === WALLET_NAV_ELEMENT.credits}
        variant="blank-primary"
        onClick={() => {
          onChange(WALLET_NAV_ELEMENT.credits);
          closeWalletPages();
        }}
        text={t("wallet.creditsmenu")}
        chip
      ></Button>
    </div>
  );
}
