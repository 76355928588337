import axios from "axios";
import {
  iConfigureStripeAccount,
  iStripeAccountData,
  iStripeBankAccount,
} from "../models/iStripe";

export default class StripeController {
  static async getStripeAccountData(): Promise<iStripeAccountData> {
    const response = await axios.get("/stripe/");
    return response.data;
  }

  static async configureStripeAccount(payload: {
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    country: string;
    city: string;
    line1: string;
    postal_code: string;
    dob_day: number;
    dob_month: number;
    dob_year: number;
    account_number: string;
    currency: string;
    state?: string;
    routing_number?: string;
    ssn_last_4?: string;
    id_number?: string;
  }): Promise<iConfigureStripeAccount> {
    const response = await axios.post(
      "/stripe/configure-stripe-account",
      payload
    );
    return response.data;
  }

  static async updateStripeAccountForVerification(payload: {
    id_number?: string;
    document?: {
      front?: string;
      back?: string;
    };
    additional_document?: {
      front?: string;
      back?: string;
    };
  }): Promise<any> {
    const response = await axios.post(
      "/stripe/update-stripe-account-for-verification",
      payload
    );
    return response.data;
  }

  static async createBankAccount(payload: {
    first_name: string;
    last_name: string;
    account_number: string;
    currency: string;
    country: string;
    routing_number?: string;
  }): Promise<iStripeBankAccount> {
    const response = await axios.post("/stripe/create-bank-account", payload);
    return response.data;
  }

  static async deleteBankAccount(payload: { bankAccountId: string }) {
    const response = await axios.delete("/stripe/delete-bank-account", {
      data: payload,
    });
    return response.data;
  }

  static async setDefaultForCurrency(payload: {
    bankAccountId: string;
  }): Promise<boolean> {
    const response = await axios.post(
      "/stripe/set-default-for-currency",
      payload
    );
    return response.data;
  }

  static async uploadImageToStripe(payload: {
    file: {
      data: string;
      name: string;
    };
  }): Promise<any> {
    const response = await axios.post("/stripe/upload-file-to-stripe", payload);
    return response.data;
  }
}
