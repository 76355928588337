import {
  AccordionProps,
  Accordion as ReactBoostrapAccordion,
} from "react-bootstrap";

interface Props extends AccordionProps {
  header: string;
  body?: any;
  index?: number;
  wallet?: boolean;
}

// TODO - Add wallet PROPERTY TO WALLET VIEW

export default function Accordion(props: Props) {
  return (
    <ReactBoostrapAccordion>
      <div
        className={`d-flex align-items-start  border-bottom ${
          props.wallet ? "light-grey-background" : ""
        }`}
      >
        <div className=" mt-3" style={{ width: "5%" }}>
          0{props.index}
        </div>
        <ReactBoostrapAccordion.Item eventKey="0">
          <ReactBoostrapAccordion.Header
            className={`${props.wallet ? "wallet" : ""}`}
          >
            {props.header}
          </ReactBoostrapAccordion.Header>

          <ReactBoostrapAccordion.Body
            className={`${props.wallet ? "wallet" : ""}`}
          >
            {props.body}
          </ReactBoostrapAccordion.Body>
        </ReactBoostrapAccordion.Item>
      </div>
    </ReactBoostrapAccordion>
  );
}
