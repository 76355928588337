import axios from "axios";
import { hotelCensus, iNft } from "../models/iNft";
import iSwipe, { SWIPE_OUTCOME } from "../models/iSwipe";
import { iPagination, iPaginationProps } from "../models/iPagination";

export interface iSwipeFilter {
  geustsMax?: number;
  geustsMin?: number;
  weekendOnly?: boolean;
  timeframe?: number;
  cheapestFirst?: boolean;
  category?: iSwipeCategory;
}

export interface iCollectionToFake {
  _id: string;
  name: string;
  census: hotelCensus;
  averagePrice: number;
  images: string[];
}

export type iSwipeCategory =
  | "top"
  | "lastminute"
  | "rome"
  | "venice"
  | "florence"
  | "milan"
  | "othercities"
  | "sea"
  | "mountain"
  | "city"
  | "lake"
  | "wellness";

export default class SwipeController {
  static async getNftsToSwipe2(
    payload: {
      filter: iSwipeFilter;
      firstNftId?: string;
      includePartners?: boolean;
      showMatch?: boolean;
    },
    index: number
  ): Promise<iNft> {
    const response = await axios.post(`/swipe/${index}`, payload);

    if (response.data === "finish") throw new Error("finish");

    const nft = response.data;

    try {
      nft.images = nft.images?.map((img: string) =>
        img.replace("upload/", "upload/f_auto/")
      );
      if (nft._collectionCensus?.images) {
        nft._collectionCensus.images = nft._collectionCensus?.images?.map(
          (img: string) => img.replace("upload/", "upload/f_auto/")
        );
      }
    } catch (error) {}

    return nft;
  }

  static async getNftsToSwipe(): Promise<iNft[]> {
    const response = await axios.get("/swipe");

    const nfts = (response.data as iNft[]).map((nft) => {
      nft.images = nft.images?.map((img) =>
        img.replace("upload/", "upload/f_auto/")
      );

      return nft;
    });

    return nfts;
  }

  static async getCollectionsToFake(): Promise<iCollectionToFake[]> {
    try {
      const response = await axios.get("/swipe/fake");

      const collections = (response.data as iCollectionToFake[]).map((coll) => {
        coll.images = coll.images?.map((img) =>
          img.replace("upload/", "upload/f_auto/")
        );

        return coll;
      });

      return collections;
    } catch (error) {
      return [];
    }
  }

  static async swipe(payload: {
    nftId: string;
    outcome: SWIPE_OUTCOME;
  }): Promise<iSwipe> {
    const response = await axios.post("/swipe", payload);
    return response.data;
  }

  static async getPaginated(
    params: iPaginationProps,
    outcome: SWIPE_OUTCOME
  ): Promise<iPagination<iSwipe>> {
    const response = await axios.get("/swipe/paginate", {
      params: { ...params, outcome },
    });
    return response.data;
  }

  static async requestStay(payload: any) {
    const response = await axios.post("stay-request", { payload });
    return response.data;
  }
}
