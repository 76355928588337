import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

import iDocument from "../../../assets/models/iDocument";
import DocumentController from "../../../assets/Controllers/DocumentController";

interface DocProps {
  name: string;
}

export function Documents({ name }: DocProps) {
  const { i18n } = useTranslation();

  const [documents, setDocuments] = useState<iDocument[]>();
  const [isLoading, setIsLoading] = useState(false);
  const { pathname } = useLocation();

  const load = async () => {
    setIsLoading(true);
    try {
      const docs = await DocumentController.getDocumentsByName(name);
      setDocuments(docs);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    load();
  }, [pathname]);

  const document = documents?.find((doc) => doc.lang === i18n.language);

  return (
    <main id="web-page">
      {isLoading ? (
        <div className="d-flex flex-wrap gap-2 p-3">
          <div className="height-160"></div>
          <div className="vw-100">
            <Skeleton height={60} />
          </div>
          <div className="vw-100">
            <Skeleton height={1000} />
          </div>
        </div>
      ) : null}

      <section className="web-section-container white-background">
        <div className="web-section-content">
          <div className="height-160"></div>

          <div key={"tos_" + Math.random()}>
            <div className="whitespace-pre-line">
              {document ? (
                <>
                  {/* <div className="h1 d-flex justify-content-center mb-4">
                    {document.name}
                  </div> */}
                  <div
                    className="documents"
                    dangerouslySetInnerHTML={{ __html: document.text }}
                  ></div>
                </>
              ) : (
                <span>Not found</span>
              )}
            </div>
          </div>

          <div className="height-100"></div>
        </div>
      </section>
    </main>
  );
}
