export const DEFAULT_PAGINATION_SIZE = 25;
export const MAX_PAGINATION_SIZE = 500;

export interface iPagination<Element> {
  data: Element[];
  page: number;
  size: number;
  total: number;
}

export interface iPaginationProps {
  page: number;
  size?: number;
  query?: string;
  sort?: string;
}
