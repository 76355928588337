// ProfileLayout.tsx
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import Icon from "../../../assets/TakyonDesignSystem/components/Icon/Icon";
import { useSelector } from "react-redux";
import {
  selectSellerPendingOffersCount,
  selectUser,
} from "../../../redux/slices/userSlice";
import { useTranslation } from "react-i18next";
import UserController from "../../../assets/Controllers/UserController";
import Button from "../../../assets/TakyonDesignSystem/components/Button/Button";
import { SMALL_SCREEN } from "../../../assets/Utils/generic";
import { useMediaQuery } from "react-responsive";
import {
  selectProfileMenuVisibility,
  setProfileMenu,
} from "../../../redux/slices/appSlice";
import { useAppDispatch } from "../../../redux/redux";
import getLink, { LINK_TYPE } from "../../../config/Links";
import { useEffect, useState } from "react";

type profileSections =
  | undefined
  | "wallet"
  | "offers"
  | "favorites"
  | "transactions"
  | "credits";

export function ProfileLayout() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery({ query: SMALL_SCREEN });
  const user = useSelector(selectUser);
  const offersPendingCount = useSelector(selectSellerPendingOffersCount);
  const isProfileMenuOpened = useSelector(selectProfileMenuVisibility);
  const dispatch = useAppDispatch();

  const location = useLocation();

  const [section, setSection] = useState<profileSections>();

  useEffect(() => {
    if (isSmallScreen) return;
    //ToDO: add those routes to links.tsx
    if (location.pathname.includes("wallet")) return setSection("wallet");
    if (location.pathname.includes("favorites")) return setSection("favorites");
    if (location.pathname.includes("transactions"))
      return setSection("transactions");
    if (location.pathname.includes("credits")) return setSection("credits");
    if (location.pathname.includes("offers")) return setSection("offers");

    setSection(undefined);
  }, [location.pathname]);

  const routeToProfile = (newRoute: profileSections) => {
    dispatch(setProfileMenu(false));
    navigate(`/profile/${newRoute ?? ""}`);
  };

  return (
    <div
      id="profile-layout"
      className="bg-white d-flex justify-content-center"
      style={{ height: "100vh", overflow: "hidden", paddingTop: "60px" }}
    >
      <div style={{ maxWidth: "1500px" }} className="d-flex w100 h100">
        {(isSmallScreen && isProfileMenuOpened) || !isSmallScreen ? (
          <div
            id="profile-sidebar"
            className={`
            border-end flex-shrink-0 white-background 
            ${isSmallScreen ? "position-absolute w-100" : ""}`}
            style={{
              width: "319px",
              left: "0px",
              zIndex: isSmallScreen ? 500 : 1,
              height: isSmallScreen ? "calc(100% - 65px)" : "unset",
            }}
          >
            <a
              onClick={() => routeToProfile(undefined)}
              id="customer-name"
              className={`w-100 d-flex align-items-center ps-5 ${
                isSmallScreen ? "pt-2 pb-3" : "py-4"
              } border-bottom gap-2 h4 text-decoration-none`}
            >
              <Icon icon="account_box" />
              <div>
                {user?.firstName} {user?.secondName}
              </div>
            </a>

            <div className="height-32"></div>

            <div className="ps-5 d-flex align-items-center gap-2">
              <Button
                variant={section === "wallet" ? "primary" : "secondary"}
                icon="wallet"
                text={t("profile_sidebar.wallet")}
                onClick={() => routeToProfile("wallet")}
              />
            </div>

            <div className="height-32"></div>

            <div className="ps-5 pe-3 d-flex flex-column gap-1 align-items-start border-bottom">
              <Button
                className="w100 d-flex justify-content-start"
                selected={section === "favorites"}
                chip
                variant="blank"
                icon="favorite"
                iconType="symbol"
                text={t("profile_sidebar.favorites")}
                onClick={() => routeToProfile("favorites")}
              />

              <Button
                className="w100 d-flex justify-content-start"
                selected={section === "offers"}
                chip
                variant="blank"
                icon="mark_as_unread"
                iconType="symbol"
                text={t("profile_sidebar.offers")}
                onClick={() => routeToProfile("offers")}
                notification={offersPendingCount}
              />

              <Button
                className="w100 d-flex justify-content-start"
                chip
                selected={section === "transactions"}
                variant="blank"
                icon="account_balance"
                iconType="symbol"
                text={t("profile_sidebar.earnings")}
                onClick={() => routeToProfile("transactions")}
              />
              <Button
                className="w100 d-flex justify-content-start"
                chip
                selected={section === "credits"}
                variant="blank"
                icon="credit_score"
                iconType="symbol"
                text={t("profile_sidebar.credits")}
                onClick={() => routeToProfile("credits")}
              />
              <div className="height-16"></div>
            </div>

            <div className="ps-5 d-flex flex-column gap-3">
              <div className="height-16"></div>
              <a
                className="black-color"
                onClick={() => {
                  dispatch(setProfileMenu(false));
                  navigate(getLink(LINK_TYPE.PROFILE_SUPPORT));
                }}
              >
                {t("profile_sidebar.support")}
              </a>
              <a
                className="red-color"
                onClick={() => {
                  dispatch(setProfileMenu(false));
                  UserController.logout();
                  navigate(getLink(LINK_TYPE.WALL));
                }}
              >
                {t("auth.logout")}
              </a>
            </div>
          </div>
        ) : null}
        <div style={{ overflow: "hidden" }} className="w100 h100">
          <Outlet /> {/* Qui verranno renderizzate le sottopagine */}
        </div>
      </div>
    </div>
  );
}
