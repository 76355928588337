import { useTranslation } from "react-i18next";
import { iBid } from "../../../models/iBid";
import TakBid from "./TakBid";
import NothingHere from "../Wallet/Components/NothingHere";

export default function TakDealBuy({ bids }: { bids: iBid[] }) {
  const { t } = useTranslation();

  return bids.length === 0 ? (
    <NothingHere
      title={t("wallet.offers.nooffers")}
      subtitle={t("wallet.offers.noofferstext")}
    />
  ) : (
    <div className="bg-light-grey ">
      {/* initially a deal has only one bid/offer */}
      {bids.map((bid, key) => (
        <div key={bid._id}>
          <TakBid offer={bid} isMostRecent={key === 0} />
          {key === bids.length - 1 ? null : <hr className="m-0" />}
        </div>
      ))}
    </div>
  );
}
