import { iPartner } from "../../../models/iPartner";

interface Props {
  partner: iPartner;
  partnerPage?: boolean;
}

export default function PartnerCard({ partner, partnerPage }: Props) {
  const linkId = "partner_link_" + partner._id;

  if (partnerPage) {
    return (
      <div className="d-flex flex-column p-2">
        <div className="partner-card position-relative">
          <a
            href={partner.website}
            hidden
            id={linkId}
            target="_blank"
            rel="noopener noreferrer"
          >
            {partner.website}
          </a>

          <div>
            <img className="image" src={partner.image} alt="" />
          </div>

          <div
            onClick={() => document.getElementById(linkId)?.click()}
            className="cursor-pointer d-flex justify-content-center align-items-center position-absolute bottom-0 end-0 mb-2 me-2 width-35 height-35 rounded-circle primary-background"
          >
            <span className="material-symbols-outlined">north_east</span>
          </div>
        </div>

        <div className="height-15"></div>

        <div style={{ maxWidth: "170px" }}>
          <h4 className="bodytext regular white-color">{partner.name}</h4>
          <p className="bodytext-xs light-grey-color">
            {partner.location?.label}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="partner-card position-relative">
      <div className="position-relative">
        <img className="image" src={partner.image} alt="" />
      </div>

      <div className="d-flex justify-content-center align-items-center position-absolute black-50-background w100 h100 rounded">
        <img className="width-80" src={partner.logo} alt=""></img>
      </div>

      <div className="height-15"></div>
    </div>
  );
}
