import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import OffersReceived from "./Offers/OffersReceived";
import OffersDone from "./Offers/OffersDone";
import TabLayout from "../Layouts/TabLayout";

export default function ProfileOffers() {
  const { t } = useTranslation();

  const tabs = [
    {
      key: "received",
      label: t("offers.received.tab_title"),
      component: <OffersReceived />,
    },
    {
      key: "done",
      label: t("offers.done.tab_title"),
      component: <OffersDone />,
    },
  ];

  return (
    <>
      <TabLayout
        title={t("offers.title")}
        tabs={tabs}
        defaultTabKey="received" // Imposta la scheda di default
      />
    </>
  );
}
