import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { SMALL_SCREEN } from "../../../assets/Utils/generic";
import { openModal } from "../../../assets/Utils/modal";
import Animate from "../../components/Animate/Animate";
import BecomePartner from "../../components/Body/BecomePartner/BecomePartner";
import heroimg from "../../../assets/img/forpartners/Other_Header.png";
import Button from "../../../assets/TakyonDesignSystem/components/Button/Button";
import { scrollTo } from "../../../assets/Utils/generic";

export default function ForGeneric({
  title1,
  title2,
  title3,
  title4,
  titleClass,
  cobrandingImg,
}: {
  title1?: string;
  title2?: string;
  title3?: string;
  title4?: string;
  titleClass?: string;
  cobrandingImg?: string;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery({ query: SMALL_SCREEN });

  return (
    <main id="web-page">
      <section
        style={{
          height: isSmallScreen ? "unset" : "100vh",
          background: `url(${heroimg})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
        }}
        className="web-section-container w100 position-relative"
      >
        {/* <div
          style={{ top: 0, bottom: 0, left: 0, right: 0, opacity: 0.7 }}
          className="position-absolute black-background"
        ></div> */}

        <div style={{ zIndex: 2 }} className="container h100">
          <div className="content h100">
            <div className="height-100 only-mobile"></div>
            <div className="d-flex h100 align-items-center justify-content-center">
              <div className="d-md-flex" style={{ gap: "50px" }}>
                <div className="white-color w100">
                  {cobrandingImg ? (
                    <div
                      style={{
                        width: "200px",
                        zIndex: 2,
                      }}
                    >
                      <img className="w100" src={cobrandingImg} alt="" />
                      <div className="height-25"></div>
                    </div>
                  ) : null}
                  <Animate
                    element={
                      <h1
                        className={`m-0 regular
                      ${titleClass ?? "h1-large"} 
                      `}
                      >
                        {title1 ?? t("forgeneric.section_1.1")}{" "}
                        <i>
                          {title2} {title3}
                        </i>
                      </h1>
                    }
                    automatic
                    delay={0}
                  />
                  <div className="height-20"></div>
                  <Animate
                    element={
                      <>
                        <p className="m-0 bodytext light">
                          {t("forgeneric.section_1.2")}
                        </p>
                        <p className="m-0 bodytext light">
                          {t("forgeneric.section_1.3")}
                        </p>
                      </>
                    }
                    automatic
                    delay={0.3}
                  />
                  <div className="height-40"></div>

                  <Animate
                    element={
                      <Button
                        text={t("forgeneric.become_button")}
                        iconFill
                        onClick={() => {
                          scrollTo("becomePartnerForm");
                        }}
                      />
                    }
                    automatic
                    delay={0.6}
                  />
                </div>

                <div className="height-80 only-mobile"></div>
              </div>
            </div>
            <div className="height-50 only-mobile"></div>
          </div>
        </div>
      </section>

      <div className="height-100"></div>

      <div className="container">
        <div className="content">
          <span className="h1-large regular white-color">
            {t("forgeneric.section_1.4")}
          </span>

          <div className="height-25"></div>

          <div className="d-flex flex-column flex-lg-row gap-3">
            <div className="w100 border rounded p-3 height-250">
              <Button variant="light" text={t("forgeneric.section_1.5")} />
              <div className="height-25"></div>
              <p className="bodytext light light-grey-color">
                {t("forgeneric.section_1.6")}
              </p>
            </div>
            <div className="w100 border rounded p-3 height-250">
              <Button variant="light" text={t("forgeneric.section_1.7")} />
              <div className="height-25"></div>
              <p className="bodytext light light-grey-color">
                {t("forgeneric.section_1.8")}
              </p>
            </div>
            <div className="w100 border rounded p-3 height-250">
              <Button variant="light" text={t("forgeneric.section_1.9")} />
              <div className="height-25"></div>
              <p className="bodytext light light-grey-color">
                {t("forgeneric.section_1.10")}
              </p>
            </div>
          </div>
        </div>
        <div className="height-50"></div>

        <div id="becomePartnerForm" className="mt-5 white-background">
          <BecomePartner />
        </div>
      </div>
      <div className="height-100"></div>
    </main>
  );
}
