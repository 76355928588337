import { useState } from "react";
import { useTranslation } from "react-i18next";
import OfferController from "../../../../../assets/Controllers/OfferController";
import { apiErrorToast } from "../../../../../assets/Utils/errors";
import Button from "../../../../../assets/TakyonDesignSystem/components/Button/Button";
import _OffersActionsLayout from "./_OffersActionsLayout";
import { useOfferNavigation } from "../../../../hooks/hooks";
import { openModal } from "../../../../../assets/Utils/modal";
import ActionFeedbackModal from "../../../Modals/ActionFeedbackModal";
import helpers from "../../../../helpers/helpers";
import { useNavigate } from "react-router-dom";

export default function OfferCancel() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { offer } = useOfferNavigation();
  const navigate = useNavigate();

  const cancelOfferClick = async () => {
    setIsLoading(true);
    try {
      await OfferController.cancelOffer({ bidId: offer._id });

      openModal({
        content: (
          <ActionFeedbackModal
            icon="check_circle"
            title={t("wallet.offers.youcanceled1")}
            message={`${t("wallet.offers.youcanceled2")} ${helpers.price(
              offer.amount
            )}`}
            continueClickCallback={() => navigate("/profile/offers")}
            continueBtnText={t("app.checkout.viewoffers")}
          />
        ),
      });
    } catch (error) {
      apiErrorToast(error);
    }
    setIsLoading(false);
  };

  if (isLoading) return <span>Loading ...</span>;

  if (!offer) return <span>No offer ...</span>;

  return (
    <_OffersActionsLayout title={t("wallet.offers.cancel")}>
      <div className="d-flex bodytext my-3">
        <div className="flex-grow-1 light">
          {t("wallet.offers.offer_sent")}:
        </div>
        <div>{helpers.price(Number(offer.amount))}</div>
      </div>

      <hr className="my-3" />

      <div className="d-flex justify-content-end">
        <Button
          onClick={() => {
            cancelOfferClick();
            // openWalletPage({
            //   id: "wallet_offer_page_success" + offer._id,
            //   content: <WalletOfferSuccess offer={offer} mode="cancel" />,
            //   hideGoBack: true,
            // });
          }}
          text={t("wallet.offers.cancel")}
        />
      </div>
    </_OffersActionsLayout>
  );
}
