import { useNavigate } from "react-router-dom";
import mallorcaBG from "../../../assets/img/mallorca/mallorca_bg.png";
import takImg from "../../../assets/TakyonDesignSystem/images/TakyonLight.svg";

import fideltour from "../../../assets/img/mallorca/fideltour.png";
import hotelverse from "../../../assets/img/mallorca/hotelverse.svg";
import triptease from "../../../assets/img/mallorca/triptease.png";
import hotelsaas from "../../../assets/img/mallorca/hotelsaas.webp";

import Button from "../../../assets/TakyonDesignSystem/components/Button/Button";
import Animate from "../../components/Animate/Animate";
import Icon from "../../../assets/TakyonDesignSystem/components/Icon/Icon";

// const agenda = [
//   { ora: "11:45 - 12:00", testo: "Registro de asistentes" },
//   {
//     ora: "12:00 - 12:15",
//     testo:
//       "Bienvenida por parte de <span class='fw-medium'>Jaume Monserrat</span> - Presidente de Turistec",
//   },
//   {
//     ora: "12:15 - 12:30",
//     testo:
//       'Ponencia de <span class="fw-medium">Quicktext:</span> "Navegando el Futuro: IA y Estrategias de Datos para Hoteles en Mallorca"',
//   },
//   {
//     ora: "12:30 - 12:45",
//     testo:
//       "Ponencia de <span class='fw-medium'>Hotel Mssngr:</span> “Optimización de la Comunicación hotel-huésped a través de la tecnología”",
//   },
//   {
//     ora: "12:45 - 13:00",
//     testo:
//       'Ponencia de <span class="fw-medium">Takyon:</span> "Evolución hacía la Web 3 y caso de uso"',
//   },
//   {
//     ora: "13:00 - 13:30",
//     testo:
//       'Dinámica de Grupo: "Ideas sobre ¿Cómo será la Experiencia del Cliente del Futuro?"',
//   },
//   { ora: "13:30 - 15:30", testo: "Networking y Almuerzo" },
// ];

const link = "https://share-eu1.hsforms.com/1By5g1fyHSRSxTvNWlthz7gg4jt5";

export default function LandingMadrid() {
  // const navigate = useNavigate();
  return (
    <main id="web-page">
      <a hidden href={link} target="_blank" id="landing-mallorca-link"></a>
      <section
        style={{
          height: "100dvh",
          minHeight: "800px",
          backgroundImage: `url(${mallorcaBG})`,
          backgroundSize: "cover",
          backgroundPosition: "center cetnter",
          backgroundBlendMode: "multiply",
          backgroundColor: "rgba(0,0,0,0.5) !important",
        }}
        className="web-section-container w100 light-grey-background justify-content-center position-relative"
      >
        <Animate
          automatic
          element={
            <div
              style={{ zIndex: 2 }}
              className="h100 web-section-content px-3"
            >
              <div className="h100 d-flex flex-column gap-1 gamp-md-4 gap-md-1 align-items-center justify-content-center">
                <img
                  className="only-mobile"
                  style={{ width: "10%", minWidth: "70px" }}
                  src={takImg}
                  alt=""
                />
                <div className="p-2 d-flex align-items-center justify-content-center">
                  <img
                    style={{
                      width: "14%",
                      minWidth: "100px",
                    }}
                    src={fideltour}
                    alt=""
                  />
                  <div className="vertical-divider mx-3 mx-md-4"></div>
                  <img
                    style={{ width: "15%", minWidth: "100px" }}
                    src={hotelverse}
                    alt=""
                  />
                  <div className="vertical-divider mx-3 mx-md-4"></div>
                  <img
                    className="only-desktop"
                    style={{ width: "8%", minWidth: "100px" }}
                    src={takImg}
                    alt=""
                  />
                  <div className="vertical-divider mx-3 mx-md-4 only-desktop"></div>
                  <img
                    style={{
                      width: "18%",
                      minWidth: "100px",
                      filter: "invert(1)",
                    }}
                    src={triptease}
                    alt=""
                  />
                </div>
                <div style={{ height: "30px" }} className="only-mobile"></div>
                <div style={{ height: "80px" }} className="only-desktop"></div>
                <span
                  className="bodytext-lg regular text-center primary-color"
                  style={{ marginBottom: "" }}
                >
                  Madrid, 2 de octubre
                </span>
                <h1
                  className="h1-large regular text-center white-color"
                  style={{ marginBottom: "48px" }}
                >
                  Conectando Hospitalidad e Innovación:
                  <br />
                  Cocktail VIP en Madrid
                </h1>
                <Button
                  text="inscríbete al evento"
                  onClick={() =>
                    document.getElementById("landing-mallorca-link")?.click()
                  }
                ></Button>
                <div style={{ height: "30px" }} className="only-mobile"></div>
                <div style={{ height: "80px" }} className="only-desktop"></div>
                <div className="d-flex flex-column flex-md-row gap-md-4 w100 align-items-center justify-content-center">
                  <p className="white-color">Con la colaboración de</p>
                  <div style={{ height: "25px", width: "160px" }}>
                    <img className="h100 w100" src={hotelsaas} alt="" />
                  </div>
                </div>
              </div>
            </div>
          }
        />
      </section>
      <section
        style={{ minHeight: "100vh" }}
        className="web-section-container w100"
      >
        <div style={{ height: "130 px" }}></div>

        <div className="h100 web-section-content">
          <Animate
            element={
              <div>
                <div style={{ height: "80px" }} className="only-mobile"></div>
                <div style={{ height: "120px" }} className="only-desktop"></div>
                <div className="border rounded p-3 mb-5 white-color">
                  <div className="d-flex align-items-center">
                    <div className="width-50">
                      <Icon size={30} icon="event" />
                    </div>
                    <h2 className="bodytext-lg medium mb-1 text-center text-md-left">
                      2 de octubre, 19.00hrs
                    </h2>
                  </div>
                  <br />
                  <div className="d-flex align-items-center">
                    <div className="width-50">
                      <Icon size={30} icon="pin_drop" />
                    </div>
                    <h2 className="bodytext-lg medium mb-1 text-center text-md-left">
                      Hotel Riu Plaza de España <br /> Gran Vía 84, Madrid,
                      España
                    </h2>
                  </div>
                </div>
                <p className="text-center text-md-left white-color">
                  Nos complace invitarte a un exclusivo Cocktail organizado por
                  Fideltour, Hotelverse, Takyon y Triptease y que cuenta con la
                  colaboración de HotelSAAS. Será una ocasión única para
                  compartir experiencias, establecer nuevas alianzas y descubrir
                  las últimas tendencias que están transformando nuestra
                  industria. No habrá ponencias y el encuentro, exclusivo para
                  hoteleros y que contará con plazas limitadas, se llevará a
                  cabo en la azotea del hotel en un ambiente totalmente
                  relajado.
                </p>
                <div className="height-40"></div>
                {/* <h2 className="medium mb-4 text-center text-md-left">Agenda</h2>
                {agenda.map((item, index) => (
                  <div
                    key={index}
                    className={
                      index !== agenda.length - 1 ? "row border-bottom" : "row"
                    }
                  >
                    <div className="col-12 col-md-4 py-3 py-md-4 text-center text-md-left">
                      <h4 className="medium">{item.ora}</h4>
                    </div>
                    <div className="col-12 col-md-8 pb-3 py-md-4 text-center text-md-left">
                      <div dangerouslySetInnerHTML={{ __html: item.testo }} />
                    </div>
                  </div>
                ))} */}
                {/* <div style={{ height: "70px" }} className="only-mobile"></div>
                <div style={{ height: "100px" }} className="only-desktop"></div> */}
                {/* <p className="text-center text-md-left">
                  Evento moderado por:{" "}
                  <span className="fw-semibold">
                    Ana Alonso, CRO de BR Group
                  </span>
                </p>
                <h4 className="text-center text-md-left mt-4">
                  ¡No te lo puedes perder!
                  <br />
                  Inscribete al evento, plazas limitadas, en el siguiente link
                </h4> */}

                <div className="d-flex justify-content-center mt-5 mb-5 mt-md-4">
                  <Button
                    onClick={() =>
                      document.getElementById("landing-mallorca-link")?.click()
                    }
                    text="inscríbete al evento"
                  ></Button>
                </div>
                {/* <div style={{ height: "80px" }} className="only-mobile"></div>
                <div style={{ height: "120px" }} className="only-desktop"></div> */}
              </div>
            }
          />
        </div>
      </section>
    </main>
  );
}
