import { ChangeEvent } from "react";
import Icon from "../Icon/Icon";

interface Props {
  onChange?(e: ChangeEvent<HTMLInputElement>): void;
  type?: string;
  className?: string;
  required?: boolean;
  defaultChecked?: boolean;
  checked?: boolean;
  name?: string;
  id?: string;
  readOnly?: boolean;
}

export default function Switch(props: Props) {
  return (
    <>
      <label
        className={`
        custom-form-switch
        ${props.className}`}
      >
        <div className="back"></div>
        <input
          readOnly={props.readOnly}
          checked={props.checked}
          defaultChecked={props.defaultChecked}
          onChange={(e) => props.onChange?.(e)}
          type="checkbox"
        />
        <div className="ball">
          <Icon
            size={23}
            icon="close"
            type="symbol"
            className="dark-grey-color unchecked"
          />
          <Icon
            size={23}
            icon="check"
            type="symbol"
            className="dark-grey-color checked"
          />
        </div>
      </label>
    </>
  );
}
