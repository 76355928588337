export enum LANG {
  en = "en",
  it = "it",
  es = "es",

  // af_NA = "af_NA",
  // af_ZA = "af_ZA",
  // af = "af",
  // ak_GH = "ak_GH",
  // ak = "ak",
  // sq_AL = "sq_AL",
  // sq = "sq",
  // am_ET = "am_ET",
  // am = "am",
  // ar_DZ = "ar_DZ",
  // ar_BH = "ar_BH",
  // ar_EG = "ar_EG",
  // ar_IQ = "ar_IQ",
  // ar_JO = "ar_JO",
  // ar_KW = "ar_KW",
  // ar_LB = "ar_LB",
  // ar_LY = "ar_LY",
  // ar_MA = "ar_MA",
  // ar_OM = "ar_OM",
  // ar_QA = "ar_QA",
  // ar_SA = "ar_SA",
  // ar_SD = "ar_SD",
  // ar_SY = "ar_SY",
  // ar_TN = "ar_TN",
  // ar_AE = "ar_AE",
  // ar_YE = "ar_YE",
  // ar = "ar",
  // hy_AM = "hy_AM",
  // hy = "hy",
  // as_IN = "as_IN",
  // as = "as",
  // asa_TZ = "asa_TZ",
  // asa = "asa",
  // az_Cyrl = "az_Cyrl",
  // az_Cyrl_AZ = "az_Cyrl_AZ",
  // az_Latn = "az_Latn",
  // az_Latn_AZ = "az_Latn_AZ",
  // az = "az",
  // bm_ML = "bm_ML",
  // bm = "bm",
  // eu_ES = "eu_ES",
  // eu = "eu",
  // be_BY = "be_BY",
  // be = "be",
  // bem_ZM = "bem_ZM",
  // bem = "bem",
  // bez_TZ = "bez_TZ",
  // bez = "bez",
  // bn_BD = "bn_BD",
  // bn_IN = "bn_IN",
  // bn = "bn",
  // bs_BA = "bs_BA",
  // bs = "bs",
  // bg_BG = "bg_BG",
  // bg = "bg",
  // my_MM = "my_MM",
  // my = "my",
  // yue_Hant_HK = "yue_Hant_HK",
  // ca_ES = "ca_ES",
  // ca = "ca",
  // tzm_Latn = "tzm_Latn",
  // tzm_Latn_MA = "tzm_Latn_MA",
  // tzm = "tzm",
  // chr_US = "chr_US",
  // chr = "chr",
  // cgg_UG = "cgg_UG",
  // cgg = "cgg",
  // zh_Hans = "zh_Hans",
  // zh_Hans_CN = "zh_Hans_CN",
  // zh_Hans_HK = "zh_Hans_HK",
  // zh_Hans_MO = "zh_Hans_MO",
  // zh_Hans_SG = "zh_Hans_SG",
  // zh_Hant = "zh_Hant",
  // zh_Hant_HK = "zh_Hant_HK",
  // zh_Hant_MO = "zh_Hant_MO",
  // zh_Hant_TW = "zh_Hant_TW",
  // zh = "zh",
  // kw_GB = "kw_GB",
  // kw = "kw",
  // hr_HR = "hr_HR",
  // hr = "hr",
  // cs_CZ = "cs_CZ",
  // cs = "cs",
  // da_DK = "da_DK",
  // da = "da",
  // nl_BE = "nl_BE",
  // nl_NL = "nl_NL",
  // nl = "nl",
  // ebu_KE = "ebu_KE",
  // ebu = "ebu",
  // en_AS = "en_AS",
  // en_AU = "en_AU",
  // en_BE = "en_BE",
  // en_BZ = "en_BZ",
  // en_BW = "en_BW",
  // en_CA = "en_CA",
  // en_GU = "en_GU",
  // en_HK = "en_HK",
  // en_IN = "en_IN",
  // en_IE = "en_IE",
  // en_IL = "en_IL",
  // en_JM = "en_JM",
  // en_MT = "en_MT",
  // en_MH = "en_MH",
  // en_MU = "en_MU",
  // en_NA = "en_NA",
  // en_NZ = "en_NZ",
  // en_MP = "en_MP",
  // en_PK = "en_PK",
  // en_PH = "en_PH",
  // en_SG = "en_SG",
  // en_ZA = "en_ZA",
  // en_TT = "en_TT",
  // en_UM = "en_UM",
  // en_VI = "en_VI",
  // en_GB = "en_GB",
  // en_US = "en_US",
  // en_ZW = "en_ZW",
  // eo = "eo",
  // et_EE = "et_EE",
  // et = "et",
  // ee_GH = "ee_GH",
  // ee_TG = "ee_TG",
  // ee = "ee",
  // fo_FO = "fo_FO",
  // fo = "fo",
  // fil_PH = "fil_PH",
  // fil = "fil",
  // fi_FI = "fi_FI",
  // fi = "fi",
  // fr_BE = "fr_BE",
  // fr_BJ = "fr_BJ",
  // fr_BF = "fr_BF",
  // fr_BI = "fr_BI",
  // fr_CM = "fr_CM",
  // fr_CA = "fr_CA",
  // fr_CF = "fr_CF",
  // fr_TD = "fr_TD",
  // fr_KM = "fr_KM",
  // fr_CG = "fr_CG",
  // fr_CD = "fr_CD",
  // fr_CI = "fr_CI",
  // fr_DJ = "fr_DJ",
  // fr_GQ = "fr_GQ",
  // fr_FR = "fr_FR",
  // fr_GA = "fr_GA",
  // fr_GP = "fr_GP",
  // fr_GN = "fr_GN",
  // fr_LU = "fr_LU",
  // fr_MG = "fr_MG",
  // fr_ML = "fr_ML",
  // fr_MQ = "fr_MQ",
  // fr_MC = "fr_MC",
  // fr_NE = "fr_NE",
  // fr_RW = "fr_RW",
  // fr_RE = "fr_RE",
  // fr_BL = "fr_BL",
  // fr_MF = "fr_MF",
  // fr_SN = "fr_SN",
  // fr_CH = "fr_CH",
  // fr_TG = "fr_TG",
  // fr = "fr",
  // ff_SN = "ff_SN",
  // ff = "ff",
  // gl_ES = "gl_ES",
  // gl = "gl",
  // lg_UG = "lg_UG",
  // lg = "lg",
  // ka_GE = "ka_GE",
  // ka = "ka",
  // de_AT = "de_AT",
  // de_BE = "de_BE",
  // de_DE = "de_DE",
  // de_LI = "de_LI",
  // de_LU = "de_LU",
  // de_CH = "de_CH",
  // de = "de",
  // el_CY = "el_CY",
  // el_GR = "el_GR",
  // el = "el",
  // gu_IN = "gu_IN",
  // gu = "gu",
  // guz_KE = "guz_KE",
  // guz = "guz",
  // ha_Latn = "ha_Latn",
  // ha_Latn_GH = "ha_Latn_GH",
  // ha_Latn_NE = "ha_Latn_NE",
  // ha_Latn_NG = "ha_Latn_NG",
  // ha = "ha",
  // haw_US = "haw_US",
  // haw = "haw",
  // he_IL = "he_IL",
  // he = "he",
  // hi_IN = "hi_IN",
  // hi = "hi",
  // hu_HU = "hu_HU",
  // hu = "hu",
  // is_IS = "is_IS",
  // is = "is",
  // ig_NG = "ig_NG",
  // ig = "ig",
  // id_ID = "id_ID",
  // id = "id",
  // ga_IE = "ga_IE",
  // ga = "ga",
  // it_IT = "it_IT",
  // it_CH = "it_CH",
  // ja_JP = "ja_JP",
  // ja = "ja",
  // kea_CV = "kea_CV",
  // kea = "kea",
  // kab_DZ = "kab_DZ",
  // kab = "kab",
  // kl_GL = "kl_GL",
  // kl = "kl",
  // kln_KE = "kln_KE",
  // kln = "kln",
  // kam_KE = "kam_KE",
  // kam = "kam",
  // kn_IN = "kn_IN",
  // kn = "kn",
  // kk_Cyrl = "kk_Cyrl",
  // kk_Cyrl_KZ = "kk_Cyrl_KZ",
  // kk = "kk",
  // km_KH = "km_KH",
  // km = "km",
  // ki_KE = "ki_KE",
  // ki = "ki",
  // rw_RW = "rw_RW",
  // rw = "rw",
  // kok_IN = "kok_IN",
  // kok = "kok",
  // ko_KR = "ko_KR",
  // ko = "ko",
  // khq_ML = "khq_ML",
  // khq = "khq",
  // ses_ML = "ses_ML",
  // ses = "ses",
  // lag_TZ = "lag_TZ",
  // lag = "lag",
  // lv_LV = "lv_LV",
  // lv = "lv",
  // lt_LT = "lt_LT",
  // lt = "lt",
  // luo_KE = "luo_KE",
  // luo = "luo",
  // luy_KE = "luy_KE",
  // luy = "luy",
  // mk_MK = "mk_MK",
  // mk = "mk",
  // jmc_TZ = "jmc_TZ",
  // jmc = "jmc",
  // kde_TZ = "kde_TZ",
  // kde = "kde",
  // mg_MG = "mg_MG",
  // mg = "mg",
  // ms_BN = "ms_BN",
  // ms_MY = "ms_MY",
  // ms = "ms",
  // ml_IN = "ml_IN",
  // ml = "ml",
  // mt_MT = "mt_MT",
  // mt = "mt",
  // gv_GB = "gv_GB",
  // gv = "gv",
  // mr_IN = "mr_IN",
  // mr = "mr",
  // mas_KE = "mas_KE",
  // mas_TZ = "mas_TZ",
  // mas = "mas",
  // mer_KE = "mer_KE",
  // mer = "mer",
  // mfe_MU = "mfe_MU",
  // mfe = "mfe",
  // naq_NA = "naq_NA",
  // naq = "naq",
  // ne_IN = "ne_IN",
  // ne_NP = "ne_NP",
  // ne = "ne",
  // nd_ZW = "nd_ZW",
  // nd = "nd",
  // nb_NO = "nb_NO",
  // nb = "nb",
  // nn_NO = "nn_NO",
  // nn = "nn",
  // nyn_UG = "nyn_UG",
  // nyn = "nyn",
  // or_IN = "or_IN",
  // or = "or",
  // om_ET = "om_ET",
  // om_KE = "om_KE",
  // om = "om",
  // ps_AF = "ps_AF",
  // ps = "ps",
  // fa_AF = "fa_AF",
  // fa_IR = "fa_IR",
  // fa = "fa",
  // pl_PL = "pl_PL",
  // pl = "pl",
  // pt_BR = "pt_BR",
  // pt_GW = "pt_GW",
  // pt_MZ = "pt_MZ",
  // pt_PT = "pt_PT",
  // pt = "pt",
  // pa_Arab = "pa_Arab",
  // pa_Arab_PK = "pa_Arab_PK",
  // pa_Guru = "pa_Guru",
  // pa_Guru_IN = "pa_Guru_IN",
  // pa = "pa",
  // ro_MD = "ro_MD",
  // ro_RO = "ro_RO",
  // ro = "ro",
  // rm_CH = "rm_CH",
  // rm = "rm",
  // rof_TZ = "rof_TZ",
  // rof = "rof",
  // ru_MD = "ru_MD",
  // ru_RU = "ru_RU",
  // ru_UA = "ru_UA",
  // ru = "ru",
  // rwk_TZ = "rwk_TZ",
  // rwk = "rwk",
  // saq_KE = "saq_KE",
  // saq = "saq",
  // sg_CF = "sg_CF",
  // sg = "sg",
  // seh_MZ = "seh_MZ",
  // seh = "seh",
  // sr_Cyrl = "sr_Cyrl",
  // sr_Cyrl_BA = "sr_Cyrl_BA",
  // sr_Cyrl_ME = "sr_Cyrl_ME",
  // sr_Cyrl_RS = "sr_Cyrl_RS",
  // sr_Latn = "sr_Latn",
  // sr_Latn_BA = "sr_Latn_BA",
  // sr_Latn_ME = "sr_Latn_ME",
  // sr_Latn_RS = "sr_Latn_RS",
  // sr = "sr",
  // sn_ZW = "sn_ZW",
  // sn = "sn",
  // ii_CN = "ii_CN",
  // ii = "ii",
  // si_LK = "si_LK",
  // si = "si",
  // sk_SK = "sk_SK",
  // sk = "sk",
  // sl_SI = "sl_SI",
  // sl = "sl",
  // xog_UG = "xog_UG",
  // xog = "xog",
  // so_DJ = "so_DJ",
  // so_ET = "so_ET",
  // so_KE = "so_KE",
  // so_SO = "so_SO",
  // so = "so",
  // es_AR = "es_AR",
  // es_BO = "es_BO",
  // es_CL = "es_CL",
  // es_CO = "es_CO",
  // es_CR = "es_CR",
  // es_DO = "es_DO",
  // es_EC = "es_EC",
  // es_SV = "es_SV",
  // es_GQ = "es_GQ",
  // es_GT = "es_GT",
  // es_HN = "es_HN",
  // es_419 = "es_419",
  // es_MX = "es_MX",
  // es_NI = "es_NI",
  // es_PA = "es_PA",
  // es_PY = "es_PY",
  // es_PE = "es_PE",
  // es_PR = "es_PR",
  // es_ES = "es_ES",
  // es_US = "es_US",
  // es_UY = "es_UY",
  // es_VE = "es_VE",
  // sw_KE = "sw_KE",
  // sw_TZ = "sw_TZ",
  // sw = "sw",
  // sv_FI = "sv_FI",
  // sv_SE = "sv_SE",
  // sv = "sv",
  // gsw_CH = "gsw_CH",
  // gsw = "gsw",
  // shi_Latn = "shi_Latn",
  // shi_Latn_MA = "shi_Latn_MA",
  // shi_Tfng = "shi_Tfng",
  // shi_Tfng_MA = "shi_Tfng_MA",
  // shi = "shi",
  // dav_KE = "dav_KE",
  // dav = "dav",
  // ta_IN = "ta_IN",
  // ta_LK = "ta_LK",
  // ta = "ta",
  // te_IN = "te_IN",
  // te = "te",
  // teo_KE = "teo_KE",
  // teo_UG = "teo_UG",
  // teo = "teo",
  // th_TH = "th_TH",
  // th = "th",
  // bo_CN = "bo_CN",
  // bo_IN = "bo_IN",
  // bo = "bo",
  // ti_ER = "ti_ER",
  // ti_ET = "ti_ET",
  // ti = "ti",
  // to_TO = "to_TO",
  // to = "to",
  // tr_TR = "tr_TR",
  // tr = "tr",
  // uk_UA = "uk_UA",
  // uk = "uk",
  // ur_IN = "ur_IN",
  // ur_PK = "ur_PK",
  // ur = "ur",
  // uz_Arab = "uz_Arab",
  // uz_Arab_AF = "uz_Arab_AF",
  // uz_Cyrl = "uz_Cyrl",
  // uz_Cyrl_UZ = "uz_Cyrl_UZ",
  // uz_Latn = "uz_Latn",
  // uz_Latn_UZ = "uz_Latn_UZ",
  // uz = "uz",
  // vi_VN = "vi_VN",
  // vi = "vi",
  // vun_TZ = "vun_TZ",
  // vun = "vun",
  // cy_GB = "cy_GB",
  // cy = "cy",
  // yo_NG = "yo_NG",
  // yo = "yo",
  // zu_ZA = "zu_ZA",
  // zu = "zu",
}

export const DEFAULT_LANGUAGE: LANG = LANG.en;
