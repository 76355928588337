import { useTranslation } from "react-i18next";
import BecomePartner from "../../components/Body/BecomePartner/BecomePartner";
import NexiHead from "./Sub/NexiHead";
import { useRef } from "react";
import { text } from "stream/consumers";
import WebStepper from "../../components/WebStepper/WebStepper";

export default function NexiTerms() {
  const { t } = useTranslation();

  const buttonRef = useRef<HTMLElement>(null);

  const terms = [
    {
      discount: "14€ ",
      price: "0€ ",
      text: t("nexi.terms.1"),
    },
    {
      discount: "0,29€",
      price: "0,15€ ",
      text: t("nexi.terms.2"),
    },
    {
      discount: "1,25%",
      price: "0,89%",
      text: t("nexi.terms.3"),
    },
  ];

  return (
    <main id="web-page">
      <section>
        <NexiHead
          text={t("nexi.terms.text")}
          btntext={t("nexi.btntext")}
          buttonRef={buttonRef}
        />
      </section>

      <section className="web-section-container">
        <div className="web-section-content align-items-center">
          <div className="height-100"></div>

          <span className="h1-large text-center">{t("nexi.terms.title")}</span>

          <div className="height-100"></div>

          <div className="d-flex flex-column flex-md-row align-items-center justify-content-between w100 gap-5">
            {terms.map((term, index) => (
              <div
                key={index}
                className="d-flex flex-column justify-content-between text-center w100"
              >
                <span className="dark-grey-color h3 regular text-decoration-line-through">
                  {term.discount}
                </span>

                <span className="h1-large regular">{term.price}</span>

                <hr className="m-3" />

                <span>{term.text}</span>
              </div>
            ))}
          </div>

          <div className="height-100"></div>
        </div>
      </section>

      <hr />

      <WebStepper
        text={[
          t("nexi.cs.rate_1"),
          t("nexi.cs.rate_2"),
          t("nexi.cs.rate_3"),
          t("nexi.cs.rate_4"),
          t("nexi.cs.rate_5"),
          t("nexi.cs.rate_6"),
          t("nexi.cs.rate_7"),
          t("nexi.cs.rate_8"),
        ]}
      />

      <section ref={buttonRef} className="light-grey-background w100">
        <BecomePartner title={t("forhotels.section_1.4")} />
      </section>
    </main>
  );
}
