import Icon from "../Icon/Icon";

export interface iPanelButtonProps {
  icon?: string;
  text: string;
  background?: string;
  onClick: () => void;
}

export default function PanelButton(props: iPanelButtonProps) {
  return (
    <div
      className="panel-button w100"
      onClick={(e) => {
        e.preventDefault();
        props.onClick();
      }}
    >
      <div
        className="panel-button-bg"
        style={{
          backgroundImage: props.background ? `url(${props.background})` : "",
        }}
      ></div>
      <div className="panel-button-shadow"></div>
      <div className="panel-button-content">
        <div className="d-flex justify-content-start">
          <div className="panel-button-chip">
            <p className="bodytext medium m-0">{props.text}</p>
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <div className="panel-button-chip chip-primary">
            {props.icon && <Icon icon={props.icon} />}
          </div>
        </div>
      </div>
    </div>
  );
}
