import { FormEvent, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import i18next from "i18next";

import { DEFAULT_PAGINATION_SIZE } from "../../../assets/models/iPagination";
import SupportController from "../../../assets/Controllers/SupportController";
import Title from "../../components/Body/Title/Title";
import Accordion from "../../../assets/TakyonDesignSystem/components/Accordion/Accordion";
import Button from "../../../assets/TakyonDesignSystem/components/Button/Button";
import Input from "../../../assets/TakyonDesignSystem/components/Input/Input";
import { handleInputChange } from "../../../assets/Utils/input";
import Switch from "../../../assets/TakyonDesignSystem/components/Switch/Switch";

export interface iFaq {
  _faqId?: string;
  question: string;
  answer: string;
  language: string;
}

export interface iSupportFaq {
  _id?: string;
  name: string;
  icons: string[];
  lang?: string;

  faqs: iFaq[];

  wallet?: boolean;
}

export interface iSupport {
  text: string;
  icons?: any;
  icon_hover?: any;
  selected?: boolean;
  questions?: any;
}

function SupportFaq(props: iSupportFaq) {
  return (
    <div>
      <div className="height-60"></div>
      <div className="h2 regular">
        <span>{props.name}</span>
      </div>
      <div className="height-24"></div>

      <div>
        {props.faqs.map((item, index) => {
          return (
            <Accordion
              header={item.question}
              body={
                <div dangerouslySetInnerHTML={{ __html: item.answer }}></div>
              }
              key={"faq" + Math.random()}
              index={index + 1}
              wallet={props.wallet}
            ></Accordion>
          );
        })}
      </div>
      <div className="height-60"></div>
    </div>
  );
}

function SupportForm({ wallet }: { wallet?: boolean }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [supportInformation, setSupportInformation] = useState<{
    name: string;
    email: string;
    message: string;
    privacyPolicy: boolean;
  }>({
    name: "",
    email: "",
    message: "",
    privacyPolicy: false,
  });

  const submitSupportForm = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await SupportController.sendSupportForm(supportInformation);
      setIsSubmitted(true);
    } catch (error) {
      console.log(error);
    }

    setIsLoading(false);
  };

  return (
    <>
      {!isSubmitted ? (
        <>
          {wallet ? (
            <div className="d-flex flex-column align-items-center">
              <span className="bodytext-lg medium text-center">
                {t("support.2")}
              </span>
              <span className="bodytext dark-grey-color text-center">
                {t("support.3")}
              </span>
            </div>
          ) : (
            <Title title={t("support.2")} text={t("support.3")} />
          )}

          <div className="height-48"></div>

          <form
            className={`w100`}
            style={{ maxWidth: "780px" }}
            onSubmit={submitSupportForm}
            id="support-form"
          >
            <div className="d-flex flex-column flex-md-row w100 justify-content-between gap-3">
              <Form.Group className="w100">
                <Form.Label htmlFor="support-form-name">
                  {t("support.4")}*
                </Form.Label>
                <Input
                  id="support-form-name"
                  type="text"
                  name="name"
                  required
                  placeholder={t("support.5")}
                  value={supportInformation.name}
                  onChange={(e) => handleInputChange(e, setSupportInformation)}
                />
              </Form.Group>

              <Form.Group className="w100">
                <Form.Label htmlFor="support-form-email">
                  {t("support.6")}*
                </Form.Label>
                <Input
                  id="support-form-email"
                  type="email"
                  name="email"
                  required
                  placeholder="name@email.com"
                  value={supportInformation.email}
                  onChange={(e) => handleInputChange(e, setSupportInformation)}
                />
              </Form.Group>
            </div>

            <Form.Group>
              <Form.Label htmlFor="support-form-message">
                {t("support.7")}*
              </Form.Label>
              <Form.Control
                id="support-form-message"
                name="message"
                required
                as="textarea"
                value={supportInformation.message}
                onChange={(e) => handleInputChange(e, setSupportInformation)}
              />
            </Form.Group>

            <p className="bodytext-xs">*{t("support.9")}</p>

            <div className="d-flex justify-content-between align-items-center gap-2">
              <div className="d-flex gap-2">
                <Switch
                  name="privacyPolicy"
                  onChange={(e: any) =>
                    setSupportInformation({
                      ...supportInformation,
                      privacyPolicy: e.target.checked,
                    })
                  }
                />

                <label htmlFor="support-form-checkbox">{t("support.10")}</label>
              </div>

              <Button
                type="submit"
                text="Send"
                loading={isLoading}
                disabled={
                  !supportInformation.privacyPolicy ||
                  !supportInformation.message ||
                  !supportInformation.email ||
                  !supportInformation.name
                }
              />
            </div>
          </form>
        </>
      ) : (
        <p>{t("support.12")}</p>
      )}
    </>
  );
}

export default function Support({ wallet }: { wallet?: boolean }) {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [, setTotal] = useState<number>(0);
  const [page] = useState(1);
  const [size] = useState<number>(DEFAULT_PAGINATION_SIZE);

  const [faqsCategories, setfaqsCategories] = useState<iSupportFaq[]>([]);
  const [activeAccordion, setActiveAccordion] = useState<null | number>(0);

  const loadSupportFaqs = async () => {
    setIsLoading(true);
    try {
      const data = await SupportController.getCategoriesPaginated({
        page,
        size,
      });
      setfaqsCategories(data.data.reverse());

      setTotal(data.total || 0);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadSupportFaqs();
    // eslint-disable-next-line
  }, []);

  const toggleAccordion = (index: null | number) => {
    index !== activeAccordion
      ? setActiveAccordion(index)
      : setActiveAccordion(null);
  };

  // isLoading && <Loader />;

  const categoriesByLanguage = faqsCategories.filter(
    (category) => category.lang === i18next.language
  );

  return (
    <main id="web-page">
      <section className="web-section-container">
        <div className="web-section-content">
          {!wallet ? (
            <>
              <div className="height-160"></div>
              <div>
                <Title title={t("support.1")} />
              </div>
            </>
          ) : (
            <div className="height-16"></div>
          )}

          <div>
            {isLoading && (
              <div>
                <div className="d-flex flex-column flex-md-row align-items-center justify-content-center gap-4">
                  <Skeleton height={150} width={150} />
                  <Skeleton height={150} width={150} />
                  <Skeleton height={150} width={150} />
                </div>
                <div className="height-60"></div>
                <Skeleton height={300} />
                <div className="height-60"></div>
              </div>
            )}
            <div className="d-flex flex-column flex-md-row align-items-center justify-content-center gap-4">
              {categoriesByLanguage.map((accordion, index) => {
                return (
                  <div
                    className={`d-flex flex-column justify-content-center align-items-center text-center width-130 height-130 rounded cursor-pointer ${
                      activeAccordion === index
                        ? "primary-border border-3 white-background"
                        : "border"
                    }`}
                    key={"faq" + Math.random()}
                    onClick={() => {
                      if (activeAccordion !== index) toggleAccordion(index);
                    }}
                  >
                    <p className="h4 regular">{accordion.name}</p>
                  </div>
                );
              })}
            </div>
            <div>
              {activeAccordion !== null && categoriesByLanguage.length > 0 && (
                <SupportFaq
                  name={categoriesByLanguage[activeAccordion].name}
                  icons={categoriesByLanguage[activeAccordion].icons}
                  faqs={categoriesByLanguage[activeAccordion].faqs}
                  wallet={wallet}
                />
              )}
            </div>
          </div>
        </div>
      </section>

      <section className="web-section-container light-grey-background">
        <div
          className={`web-section-content align-items-center p-4 ${
            wallet ? "white-background" : ""
          }`}
        >
          {!wallet ? (
            <div className="height-60"></div>
          ) : (
            <div className="height-30"></div>
          )}

          <SupportForm wallet={wallet} />
          {!wallet ? (
            <div className="height-90"></div>
          ) : (
            <div className="height-30"></div>
          )}
        </div>
        {wallet && <div className="height-60 w100"></div>}
      </section>
    </main>
  );
}
