import { useTranslation } from "react-i18next";
import helpers from "../../../../_Web/helpers/helpers";
import { DEFAULT_FEE_SPLIT } from "../../../../config/Fees";

export default function TakSaleDetails({ amount }: { amount: number }) {
  const { t } = useTranslation();

  const feeValue = (1 - DEFAULT_FEE_SPLIT.seller) * amount;
  const total = amount - feeValue;

  return (
    <div className="mt-3 d-flex flex-column">
      <div className="d-flex bodytext">
        <div className="flex-grow-1 light">{t("wallet.bookings.price")}:</div>
        <div>{helpers.price(amount ?? 0)}</div>
      </div>
      <div className="height-12"></div>

      <div className="d-flex bodytext">
        <div className="flex-grow-1 light">
          {t("wallet.bookings.servicefee", {
            fee: helpers.feeToString(1 - DEFAULT_FEE_SPLIT.seller),
          })}
        </div>
        <div>- {helpers.price(feeValue)}</div>
      </div>

      <hr className="my-3" />
      <div className="d-flex">
        <div className="flex-grow-1 h4 medium">
          <>{t("wallet.bookings.totalget")}</>:
        </div>
        <div>{helpers.price(total)}</div>
      </div>
    </div>
  );
}
