import Earnings from "./Transactions/Earnings";
import { useTranslation } from "react-i18next";
import TabLayout from "../Layouts/TabLayout";

export default function ProfileTransactions() {
  const { t } = useTranslation();

  const tabs = [
    {
      key: "earnings",
      label: t("profile_transactions.earnings.tab_title"),
      component: <Earnings />,
    },
  ];

  return (
    <>
      <TabLayout
        title={t("profile_transactions.title")}
        tabs={tabs}
        defaultTabKey="earnings" // Imposta la scheda di default
      />
    </>
  );
}
