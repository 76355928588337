import { useEffect, useState } from "react";
import Button from "../../../assets/TakyonDesignSystem/components/Button/Button";
import heroimg from "../../../assets/img/landing/heroimg.jpeg";
import { useLocation } from "react-router-dom";
import takLogo from "../../../assets/img/icons/tak_green.svg";
import { useSelector } from "react-redux";
import store from "../../../redux/redux";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { SMALL_SCREEN } from "../../../assets/Utils/generic";

export default function Hero() {
  const { t } = useTranslation();
  const location = useLocation();
  const isSmallScreen = useMediaQuery({ query: SMALL_SCREEN });

  return (
    <>
      <div
        className="border-bottom"
        style={{
          height: "467px",
          width: "100vw",
          background: `url(${heroimg})`,
          backgroundPosition: "center center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          position: "absolute",
          left: 0,
        }}
        id="hero"
      >
        <div
          className="w100"
          style={{
            height: "467px",
            background:
              "linear-gradient(180deg, rgba(0, 0, 0, 0.52) 0%, rgba(0, 0, 0, 0.65) 100%)",
          }}
        >
          <div className="container h100">
            <div className="content h100 py-5">
              <div className="h100 d-flex flex-column justify-content-around">
                <div
                  className="h100 d-flex align-items-center justify-content-center"
                  style={{ width: isSmallScreen ? "100%" : "50%" }}
                >
                  <p
                    className={`h1-large white-color regular ${
                      isSmallScreen ? "text-center" : ""
                    }`}
                  >
                    {t("wall.hero.title")}
                    <span className="primary-color">
                      {t("wall.hero.title2")}
                    </span>
                    {t("wall.hero.title3")}
                  </p>
                </div>
                <div className="d-flex flex-column gap-2 align-items-start">
                  <Button
                    className={isSmallScreen ? "w-100" : ""}
                    text={t("wall.hero.button")}
                    icon="arrow_downward"
                    onClick={() => {
                      window.scrollBy({
                        top: 467,
                        behavior: "smooth",
                      });

                      setTimeout(() => {
                        window.scrollBy({
                          top: 0,
                        });
                      }, 1000);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ height: "467px" }}></div>
    </>
  );
}
