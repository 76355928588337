// using https://fkhadra.github.io/react-toastify/introduction

export function apiErrorToast(error: any) {
  const errorString = error?.response?.data?.message;
  if (String(errorString).toLowerCase().includes("too many requests")) return;
  console.log(error);

  // toast.error(errorString);
  // toast.error("An error occurred. Try again later.");
}
 