import { useState } from "react";
import Button from "../../../../../assets/TakyonDesignSystem/components/Button/Button";
import { useTranslation } from "react-i18next";
import OfferController from "../../../../../assets/Controllers/OfferController";
import Input from "../../../../../assets/TakyonDesignSystem/components/Input/Input";
import { apiErrorToast } from "../../../../../assets/Utils/errors";
import { Form } from "react-bootstrap";
import { useOfferNavigation } from "../../../../hooks/hooks";
import _OffersActionsLayout from "./_OffersActionsLayout";
import helpers from "../../../../helpers/helpers";
import { openModal } from "../../../../../assets/Utils/modal";
import ActionFeedbackModal from "../../../Modals/ActionFeedbackModal";
import { useNavigate } from "react-router-dom";

export default function Counteroffer() {
  const { t } = useTranslation();
  const { offer } = useOfferNavigation();
  const navigate = useNavigate();

  const nft = offer._nft!;

  const currentOffer = offer.amount;

  const [isLoading, setIsLoading] = useState(false);

  const [amount, setAmount] = useState<number | string>();

  const canContinue = Number(amount) > 0; // && Number(amount) < Number(nft.currentPrice);

  const counterofferClick = async () => {
    setIsLoading(true);
    try {
      const counteroffer = await OfferController.createCounterOffer({
        bidId: offer._id,
        amount: Number(amount),
      });

      openModal(
        {
          content: (
            <ActionFeedbackModal
              icon="check_circle"
              title={`${t("wallet.offers.yourcounteroffer")} ${t(
                "wallet.offers.hassent"
              )}`}
              message={`${t("wallet.offers.youcounteroffer")} ${helpers.price(
                Number(amount)
              )} ${t("wallet.offers.hassubmmited")} ${t(
                "wallet.offers.counteroffertime"
              )}`}
              continueClickCallback={() => navigate("/profile/offers")}
              continueBtnText={t("app.checkout.viewoffers")}
            />
          ),
        },
        true
      );
    } catch (error) {
      apiErrorToast(error);
    }
    setIsLoading(false);
  };

  return (
    <_OffersActionsLayout title={t("wallet.offers.makecounter")}>
      <div className="my-3 bodytext d-flex gap-1">
        <div className="light">{t("wallet.offers.receivedof")}:</div>
        <div>{helpers.price(currentOffer)}</div>
      </div>

      <form>
        <Form.Label htmlFor="amount_counteroffer">
          {t("wallet.offers.counteroffersuggest3")}
        </Form.Label>
        <Input
          status={canContinue ? "success" : "normal"}
          min={0}
          type="number"
          name="amount"
          required
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
        />
      </form>

      <hr />

      <div className="d-flex justify-content-end">
        <Button
          onClick={() => {
            counterofferClick();
          }}
          text={t("wallet.offers.makecounter")}
          loading={isLoading}
          disabled={!canContinue}
        />
      </div>
    </_OffersActionsLayout>
  );
}
