import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import UserController from "../../../../assets/Controllers/UserController";
import { Form } from "react-bootstrap";
import Input from "../../../../assets/TakyonDesignSystem/components/Input/Input";
import Button from "../../../../assets/TakyonDesignSystem/components/Button/Button";
import iCredits from "../../../../assets/models/iCredit";
import { useNavigate } from "react-router-dom";
import { closeModal, openModal } from "../../../../assets/Utils/modal";

function PromoCodeSuccess({ credits }: { credits: iCredits }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <section className="p-3">
      <p className="text-center m-0 h3 medium">
        {t("wallet.credits.promosuccesstitle")}
      </p>
      <p className="text-center m-0 bodytext medium">
        {t("wallet.credits.promosuccesstext1")} €{credits.amount}{" "}
        {t("wallet.credits.promosuccesstext2")}
      </p>
      <div className="height-16"></div>
      <p className="text-center m-0 bodytext regular dark-grey-color">
        {t("wallet.credits.promosuccesstext")}
      </p>
      <div className="height-16"></div>
      <div className="d-flex justify-content-center">
        <div>
          <Button
            text={t("wallet.credits.explore")}
            onClick={() => {
              closeModal();
              navigate("/profile/credits");
            }}
          />
        </div>
      </div>
    </section>
  );
}

export default function PromoCode() {
  const [code, setCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const { t } = useTranslation();

  const [status, setStatus] = useState<"error" | "success" | "normal">(
    "normal"
  );

  const submit = async () => {
    setIsLoading(true);
    try {
      const credits = await UserController.redeemCredits(code);
      setCode("");

      openModal({
        content: <PromoCodeSuccess credits={credits} />,
      });
    } catch (error) {
      // apiErrorToast(error);
      setError(true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (code) setStatus("success");
    if (!code) setStatus("normal");
    setError(false);
  }, [code]);

  useEffect(() => {
    if (error) setStatus("error");
  }, [error]);

  return (
    <section className="p-3">
      <p className="m-0 bodytext medium">{t("wallet.credits.addpromotitle")}</p>

      <div className="height-16"></div>

      <form>
        <Form.Group
          onSubmit={(e) => {
            e.preventDefault();
            submit();
          }}
        >
          <Form.Label>{t("wallet.credits.promocode")}</Form.Label>
          <Input
            status={status}
            value={code}
            onChange={(e) => setCode(e.target.value)}
            type="text"
            placeholder="000 000"
          />
          {error ? (
            <Form.Text className="danger-color">
              {t("wallet.credits.promoerror")}
            </Form.Text>
          ) : null}
        </Form.Group>

        <div className="d-flex justify-content-end">
          <Button
            onClick={submit}
            loading={isLoading}
            disabled={!code}
            text={t("wallet.credits.addpromo")}
            type="button"
          />
        </div>
      </form>
    </section>
  );
}
