import { hotelCensus } from "./iNft";

export enum COLLECTION_TYPE {
  experience = "experience",
  hotel = "hotel",
}

type hotelStats = {
  averageBookingPrice?: number;
  averageNumberOfDaysPerStay?: number;
  averageOccupancyPercentage?: number;
  directBookingPercentage?: number;
  nonRefoundableSalesPercentage?: number;
};

export default interface iCollection {
  _id: string;
  averagePrice: number;
  name: string;
  type?: COLLECTION_TYPE;
  census: hotelCensus;
  censusStats: hotelStats;
  images: Array<string>;
}
