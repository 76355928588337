import axios from "axios";
import UserController from "./UserController";
import { iNft } from "../models/iNft";

export default class AdminController {
  static async loginAs(payload: { email: string }) {
    const response = await axios.post("/users/login-as", payload);
    UserController._login(response.data);
    return response.data;
  }

  static async putNftForSalePending(payload: { nftId: string }): Promise<iNft> {
    const response = await axios.post("/nft/sale/pending", payload);
    return response.data;
  }

  static async refreshNftExtraInfo(nftId: string, hotelsComSlug?: string) {
    const response = await axios.post("/nft/update-additional-data", {
      nftId,
      hotelsComSlug,
    });
    return response.data;
  }

  static async updateNftMarketPrice(nftId: string, marketPrice: number) {
    const response = await axios.patch("/nft", {
      _id: nftId,
      marketPrice,
    });
    return response.data;
  }
}
