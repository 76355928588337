import { useTranslation } from "react-i18next";
import { closeModal, openModal } from "../../../assets/Utils/modal";
import { useState, useEffect, useRef } from "react";
import NftController from "../../../assets/Controllers/NftController";
import BookingHeader from "../../../assets/TakyonDesignSystem/components/Wallet/sub/BookingHeader";
import { iNft } from "../../../assets/models/iNft";
import Input from "../../../assets/TakyonDesignSystem/components/Input/Input";
import Button from "../../../assets/TakyonDesignSystem/components/Button/Button";
import OfferController from "../../../assets/Controllers/OfferController";
import { fireTagManagerEvent } from "../../../assets/Services/tagmanager";
import { apiErrorToast } from "../../../assets/Utils/errors";
import Icon from "../../../assets/TakyonDesignSystem/components/Icon/Icon";
import Skeleton from "react-loading-skeleton";
import { useMediaQuery } from "react-responsive";
import { SMALL_SCREEN } from "../../../assets/Utils/generic";
import { useNavigate } from "react-router-dom";
import getLink, { LINK_TYPE } from "../../../config/Links";

export default function openOfferModal(nftId: string) {
  openModal({ content: <Offer nftId={nftId} /> });
}

interface Props {
  nftId: string;
}

export function Offer({ nftId }: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [amount, setAmount] = useState<number>();
  const [isOfferSummary, setIsOfferSummary] = useState(false);
  const [isOfferError, setIsOfferError] = useState(false);
  const isSmallScreen = useMediaQuery({ query: SMALL_SCREEN });
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [nft, setNft] = useState<iNft>();

  const mode = useRef<string>("");

  const load = async () => {
    setIsLoading(true);
    try {
      const data = await NftController.getNft(String(nftId));
      setNft(data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    load();
  }, [nftId]);

  const makeAnOffer = async () => {
    if (!amount || !nft) return;

    setIsLoading(true);
    try {
      const offerDate = new Date(nft.lockDate!);
      offerDate.setHours(offerDate.getHours() - 4);

      try {
        const offer = await OfferController.makeOffer({
          nftId: nft._id,
          expireDate: offerDate.toISOString(),
          amount: Number(amount),
          // mode: offerMode,
        });
      } catch (error) {
        apiErrorToast(error);
        setIsOfferError(true);
      }

      fireTagManagerEvent("offer", {
        mode: mode,
      });

      setIsOfferSummary(true);
    } catch (error) {
      setIsOfferError(true);
    }
    setIsLoading(false);
  };

  return (
    <>
      {isOfferSummary ? (
        <>
          <div style={{ zIndex: 2 }} className="d-flex flex-column">
            <div className="d-flex justify-content-center align-items-center py-2 border-bottom">
              <span style={{ height: "22px" }}></span>
            </div>
            <div className="p-4 pt-5 d-flex flex-column align-items-center justify-content-center h100 w100">
              {!isOfferError ? (
                <Icon icon="check_circle" type="symbol" size={150} />
              ) : (
                <svg width="147" height="148" viewBox="0 0 147 148" fill="none">
                  <path
                    d="M73.5 147.125C71.8125 147.125 70.1486 146.788 68.5083 146.115C66.8681 145.442 65.375 144.449 64.0292 143.135L4.36458 83.4708C3.05069 82.125 2.05764 80.632 1.38542 78.9917C0.711807 77.3514 0.375 75.6875 0.375 74C0.375 72.3125 0.711807 70.6403 1.38542 68.9834C2.05764 67.3278 3.05069 65.8431 4.36458 64.5292L64.0292 4.86459C65.375 3.51876 66.8681 2.51736 68.5083 1.86041C70.1486 1.20347 71.8125 0.875 73.5 0.875C75.1875 0.875 76.8597 1.20347 78.5167 1.86041C80.1722 2.51736 81.657 3.51876 82.9709 4.86459L142.635 64.5292C143.981 65.8431 144.983 67.3278 145.64 68.9834C146.297 70.6403 146.625 72.3125 146.625 74C146.625 75.6875 146.297 77.3514 145.64 78.9917C144.983 80.632 143.981 82.125 142.635 83.4708L82.9709 143.135C81.657 144.449 80.1722 145.442 78.5167 146.115C76.8597 146.788 75.1875 147.125 73.5 147.125ZM77.1854 137.19L136.69 77.6854C137.544 76.8313 137.971 75.6028 137.971 74C137.971 72.3972 137.544 71.1688 136.69 70.3146L77.1854 10.8104C76.3313 9.95625 75.1028 9.52916 73.5 9.52916C71.8972 9.52916 70.6688 9.95625 69.8146 10.8104L10.3104 70.3146C9.45625 71.1688 9.02917 72.3972 9.02917 74C9.02917 75.6028 9.45625 76.8313 10.3104 77.6854L69.8146 137.19C70.6688 138.044 71.8972 138.471 73.5 138.471C75.1028 138.471 76.3313 138.044 77.1854 137.19ZM69.3333 83.775H77.6667V40.1854H69.3333V83.775ZM73.5 102.365C74.9 102.365 76.1049 101.86 77.1146 100.85C78.1243 99.8417 78.6292 98.6375 78.6292 97.2375C78.6292 95.8375 78.1243 94.6327 77.1146 93.6229C76.1049 92.6132 74.9 92.1083 73.5 92.1083C72.1 92.1083 70.8951 92.6132 69.8854 93.6229C68.8757 94.6327 68.3708 95.8375 68.3708 97.2375C68.3708 98.6375 68.8757 99.8417 69.8854 100.85C70.8951 101.86 72.1 102.365 73.5 102.365Z"
                    fill="#1C1B1F"
                  />
                </svg>
              )}
            </div>
            <div className="height-10"></div>
            <div className="p-4 pt-0 pt-md-0 p-md-5">
              <div className="d-flex flex-column gap-3 align-items-center">
                <p className="m-0 h1 regular text-center">
                  {!isOfferError
                    ? t("app.checkout.offersuccesstitle")
                    : t("offer.error_title")}
                </p>
                <p className="m-0 bodytext regular text-center">
                  {!isOfferError
                    ? t("app.checkout.offersuccesstext")
                    : t("app.checkout.offererrortext")}
                </p>
              </div>
            </div>

            <div className="d-flex w100 justify-content-center">
              <Button
                style={{ marginBottom: "69px" }}
                text={
                  !isOfferError
                    ? t("app.checkout.explore")
                    : t("app.checkout.makeanewoffer")
                }
                onClick={() => {
                  closeModal();
                  navigate(getLink(LINK_TYPE.PROFILE_OFFERS));
                }}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="d-flex justify-content-center align-items-center py-2 border-bottom">
            <span style={{ height: "22px" }}></span>
          </div>
          {!isLoading && nft ? (
            <>
              <div
                className="modal-body modal-padding"
                style={{
                  maxHeight: isSmallScreen ? "calc(100dvh - 88px)" : "unset",
                }}
              >
                <div className="height-24"></div>
                <span className="h3 medium">
                  {t("offer.offer_to")} {nft._ownerName}
                </span>
                <div className="height-24"></div>

                <p className="bodytext m-0">
                  <span className="light">{t("offer.offer_1")}</span>{" "}
                  <span className="regular">{t("offer.offer_2")}</span>
                </p>

                <div className="height-32"></div>

                <BookingHeader nft={nft} />
                <div className="height-32"></div>

                <span className="bodytext medium">{t("offer.label")}</span>

                <div className="height-8"></div>

                <Input
                  className="w100"
                  placeholder={t("offer.placeholder")}
                  type="text"
                  value={amount}
                  onChange={(e) => {
                    let amount = Number(
                      (e.target.value
                        ? Number(e.target.value)
                        : undefined
                      )?.toFixed(2)
                    );

                    if (!isNaN(amount)) {
                      mode.current = "manual";
                      setAmount(amount);
                    } else {
                      setAmount(undefined);
                    }
                  }}
                />

                <div className="height-16"></div>

                <div className="d-flex gap-2">
                  <Button
                    size="sm"
                    onClick={() => {
                      mode.current = "button";
                      setAmount(
                        Number(
                          (Number(nft.currentPrice) * (1 - 0.05)).toFixed(2)
                        )
                      );
                    }}
                    variant="secondary"
                    text="5% Off"
                  />
                  <Button
                    size="sm"
                    onClick={() => {
                      mode.current = "button";
                      setAmount(
                        Number(
                          (Number(nft.currentPrice) * (1 - 0.1)).toFixed(2)
                        )
                      );
                    }}
                    variant="secondary"
                    text="10% Off"
                  />
                  <Button
                    size="sm"
                    onClick={() => {
                      mode.current = "button";
                      setAmount(
                        Number(
                          (Number(nft.currentPrice) * (1 - 0.15)).toFixed(2)
                        )
                      );
                    }}
                    variant="secondary"
                    text="15% Off"
                  />
                </div>

                <div className="height-32"></div>
              </div>
              <div className="d-flex justify-content-between px-4 py-2 border-top">
                <Button
                  variant="blank"
                  onlyText={true}
                  text={t("offer.cancel")}
                  onClick={closeModal}
                />
                <Button
                  disabled={!amount}
                  text={t("offer.proceed")}
                  onClick={makeAnOffer}
                />
              </div>
            </>
          ) : (
            <>
              <div className="modal-padding py-32">
                <Skeleton height={40} className="w-100"></Skeleton>
              </div>
              <div className="modal-body">
                <div className="modal-padding">
                  <Skeleton height={118} className="w-100"></Skeleton>
                </div>
                <div className="height-32"></div>
                <div className="height-16"></div>
                <div className="modal-padding">
                  <Skeleton height={22} className="w-100"></Skeleton>
                </div>
                <div className="height-16"></div>
                <div className="modal-padding">
                  <Skeleton height={22} className="w-100"></Skeleton>
                </div>
                <div className="height-16"></div>
                <div className="modal-padding">
                  <Skeleton height={22} className="w-100"></Skeleton>
                </div>
                <div className="height-60"></div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}
