import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../../../../assets/TakyonDesignSystem/components/Button/Button";
import Icon from "../../../../../assets/TakyonDesignSystem/components/Icon/Icon";
import Input from "../../../../../assets/TakyonDesignSystem/components/Input/Input";
import { iNft } from "../../../../../assets/models/iNft";
import { DEFAULT_FEE_SPLIT } from "../../../../../config/Fees";
import { selectUser } from "../../../../../redux/slices/userSlice";
import { Form } from "react-bootstrap";
import { openModal } from "../../../../../assets/Utils/modal";
import { RegisterBankAccountPage } from "../../../../../assets/TakyonDesignSystem/components/Wallet/sub/WalletBalance";
import NftController from "../../../../../assets/Controllers/NftController";
import { apiErrorToast } from "../../../../../assets/Utils/errors";
import { fireTagManagerEvent } from "../../../../../assets/Services/tagmanager";
import getLink, { LINK_TYPE } from "../../../../../config/Links";
import Share from "../../../../../assets/TakyonDesignSystem/components/Share/Share";
import _ProfileSectionLayout from "../../_ProfileSectionLayout";
import OfferDetails from "../../../../../assets/TakyonDesignSystem/components/TakDeal/OfferDetails";
import iUser from "../../../../../assets/models/iUser";
import ActionFeedbackModal from "../../../Modals/ActionFeedbackModal";
import Tab from "../../../../../assets/TakyonDesignSystem/components/Tab/Tab";
import TakSaleDetails from "../../../../../assets/TakyonDesignSystem/components/TakDeal/TakSaleDetails";

function UserPaymentAccountCheck({ user }: { user: iUser }) {
  const { t } = useTranslation();

  return (
    <>
      {!user?.stripeDetailsSubmitted || !user?.stripeChargesEnabled ? (
        <div>
          <p>{t("wallet.bookings.nobankaccounttext")}</p>
          <div className="height-16"></div>
          <Button
            onClick={() => {
              openModal({
                content: <RegisterBankAccountPage />,
              });
            }}
            text={t("wallet.bookings.nobankaccountaction")}
          />
        </div>
      ) : (
        <div>
          <p className="bodytext medium">
            {t("wallet.bookings.bankaccountverifyingtitle")}
          </p>
          <p className="bodytext light">
            {t("wallet.bookings.bankaccountverifyingtext")}
          </p>
        </div>
      )}
    </>
  );
}

export default function TakPutOnSale() {
  // { nft }: { nft: iNft }
  let location = useLocation();
  const navigate = useNavigate();

  const nft: iNft = location.state;

  const [selected, setSelected] = useState(-1);
  const [mode, setMode] = useState("suggested");
  const [price, setPrice] = useState<number | string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [target, setTarget] = useState<string>("manual");

  const { t } = useTranslation();

  const user = useSelector(selectUser);

  useEffect(() => {
    setPrice("");
    setSelected(-1);
  }, [mode]);

  const canSell = useMemo(() => {
    return user?.stripeDetailsSubmitted && user?.stripeChargesEnabled;
  }, [user?.stripeDetailsSubmitted, user?.stripeChargesEnabled]);

  const PRICE_TYPES = [
    { name: t("wallet.bookings.suggested"), value: "suggested" },
    { name: t("wallet.bookings.manual"), value: "manual" },
  ];

  const sellModes = [
    {
      name: t("wallet.bookings.careful"),
      discount: 0.5,
      target: "careful",
    },
    {
      name: t("wallet.bookings.reccomended"),
      discount: 0.6,
      target: "reccomended",
    },
    {
      name: t("wallet.bookings.bold"),
      discount: 0.8,
      target: "bold",
    },
  ];

  const currPrice = (nft.marketPrice ?? nft.originalPrice)!;

  const putOnSale = async () => {
    setIsLoading(true);
    try {
      await NftController.putNftForSale({
        nftId: nft._id,
        price: Number(price),
      });

      let selectedMode = mode;

      if (selectedMode === "suggested") {
        selectedMode = sellModes[selected].target;
      }

      fireTagManagerEvent("put_on_sale", {
        mode: selectedMode,
      });

      navigate("/profile/wallet");

      openModal({
        cancelButton: true,
        content: (
          <ActionFeedbackModal
            icon="check_circle"
            title={t("wallet.bookings.salesuccesstitle")}
            message={`${t("wallet.bookings.salesuccesstext")} ${t(
              "wallet.bookings.salesuccesssharetext"
            )}`}
            continueBtnText={t("wallet.bookings.sharetak")}
            continueBtnIcon="ios_share"
            continueClickCallback={() => {
              openModal({
                content: (
                  <Share
                    id={String(nft?._id)}
                    link={getLink(LINK_TYPE.APP_EXPLORE_NFT, "API", {
                      __NFTID__: String(nft?._id),
                    })}
                  />
                ),
              });
            }}
          />
        ),
      });
    } catch (error) {
      apiErrorToast(error);
    }
    setIsLoading(false);
  };

  return (
    <_ProfileSectionLayout nft={nft} title={t("wallet.bookings.putsaletak")}>
      {/* Price section */}
      {!canSell ? (
        <UserPaymentAccountCheck user={user!} />
      ) : (
        <>
          <Tab
            size="50%"
            items={PRICE_TYPES}
            onChange={(value: any) => {
              setMode(value);
            }}
          />
          <div className="height-16"></div>
          {mode === "suggested" ? (
            <>
              <div className="d-flex flex-column gap-2">
                {nft.marketPrice || nft.originalPrice ? (
                  <>
                    {sellModes.map((mode, key) => {
                      const suggestedPrice = currPrice * mode.discount;

                      return (
                        <div
                          key={"mode_" + key}
                          className={`
                            cursor-pointer p-3 
                            rounded w100
                            ${
                              selected === key
                                ? "black-border"
                                : "mid-grey-border"
                            }
                            `}
                          onClick={() => {
                            setSelected(key);
                            setPrice(suggestedPrice);
                            setTarget(mode.target);
                          }}
                        >
                          <div className="d-flex align-items-center gap-2">
                            <Icon
                              icon="circle"
                              type="symbol"
                              fill={selected === key}
                            />
                            <p className="m-0 bodytext medium light-balck-color">
                              {mode.name}
                            </p>
                            <p className="text-end w100 m-0 bodytext-lg medium">
                              €{suggestedPrice.toFixed(2)}
                            </p>
                          </div>
                          <div className="height-16"></div>
                          <div className="d-flex justify-content-between gap-2">
                            <p className="m-0 bodytext-sm regular dark-grey-color text-nowrap">
                              {t("wallet.bookings.price")}
                            </p>
                            <p className="m-0 bodytext-sm regular dark-grey-color">
                              €{suggestedPrice.toFixed(2)}
                            </p>
                          </div>
                          <div className="d-flex justify-content-between gap-2">
                            <p className="m-0 bodytext-sm regular dark-grey-color text-nowrap">
                              {t("wallet.bookings.servicefee", {
                                fee: `${Math.ceil(
                                  (1 - DEFAULT_FEE_SPLIT.seller) * 100
                                )}%`,
                              })}
                            </p>
                            <p className="m-0 bodytext-sm regular dark-grey-color">
                              -€
                              {(
                                suggestedPrice *
                                (1 - DEFAULT_FEE_SPLIT.seller)
                              ).toFixed(2)}
                            </p>
                          </div>
                          <div
                            className="horizontal-divider"
                            style={{ margin: "8px 0" }}
                          ></div>
                          <div className="d-flex justify-content-between">
                            <p className="m-0 bodytext-sm regular">
                              {t("wallet.bookings.totalget")}
                            </p>
                            <p className="m-0 bodytext-sm regular">
                              €
                              {(
                                suggestedPrice * DEFAULT_FEE_SPLIT.seller
                              ).toFixed(2)}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : null}
              </div>
            </>
          ) : null}
          {mode === "manual" ? (
            <>
              <div className="d-flex gap-2">
                <Form.Group className="w100">
                  <Form.Label>{t("wallet.bookings.choosenprice")}</Form.Label>
                  <Input
                    status={Number(price) > 0 ? "success" : "normal"}
                    type="number"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    placeholder={t("wallet.bookings.insertprice")}
                  />
                </Form.Group>
              </div>
              <TakSaleDetails amount={Number(price)} />
              <hr className="m-0" />
            </>
          ) : null}
          {/* <div className="d-flex">
            <div>
              <Form.Group className="mb-3">
                <Form.Check
                  type="checkbox"
                  label="Accetta automaticamente offerte di altri viaggiatori superiori a:"
                />
              </Form.Group>
            </div>
            <div>
              <Input
                status={Number(price) > 0 ? "success" : "normal"}
                type="number"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                placeholder={t("wallet.bookings.insertprice")}
              />
            </div>
          </div> */}
          <div className="height-24"></div>
          <div className="d-flex justify-content-end">
            <Button
              loading={isLoading}
              disabled={!canSell || Number(price) <= 0}
              onClick={() => {
                if (canSell && Number(price) > 0) putOnSale();
              }}
              text={t("wallet.bookings.putsale")}
            />
          </div>
        </>
      )}
      {/* Price section - end */}
    </_ProfileSectionLayout>
  );
}
