import { useTranslation } from "react-i18next";
import helpers from "../../../../_Web/helpers/helpers";
import { iBid } from "../../../models/iBid";
import { DEFAULT_FEE_SPLIT } from "../../../../config/Fees";

export default function OfferDetails({ offer }: { offer: iBid }) {
  const { t } = useTranslation();

  const offerPrice = offer.counterOfferAmount ?? offer.amount;
  const feeValue = (1 - DEFAULT_FEE_SPLIT.seller) * offerPrice;

  const isSeller = offer._side === "seller";

  const total = isSeller ? offerPrice - feeValue : offerPrice;

  return (
    <div className="mt-3 d-flex flex-column">
      <div className="d-flex bodytext">
        <div className="flex-grow-1 light">
          {t("wallet.offers.offer_amount")}:
        </div>
        <div>{helpers.price(offerPrice)}</div>
      </div>
      <div className="height-12"></div>
      {/* only seller can see fee */}
      {isSeller && (
        <div className="d-flex bodytext">
          <div className="flex-grow-1 light">
            {t("wallet.bookings.servicefee", {
              fee: helpers.feeToString(1 - DEFAULT_FEE_SPLIT.seller),
            })}
          </div>
          <div>- {helpers.price(feeValue)}</div>
        </div>
      )}
      <hr className="my-3" />
      <div className="d-flex">
        <div className="flex-grow-1 h4 medium">
          {isSeller ? (
            <>{t("wallet.bookings.totalget")}</>
          ) : (
            <>{t("wallet.bookings.total")}</>
          )}
          :
        </div>
        <div>{helpers.price(total)}</div>
      </div>
    </div>
  );
}
