import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FavoritesController from "../../../../assets/Controllers/FavoritesController";
import { iNft } from "../../../../assets/models/iNft";
import NftController from "../../../../assets/Controllers/NftController";

import TakSmallList from "../../TakSmallList/TakSmallList";
import Skeleton from "react-loading-skeleton";

interface Props {
  userId: string;
}

export default function FavoritesAll({ userId }: Props) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [favorites, setFavorites] = useState<iNft[]>([]);

  const loadFavorites = async () => {
    try {
      setIsLoading(true);
      // first call to get all favorites nft ids
      const ids = await FavoritesController.getAllFavoritesIds(userId);
      // second call to get nft data to display takcard
      const nftsPaginated = await NftController.getPaginated(
        {
          page: 1,
          size: 500,
          query: JSON.stringify({ _id: { $in: ids } }),
          sort: JSON.stringify({
            _lockDate: 1,
          }),
        },
        false
      );

      setFavorites(nftsPaginated.data);

      setIsLoading(false);
    } catch (error) {
      console.log("FavoritesCategory - loadCustomerFavorites error", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadFavorites();
    return () => {
      console.log("FavoritesCategory Unmounted");
    };
  }, []);

  if (isLoading)
    return (
      <div className="favorites-list d-flex gap-2 flex-wrap">
        <Skeleton width={200} height={200}></Skeleton>
        <Skeleton width={200} height={200}></Skeleton>
      </div>
    );

  if (favorites.length === 0) return <div>{t("favorites.no_favorites")}</div>;

  return (
    <>
      <TakSmallList nfts={favorites} />
    </>
  );
}
